import { getUserLocale } from 'get-user-locale';
import _ from 'lodash';
import Vue from 'vue';
import Vuex, { Store } from 'vuex';

import { TRANSITIME_OPERATOR_FORMAT } from '../utils/format-operator';
import getAgencies from '../utils/get-agencies';

import * as actions from './actions';
import * as getters from './getters';
import { currentTime } from './modules/currentTime';
import { messages } from './modules/messages';
import { navigation } from './modules/navigation';
import { passengerCounting } from './modules/passenger-counting';
import * as mutations from './mutations';

const ALL_TABLET_AGENCIES_BY_KEY = _.keyBy(getAgencies(), 'key');

Vue.use(Vuex);

// TODO(haysmike) Write `localStorage` wrapper
const agencyKey = localStorage.getItem('agencyKey') || '';
const agencyKeys = localStorage.getItem('agencyKeys') || '[]';

let sendGeolocationUpdates =
  localStorage.getItem('sendGeolocationUpdates') === 'true';
let showTimepointsOnlyInOtpView =
  localStorage.getItem('showTimepointsOnlyInOtpView') === 'true';
let showTimepointsOnlyInMapView =
  localStorage.getItem('showTimepointsOnlyInMapView') === 'true';
let headwayMode = localStorage.getItem('headwayMode') === 'true';
// If not explicitly set, we'll set this later based on whether the agency has operator IDs in Mongo.
const localStorageShowOperatorLogin = localStorage.getItem('showOperatorLogin');
let showOperatorLogin =
  localStorageShowOperatorLogin === 'true'
    ? true
    : localStorageShowOperatorLogin === 'false'
    ? false
    : null;
// TODO(patyhonores) Removed after all users have latest apphash version
localStorage.removeItem('showRouteStops');
localStorage.removeItem('isSwiftlyUser');

// If not explicitly enabled in agency settings, always default these settings to false
let enableServiceAdjustments = false;

let tripDisplayFormat = 'default';
let operatorAssignmentFormat = TRANSITIME_OPERATOR_FORMAT.ID;

const currentOperatorLocalStorageValue =
  localStorage.getItem('currentOperator');
const currentOperator = currentOperatorLocalStorageValue
  ? currentOperatorLocalStorageValue
  : 'null';

let developerMode;
let showRouteStops;
// If we have agency defaults, override local storage settings
const agencyDefaultSettings = _.get(ALL_TABLET_AGENCIES_BY_KEY, [
  agencyKey,
  'defaultSettings',
]);
if (agencyDefaultSettings) {
  if (agencyDefaultSettings.enableZeroTouchLogin === true) {
    sendGeolocationUpdates = false;
  } else if (
    typeof agencyDefaultSettings.sendGeolocationUpdates === 'boolean'
  ) {
    sendGeolocationUpdates = agencyDefaultSettings.sendGeolocationUpdates;
  }
  if (typeof agencyDefaultSettings.showRouteStops === 'boolean') {
    showRouteStops = agencyDefaultSettings.showRouteStops;
  }
  if (typeof agencyDefaultSettings.showTimepointsOnlyInOtpView === 'boolean') {
    showTimepointsOnlyInOtpView =
      agencyDefaultSettings.showTimepointsOnlyInOtpView;
  }
  if (typeof agencyDefaultSettings.showTimepointsOnlyInMapView === 'boolean') {
    showTimepointsOnlyInMapView =
      agencyDefaultSettings.showTimepointsOnlyInMapView;
  }
  if (typeof agencyDefaultSettings.headwayMode === 'boolean') {
    headwayMode = agencyDefaultSettings.headwayMode;
  }
  if (typeof agencyDefaultSettings.developerMode === 'boolean') {
    developerMode = agencyDefaultSettings.developerMode;
  }
  if (typeof agencyDefaultSettings.showOperatorLogin === 'boolean') {
    showOperatorLogin = agencyDefaultSettings.showOperatorLogin;
  }
  if (typeof agencyDefaultSettings.enableServiceAdjustments === 'boolean') {
    enableServiceAdjustments = agencyDefaultSettings.enableServiceAdjustments;
  }
  if (typeof agencyDefaultSettings.tripDisplayFormat === 'string') {
    tripDisplayFormat = agencyDefaultSettings.tripDisplayFormat;
  }
  if (typeof agencyDefaultSettings.operatorAssignmentFormat === 'string') {
    operatorAssignmentFormat = agencyDefaultSettings.operatorAssignmentFormat;
  }
}

const permanentlyAssignedVehicleId = localStorage.getItem(
  'permanentlyAssignedVehicleId',
);

const state = {
  agenciesByKey: ALL_TABLET_AGENCIES_BY_KEY,

  uuid: window.swiftlyUUID || '',
  registrationStatus: window.swiftlyRegisteredStatus || 'unregistered',
  appHash: window.appHash || '',
  nativeAppVersion: null,

  sendGeolocationUpdates,
  geolocationEnabledState: '...',

  isOnline: 'onLine' in navigator ? navigator.onLine : true,
  serverErrorVehicles: false,
  serverErrorBlocks: false,
  serverErrorTripInfo: false,
  serverErrorVehicleInfo: false,
  vehicleAssignmentError: '',

  headwayMode,
  developerMode,
  showRouteStops,
  showTimepointsOnlyInOtpView,
  showTimepointsOnlyInMapView,
  showOperatorLogin,
  enableServiceAdjustments,
  tripDisplayFormat,
  operatorAssignmentFormat,
  showTopBar: true,
  showLoginOverlay: true,
  showLoginSelections: true,

  currentAgencyKey: agencyKey,
  currentVehicleId: '',
  permanentlyAssignedVehicleId: permanentlyAssignedVehicleId || '',
  currentRouteKey: '',
  currentBlockId: '',

  currentVehicleInfoTimestamp: null,
  currentVehicleInfoAttemptTimestamp: null,
  wakeLockStatus: 'Requesting...',

  // Values stored as stringified JSON
  agencyKeys,
  currentOperator,
  currentOperatorIds: '[]',
  currentAgencyInfo: 'null',
  currentAgencyTransitimeConfig: 'null',
  currentRoutes: '[]',
  currentVehicles: '[]',
  currentBlocks: '[]',
  serviceAdjustments: '[]',

  currentVehicleInfo: 'null',
  lastValidVehicleInfo: 'null',
  currentRouteInfo: 'null',
  currentTripInfo: 'null',
  lastTripLastVehicleInfo: 'null',
  matchedTripPathResponse: 'null',
  currentTripCoordinates: 'null',

  currentPosition: 'null',

  memoryInfo: '{}',

  email: localStorage.getItem('email'),
  userLocale: getUserLocale(),
  isSimulating:
    !sendGeolocationUpdates && localStorage.getItem('isSimulating') === 'true',
  disableSafeDrivingMode:
    localStorage.getItem('disableSafeDrivingMode') === 'true' ||
    (agencyDefaultSettings?.disableSafeDrivingMode ?? false),
  useStadiaNavigation:
    localStorage.getItem('useStadiaNavigation') === 'true' ||
    (agencyDefaultSettings?.useStadiaNavigation ?? true),
  useStadiaPolylines:
    localStorage.getItem('useStadiaPolylines') === 'true' ||
    (agencyDefaultSettings?.useStadiaPolylines ?? true),

  distancesFromRouteLines: {
    original: null,
    detour: null,
    closed: null,
    accuracy: null,
  },
};

const store = new Store({
  strict: true,
  state,
  mutations,
  getters,
  actions,
  modules: { currentTime, messages, navigation, passengerCounting },
});

export default store;
