<template>
  <div
    class="component-status-header"
    :class="
      showStatus && statusConfig.class != null
        ? `component-status-header--${statusConfig.class}`
        : ''
    "
  >
    <DigitalClock />
    <div v-if="showStatus" class="component-status-header__container">
      <div class="component-status-header__text">
        {{ statusConfig.text }}
      </div>
      <div class="component-status-header__icon">
        <inline-svg :name="statusConfig.icon" />
      </div>
    </div>
  </div>
</template>
<script>
import DigitalClock from './DigitalClock.vue';

const statusConfigByStatus = {
  'error': {
    text: 'Lost connection',
    icon: 'connection-lost',
    class: 'alert-error',
  },
  'depart': {
    icon: 'timer',
    class: 'alert-depart',
  },
  'detour': {
    text: 'On detour',
    icon: 'detour',
    class: 'alert-info',
  },
  'on-time': {
    text: 'On time',
    icon: 'check-mark',
  },
  'expected': {
    text: 'Expected headway',
    icon: 'check-mark',
  },
  'early': {
    icon: 'alert',
    class: 'alert-warning',
  },
  'bunched': {
    icon: 'alert',
    class: 'alert-warning',
  },
  'late': {
    icon: 'clock',
  },
  'gapped': {
    icon: 'clock',
  },
  'timing-unavailable': {
    text: 'Timing unavailable',
    icon: 'question-mark-circle',
    class: 'timing-unavailable',
  },
};

export default {
  components: {
    DigitalClock,
  },
  props: {
    status: {
      type: String,
    },
    statusText: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    statusConfig() {
      if (this.status == null) {
        return null;
      }
      const statusConfig = statusConfigByStatus[this.status];
      if (statusConfig.text != null) {
        return statusConfig;
      } else if (this.statusText !== '') {
        return { text: this.statusText, ...statusConfig };
      }
      return null;
    },
    showStatus() {
      return this.status != null && this.statusConfig != null;
    },
  },
};
</script>
<style lang="stylus" scoped>
@require "../../styl/_colors.styl"
@require "../../styl/_variables.styl"

.component-status-header {
  position relative
  color $white
  background-color $background-color
  height $status-header-height
  width 100%
  display flex
  align-items center
  justify-content space-between
  padding 0 2rem
  &--alert-error {
    background-color $background-color-error
    background-image repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 2.8rem,
      $background-color-error2 2.8rem,
      $background-color-error2 5.6rem
    )
  }
  &--alert-depart {
    background-color $background-color
    animation fadeBackgroundDepart 1s
    animation-fill-mode forwards
    @keyframes fadeBackgroundDepart {
      from {
        background-color $background-color
      }
      to {
        background-color $status-standby
      }
    }
  }
  &--alert-warning  {
    background-color $background-color
    animation fadeBackgroundWarning 1s
    animation-fill-mode forwards
    @keyframes fadeBackgroundWarning {
      from {
        background-color $background-color
      }
      to {
        background-color $background-color-alert
      }
    }
  }
  &--alert-info {
    color $color-secondary-yellow
  }
  &__container {
    font-size 2.8rem
    font-weight 600
    display flex
    align-items center
    animation fadeIn 1s
    @keyframes fadeIn {
      0% {
        opacity 0
      }
      100% {
        opacity 1
      }
    }
  }
  &__text {
    margin 0.8rem
  }
  &__icon {
    width 2.8rem
    height 2.8rem
  }
  &--timing-unavailable {
    color $alto2
  }
}
</style>
