<template>
  <div
    id="app"
    :class="`active-section-${activeSectionName} ${warningClass}`"
    @click="onTapAnything"
  >
    <TopBar
      v-show="$store.getters.showTopBar"
      :showingPartialError="showingPartialError"
      :showingFullError="showingFullError"
    />
    <router-view
      @refresh-agency-data="refreshAgencyData"
      @show-loading="showLoading"
      @hide-loading="hideLoading"
      @show-partial-error-change="showPartialErrorChange"
      @show-full-error-change="showFullErrorChange"
    />
    <div
      v-show="showLoadingOverlay"
      id="loading-overlay"
      class="animated fadeIn"
    >
      <LoadingAnimation />
    </div>
  </div>
</template>

<script>
import { TweenMax } from 'gsap/all';
import _ from 'lodash';
// TODO(will): Remove NoSleep implementation; this was for preventing sleep on the PWA.
import NoSleep from 'nosleep.js';
import { mapGetters } from 'vuex';

import * as Segment from '../analytics/segment';
import * as ErrorReporter from '../error-reporter';
import { ONBOARD_APP_SECRET_MENU } from '../optimizely/feature-flags';
import { TRANSITIME_OPERATOR_FORMAT } from '../utils/format-operator';
import getAgencies from '../utils/get-agencies';
import VueDispatchInterval from '../utils/vue-dispatch-interval';

import TopBar from './TopBar.vue';
import LoadingAnimation from './common/LoadingAnimation.vue';

const ALL_TABLET_AGENCIES_BY_KEY = _.keyBy(getAgencies(), 'key');

let _vehicleInfoInterval = undefined;
let _serviceAdjustmentsInterval = undefined;
let _sendAvlInterval = undefined;
let _memoryInterval = undefined;
let _appUpdateInterval = undefined;
let _appStartTimestamp = undefined;

const _noSleep = typeof window.cordova !== 'object' ? new NoSleep() : undefined;

export default {
  components: {
    LoadingAnimation,
    TopBar,
  },
  data() {
    return {
      showLoadingOverlay: false,

      showingPartialError: false,
      showingFullError: false,
      lastUserInteraction: Date.now(),

      // TODO: Allow server to dynamically update geolocation settings
      geolocationTargetFrequency: 1000 * 10, // 10 seconds
      geolocationErrorCooldown: 1000 * 10, // 10 seconds
      geolocationErrorRetries: 3,

      hasLocationPermission: false,
      geolocationWatchId: null,
      wakeLock: null,
    };
  },
  computed: {
    ...mapGetters(['sendGeolocationUpdates']),
    activeSectionName() {
      if (this.$route.name === 'settings') {
        return 'settings';
      }
      return this.$route.name;
    },
    warningClass() {
      const shouldShowWarning =
        this.$store.getters.userHasFeatureAccess(ONBOARD_APP_SECRET_MENU) &&
        process.env.ALLOWED_AGENCY_TYPE === 'prod';
      return shouldShowWarning ? 'prod-warning' : '';
    },
    vehicleInfoIntervalDelay() {
      return 1000 * (this.$store.getters.isSimulating ? 2 : 8.5);
    },
    vehicleIdForPolling() {
      const {
        isOnline,
        currentVehicleId,
        isZeroTouchLoginEnabled,
        isZeroTouchLoginPossible,
        permanentlyAssignedVehicleId,
      } = this.$store.getters;
      const zeroTouchVehicleId =
        isZeroTouchLoginEnabled && isZeroTouchLoginPossible
          ? permanentlyAssignedVehicleId
          : '';
      return isOnline ? currentVehicleId || zeroTouchVehicleId : null;
    },
    shouldPollServiceAdjustments() {
      const { isOnline, currentVehicleId } = this.$store.getters;
      return currentVehicleId && isOnline;
    },
  },
  watch: {
    '$store.getters.isOnline'(isOnline) {
      if (isOnline && this.sendGeolocationUpdates) {
        this.startSendAvlInterval();
      } else {
        this.stopSendAvlInterval();
      }
    },
    'hasLocationPermission'(hasLocationPermission) {
      if (hasLocationPermission) {
        this.startWatchingGeolocation();
        // If we don't have a location, we wait until we do (see `currentPosition` watcher) to start the interval.
        // This ensures that a location is sent as soon as we have one, rather than waiting up to `avlReportingInterval` seconds.
        if (
          this.$store.getters.currentPosition != null &&
          this.sendGeolocationUpdates
        ) {
          this.startSendAvlInterval();
        }
      } else {
        this.stopWatchingGeolocation();
        this.stopSendAvlInterval();
      }
    },
    async '$store.getters.currentAgencyKey'(agencyKey) {
      await this.resetAgencyState();
      this.refreshAgencyData();

      // If we have agency defaults, override current settings once
      const agencyDefaultSettings = _.get(ALL_TABLET_AGENCIES_BY_KEY, [
        agencyKey,
        'defaultSettings',
      ]);
      if (agencyDefaultSettings) {
        if (
          agencyDefaultSettings.enableZeroTouchLogin !== true &&
          typeof agencyDefaultSettings.sendGeolocationUpdates === 'boolean' &&
          this.hasLocationPermission
        ) {
          this.$store.commit(
            'sendGeolocationUpdates',
            agencyDefaultSettings.sendGeolocationUpdates,
          );
        }
        if (typeof agencyDefaultSettings.showRouteStops === 'boolean') {
          this.$store.commit(
            'showRouteStops',
            agencyDefaultSettings.showRouteStops,
          );
        }
        if (
          typeof agencyDefaultSettings.showTimepointsOnlyInOtpView === 'boolean'
        ) {
          this.$store.commit(
            'showTimepointsOnlyInOtpView',
            agencyDefaultSettings.showTimepointsOnlyInOtpView,
          );
        }
        if (
          typeof agencyDefaultSettings.showTimepointsOnlyInMapView === 'boolean'
        ) {
          this.$store.commit(
            'showTimepointsOnlyInMapView',
            agencyDefaultSettings.showTimepointsOnlyInMapView,
          );
        }
        if (typeof agencyDefaultSettings.headwayMode === 'boolean') {
          this.$store.commit('headwayMode', agencyDefaultSettings.headwayMode);
        }
        if (typeof agencyDefaultSettings.developerMode === 'boolean') {
          this.$store.commit(
            'developerMode',
            agencyDefaultSettings.developerMode,
          );
        }
        if (typeof agencyDefaultSettings.showOperatorLogin === 'boolean') {
          this.$store.commit(
            'showOperatorLogin',
            agencyDefaultSettings.showOperatorLogin,
          );
        }
        if (
          typeof agencyDefaultSettings.enableServiceAdjustments === 'boolean'
        ) {
          this.$store.commit(
            'enableServiceAdjustments',
            agencyDefaultSettings.enableServiceAdjustments,
          );
        }
        if (typeof agencyDefaultSettings.tripDisplayFormat === 'string') {
          this.$store.commit(
            'tripDisplayFormat',
            agencyDefaultSettings.tripDisplayFormat,
          );
        }
        if (
          typeof agencyDefaultSettings.operatorAssignmentFormat === 'string'
        ) {
          this.$store.commit(
            'operatorAssignmentFormat',
            agencyDefaultSettings.operatorAssignmentFormat,
          );
        }
        this.$store.commit(
          'disableSafeDrivingMode',
          (this.$store.getters.userHasFeatureAccess(ONBOARD_APP_SECRET_MENU) &&
            localStorage.getItem('disableSafeDrivingMode') === 'true') ||
            agencyDefaultSettings.disableSafeDrivingMode,
        );
        this.$store.commit(
          'useStadiaNavigation',
          (this.$store.getters.userHasFeatureAccess(ONBOARD_APP_SECRET_MENU) &&
            localStorage.getItem('useStadiaNavigation') === 'true') ||
            agencyDefaultSettings.useStadiaNavigation,
        );
        this.$store.commit(
          'useStadiaPolylines',
          (this.$store.getters.userHasFeatureAccess(ONBOARD_APP_SECRET_MENU) &&
            localStorage.getItem('useStadiaPolylines') === 'true') ||
            agencyDefaultSettings.useStadiaPolylines,
        );
      } else {
        // If not explicitly enabled in agency settings, default the following settings
        this.$store.commit('enableServiceAdjustments', false);
        this.$store.commit('tripDisplayFormat', 'default');
        this.$store.commit(
          'operatorAssignmentFormat',
          TRANSITIME_OPERATOR_FORMAT.ID,
        );
      }
    },
    'shouldPollServiceAdjustments': {
      immediate: true,
      handler(shouldPollServiceAdjustments) {
        if (shouldPollServiceAdjustments) {
          this.startServiceAdjustmentsInterval();
        } else {
          this.stopServiceAdjustmentsInterval();
        }
      },
    },
    'vehicleIdForPolling': {
      immediate: true,
      handler(vehicleId) {
        this.stopVehicleInfoInterval();
        this.$store.commit('currentVehicleInfo', null);
        this.$store.commit('currentVehicleInfoTimestamp', null);
        if (vehicleId) {
          this.startVehicleInfoInterval();
        }
      },
    },
    '$store.getters.currentRouteKey'(routeKey) {
      if (!routeKey) {
        return;
      }
      this.$store.dispatch('getRouteInfo');
    },
    async 'sendGeolocationUpdates'(sendGeolocationUpdates) {
      if (this.hasLocationPermission) {
        if (sendGeolocationUpdates) {
          this.startSendAvlInterval();
        } else {
          this.stopSendAvlInterval();
        }
      } else if (sendGeolocationUpdates) {
        await this.checkLocationPermission();
      }
    },
    '$store.getters.currentPosition'(currentPosition) {
      if (currentPosition != null && this.sendGeolocationUpdates) {
        this.startSendAvlInterval();
      }
    },
    '$store.getters.showOperatorLogin'(showOperatorLogin) {
      if (!showOperatorLogin) {
        this.$store.commit('currentOperator', null);
      }
    },
    '$store.getters.hasOperatorIds'(hasOperatorIds) {
      if (hasOperatorIds && this.$store.getters.showOperatorLogin == null) {
        this.$store.commit('showOperatorLogin', true);
      }
    },
    '$store.getters.isSimulating'(isSimulating) {
      _vehicleInfoInterval?.updateDelay(this.vehicleInfoIntervalDelay);
    },
    '$store.getters.email'() {
      Segment.identifyUser();
    },
  },
  async beforeMount() {
    _appStartTimestamp = Date.now();

    this.requestWakeLock();
    try {
      document.addEventListener(
        'visibilitychange',
        this.handleVisibilityChange,
      );
      document.addEventListener(
        'fullscreenchange',
        this.handleVisibilityChange,
      );
      document.addEventListener(
        'backbutton',
        (e) => {
          e.preventDefault();
        },
        false,
      );
      document.addEventListener('pause', () => Segment.track('app-pause'));
      document.addEventListener('resume', () => Segment.track('app-resume'));
      window.addEventListener('offline', this.handleOnlineStatusChange);
      window.addEventListener('online', this.handleOnlineStatusChange);
      window.addEventListener('focus', this.onWindowFocus);
      if (
        window.NativeAPI != null &&
        window.NativeAPI.listenForNativeEvents != null
      ) {
        window.NativeAPI.listenForNativeEvents((event) => {
          if (typeof event === 'string') {
            this.$store.dispatch(event);
          } else if (typeof event === 'object') {
            const { action, ...opts } = event;
            this.$store.dispatch(action, opts);
          }
        });
      }
    } catch (error) {
      ErrorReporter.captureException(
        `Unable to add event listeners: ${error.message}`,
      );
    }

    this.getStateFromLocalStorage();

    _memoryInterval = setInterval(
      () => {
        if (_.get(window, ['performance', 'memory'])) {
          this.$store.commit('memoryInfo', performance.memory);
        }
      },
      1000 * 10, // Every 10 seconds
    );

    _appUpdateInterval = setInterval(
      this.checkIfShouldUpdate,
      1000 * 60 * 30, // Every 30 minutes
    );

    if (this.$route.name !== 'vehicle') {
      this.$store.commit('currentOperator', null);
    }

    this.refreshAgencyData();

    await this.checkLocationPermission();

    try {
      const getNativeAppVersion = window.NativeAPI?.getNativeAppVersion;
      if (getNativeAppVersion != null) {
        const version = await getNativeAppVersion();
        this.$store.commit('nativeAppVersion', version);
      }
    } catch (error) {
      ErrorReporter.capture({
        level: 'error',
        messageOrException: `Unable to get native app version: ${error.message}`,
      });
    }

    if (this.$store.getters.isRegisteredDevice) {
      await this.$store.dispatch('ensureEmail');
    }
    Segment.identifyUser();
    Segment.group();
    Segment.track('app-start');
    Segment.track('optimizely_config', {
      enabledFeatures: this.$store.getters.enabledFeatures,
    });
  },
  beforeDestroy() {
    window.removeEventListener('focus', this.onWindowFocus);
    clearInterval(_memoryInterval);
    clearInterval(_appUpdateInterval);
    this.$store.dispatch('currentTime/stop');
  },
  beforeCreate() {
    this.$store.dispatch('currentTime/start');
  },
  methods: {
    getStateFromLocalStorage() {
      const headwayMode = localStorage.getItem('headwayMode');
      if (headwayMode != null) {
        this.$store.commit('headwayMode', JSON.parse(headwayMode));
      }
      if (this.$store.getters.userHasFeatureAccess(ONBOARD_APP_SECRET_MENU)) {
        const developerMode = localStorage.getItem('developerMode');
        if (developerMode != null) {
          this.$store.commit('developerMode', JSON.parse(developerMode));
        }
      }
    },
    onTapAnything() {
      this.lastUserInteraction = Date.now();
    },
    startVehicleInfoInterval() {
      if (!_vehicleInfoInterval) {
        _vehicleInfoInterval = new VueDispatchInterval(
          this.$store,
          'getVehicleInfo',
          this.vehicleInfoIntervalDelay,
        );
      }
      _vehicleInfoInterval.start();
    },
    stopVehicleInfoInterval() {
      if (!_vehicleInfoInterval) {
        return;
      }
      _vehicleInfoInterval.stop();
    },
    startServiceAdjustmentsInterval() {
      if (!this.$store.getters.isOnline) {
        return;
      }
      if (!_serviceAdjustmentsInterval) {
        _serviceAdjustmentsInterval = new VueDispatchInterval(
          this.$store,
          'getServiceAdjustments',
          1000 * 10, // 10 Seconds
        );
      }
      _serviceAdjustmentsInterval.start();
    },
    stopServiceAdjustmentsInterval() {
      if (!_serviceAdjustmentsInterval) {
        return;
      }
      _serviceAdjustmentsInterval.stop();
    },
    startSendAvlInterval() {
      if (!_sendAvlInterval) {
        _sendAvlInterval = new VueDispatchInterval(
          this.$store,
          'sendLocationUpdate',
          this.$store.getters.agencyAvlReportingInterval,
        );
      }
      _sendAvlInterval.start();
    },
    stopSendAvlInterval() {
      if (!_sendAvlInterval) {
        return;
      }
      _sendAvlInterval.stop();
    },
    showLoading() {
      this.showLoadingOverlay = true;
    },
    hideLoading() {
      let $element = document.getElementById('loading-overlay');

      const tween = { value: 1 };
      TweenMax.to(tween, 0.75, {
        value: 0,
        ease: 'none',
        onUpdate: () => {
          $element = document.getElementById('loading-overlay');
          if (!$element) {
            return;
          }
          $element.className = '';
          $element.style.opacity = String(tween.value);
        },
        onComplete: () => {
          this.showLoadingOverlay = false;
        },
      });
    },
    showPartialErrorChange(showingPartialError) {
      this.showingPartialError = showingPartialError;
    },
    showFullErrorChange(showingFullError) {
      this.showingFullError = showingFullError;
    },
    async onWindowFocus() {
      this.refreshAgencyData();
      // As this can fire on initial load as well, only fire the
      // if a few seconds have passed since the app start time
      if ((Date.now() - _appStartTimestamp) / 1000 > 5) {
        Segment.track('window-focus');
      }
      await this.$store.dispatch('checkPwaGeolocationEnabledState', {
        vc: this,
      });
    },
    refreshAgencyData() {
      if (!this.$store.getters.isOnline) {
        return;
      }
      this.$store.dispatch('getAgencyInfo');
      this.$store.dispatch('getAgencyTransitimeConfig');
      this.$store.dispatch('getRoutes');
      this.$store.dispatch('getTripInfo');
      this.$store.dispatch('getOperatorIds');
    },
    async checkLocationPermission() {
      const checkNativeLocationPermission =
        window.NativeAPI?.getLocationPermission;
      if (checkNativeLocationPermission != null) {
        this.hasLocationPermission = await checkNativeLocationPermission();
        if (!this.hasLocationPermission) {
          this.$store.commit('sendGeolocationUpdates', false);
        }
      } else {
        const enabledState = await this.$store.dispatch(
          'checkPwaGeolocationEnabledState',
          { vc: this },
        );
        this.hasLocationPermission = enabledState !== 'denied';
        if (!this.hasLocationPermission) {
          this.$store.commit('sendGeolocationUpdates', false);
          this.$store.dispatch('showPermissionDeniedInstructions');
        }
      }
    },
    startWatchingGeolocation() {
      if (_.isFinite(this.geolocationWatchId)) {
        return;
      }
      this.geolocationWatchId = navigator.geolocation.watchPosition(
        (position) => {
          if (!this.$store.getters.isSimulating) {
            this.$store.commit('geolocationEnabledState', 'enabled');
            this.$store.commit('currentPosition', position);
          }
        },
        (error) => {
          switch (error.code) {
            case 1: // Permission denied
              console.log(`geolocation non-retry-able error: ${error.message}`);
              this.hasLocationPermission = false;
              this.$store.commit('sendGeolocationUpdates', false);
              // Chrome and Firefox have slightly different language
              if (error.message.indexOf('denied') !== -1) {
                this.$store.commit('geolocationEnabledState', 'denied');
                this.$store.dispatch('showPermissionDeniedInstructions');
              }
              break;

            // "Retry-able" errors
            case 0: // unknown error
            case 2: // position unavailable
            case 3: // timed out
              console.log(`geolocation retry-able error: ${error.message}`);
              if (this.$store.getters.isNativeApp) {
                this.$store.commit(
                  'geolocationEnabledState',
                  `error (${error.message}). retrying...`,
                );
              }
              break;
          }
        },
        {
          maximumAge: 0,
          enableHighAccuracy: true,
          timeout: 1000 * 60, // 1 minute
        },
      );
    },
    stopWatchingGeolocation() {
      if (
        _.isFinite(this.geolocationWatchId) &&
        _.isFunction(_.get(navigator, ['geolocation', 'clearWatch']))
      ) {
        navigator.geolocation.clearWatch(this.geolocationWatchId);
      }
      this.geolocationWatchId = null;
    },
    async resetAgencyState() {
      this.$store.commit('currentRouteKey', '');
      this.$store.commit('currentVehicleId', '');
      this.$store.commit('currentBlockId', '');
      this.$store.commit('currentTripInfo', null);
      this.$store.commit('currentVehicleInfo', null);
      this.$store.commit('lastValidVehicleInfo', null);
      this.$store.commit('currentVehicleInfoTimestamp', null);
      this.$store.commit('currentAgencyInfo', null);
      this.$store.commit('currentAgencyTransitimeConfig', null);
      this.$store.commit('currentOperatorIds', []);
      this.$store.commit('currentOperator', null);
      this.$store.commit('permanentlyAssignedVehicleId', '');
      this.$store.commit('showRouteStops', true);
      this.$store.commit('headwayMode', false);
      this.$store.commit('developerMode', false);
      this.$store.commit('showOperatorLogin', null);

      const geolocationEnabledState = await this.$store.dispatch(
        'checkPwaGeolocationEnabledState',
        { vc: this },
      );

      if (geolocationEnabledState !== 'native') {
        this.$store.commit(
          'sendGeolocationUpdates',
          geolocationEnabledState !== 'denied',
        );
      }
    },
    requestWakeLock() {
      if (this.$store.getters.isNativeApp) {
        this.$store.commit('wakeLockStatus', 'Native App');
        return;
      }
      try {
        if ('keepAwake' in screen) {
          screen.keepAwake = true;
          this.$store.commit('wakeLockStatus', 'Active (screen.keepAwake)');
        }
        if ('wakeLock' in navigator) {
          this.wakeLock = navigator.wakeLock.request('screen');
          this.$store.commit('wakeLockStatus', 'Active (WakeLock API)');
          if (typeof this.wakeLock.addEventListener === 'function') {
            this.wakeLock.addEventListener('release', () => {
              this.wakeLock = null;
              this.$store.commit(
                'wakeLockStatus',
                'Inactive (WakeLock API Released)',
              );
            });
          }
        } else {
          this.$store.commit(
            'wakeLockStatus',
            'Inactive (WakeLock API Unsupported); awaiting tap to activate NoSleep...',
          );
          this.enableNoSleepWakeLock();
        }
      } catch (error) {
        ErrorReporter.captureException(
          `Unable to activate Screen Wake Lock API: ${error.message}`,
        );
        this.$store.commit('wakeLockStatus', 'Inactive (WakeLock API Error)');
      }
    },
    enableNoSleep() {
      document.removeEventListener('click', this.enableNoSleep, false);
      _noSleep.enable();
      this.$store.commit('wakeLockStatus', 'Active (NoSleep.js).');
    },
    enableNoSleepWakeLock() {
      if (this.$store.getters.isNativeApp) {
        return;
      }
      document.addEventListener('click', this.enableNoSleep, false);
    },
    handleVisibilityChange() {
      if (document.visibilityState !== 'visible') {
        return;
      }
      if (this.wakeLock === null) {
        return;
      }
      this.requestWakeLock();
    },
    handleOnlineStatusChange(event) {
      const onlineStatus = _.get(event, 'type');
      if (onlineStatus === 'offline') {
        this.$store.commit('isOnline', false);
        Segment.track('app-offline');
      } else {
        this.$store.commit('isOnline', true);
        Segment.track('app-online');
      }
    },
    async checkIfShouldUpdate() {
      // Only allow updates while the user is on a non-driving screen
      const UPDATABLE_ROUTES = ['agency-selection', 'operator-login'];
      const currentRouteIsAllowedToUpdate = UPDATABLE_ROUTES.includes(
        this.$route.name,
      );
      if (!currentRouteIsAllowedToUpdate) {
        return;
      }

      // Only allow updates if the user has not interacted for at least 10 minutes
      const minutesSinceLastUserInteraction =
        (Date.now() - this.lastUserInteraction) / 1000 / 60;
      if (minutesSinceLastUserInteraction < 10) {
        return;
      }

      const currentAppHash = this.$store.getters.currentAppHash;
      const newestAppHash = await this.$store.dispatch('getAppHash');
      if (newestAppHash === null) {
        // This should only ever happen when using webpack's dev server
        return;
      }
      if (currentAppHash === newestAppHash) {
        return;
      }

      Segment.track('app-update', {
        oldVersion: currentAppHash,
        newVersion: newestAppHash,
      });

      this.$router.go();
    },
  },
};
</script>

<style lang="stylus">
@require "../styl/_colors.styl"

#app {
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    overflow hidden

    display flex
    flex-direction column

    &:not(.active-section-agency-selection, .active-section-operator-login)::before {
      content ''
      position absolute
      z-index -1
      top 0
      left 0
      width 100%
      height 100%
      background-image url('../images/swiftly-logo.png')
      background-repeat no-repeat
      background-position bottom center
      background-size 45px
      filter grayscale(100%) opacity(0.4)
    }

    > #loading-overlay {
        z-index 2
        position absolute
        top 0
        left 0
        width 100%
        height 100%
        background-color $black-trnsp-085
        text-align center
        opacity 1

        display flex
        flex-direction column
        justify-content center
    }

    &.prod-warning {
        border 2px solid $sea-buckthorn
    }
}
</style>
