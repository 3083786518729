import _ from 'lodash';

import { TRANSITIME_OPERATOR_FORMAT } from './format-operator';

const ALL_TABLET_AGENCIES = [
  {
    key: 'alexandria-dash',
    displayName: 'Alexandria DASH',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'alternating-current-test',
    displayName: 'Alternating Current Test (based on AC Transit)',
    isTestAgency: true,
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
      hideScreenWhenVehicleIsMoving: true,
    },
    privateRoadsDatasetId: 'clp8ok8z90iqc1wob560q0pyu',
  },
  {
    key: 'alternating-current-test-passenger-counting',
    aliasOf: 'alternating-current-test',
    displayName: 'Alternating Current Test Passenger Counting',
    isTestAgency: true,
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
      disableSafeDrivingMode: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'wheelchair', displayName: 'W/C' },
        { type: 'youth', displayName: 'Youth' },
        { type: 'general', displayName: 'General' },
        { type: 'senior-or-disabled', displayName: 'Sen/Dis' },
        { type: 'veteran', displayName: 'Veteran' },
        { type: 'child', displayName: 'Child' },
        { type: 'pass-or-free', displayName: 'Pass or Free' },
        { type: 'transfer', displayName: 'Transfer' },
      ],
    },
  },
  {
    key: 'appal-cart',
    displayName: 'AppalCART',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'arcata',
    displayName: 'Humboldt Transit Authority - Arcata',
    defaultSettings: {
      enableNativeMap: true,
      showRouteStops: false,
    },
  },
  {
    key: 'art-asheville',
    displayName: 'Asheville Rides Transit',
    vehiclesLicensed: '38',
    defaultSettings: {
      showOperatorLogin: true,
    },
  },
  {
    key: 'art',
    displayName: 'Asheville Rides Transit Test',
    defaultSettings: {
      showOperatorLogin: true,
      showRouteStops: false,
    },
  },
  {
    key: 'auckland-metrolink',
    displayName: 'Auckland Transport',
    vehiclesLicensed: '1300',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
      tripDisplayFormat: 'miami-style',
    },
  },
  {
    key: 'au-adelaide',
    displayName: 'Adelaide Metro',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'au-hillsbus',
    displayName: 'Hillsbus',
    defaultSettings: {
      showRouteStops: false,
    },
  },
  {
    key: 'au-hillsbus-test',
    displayName: 'Hillsbus Test',
    defaultSettings: {
      showRouteStops: false,
    },
  },
  {
    key: 'au-metro-melbourne',
    displayName: 'Donric - MTM',
    vehiclesLicensed: '5',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
    },
  },
  {
    key: 'au-metro-melbourne-dysons',
    displayName: 'Dysons - MTM',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
    },
  },
  {
    key: 'beaumont',
    displayName: 'Beaumont Transit',
    vehiclesLicensed: '20',
    defaultSettings: {
      enableNativeMap: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'wheelchair', displayName: 'W/C' },
        { type: 'youth', displayName: 'Youth' },
        { type: 'general', displayName: 'General' },
        { type: 'senior-or-disabled', displayName: 'Sen/Dis' },
        { type: 'veteran', displayName: 'Veteran' },
        { type: 'child', displayName: 'Child' },
        { type: 'pass-or-free', displayName: 'Pass or Free' },
        { type: 'transfer', displayName: 'Transfer' },
      ],
    },
  },
  {
    key: 'beaumont-test',
    displayName: 'Beaumont Transit Test',
    isTestAgency: true,
    vehiclesLicensed: '20',
    defaultSettings: {
      enableNativeMap: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'wheelchair', displayName: 'W/C' },
        { type: 'youth', displayName: 'Youth' },
        { type: 'general', displayName: 'General' },
        { type: 'senior-or-disabled', displayName: 'Sen/Dis' },
        { type: 'veteran', displayName: 'Veteran' },
        { type: 'child', displayName: 'Child' },
        { type: 'pass-or-free', displayName: 'Pass or Free' },
        { type: 'transfer', displayName: 'Transfer' },
      ],
    },
  },
  {
    key: 'ben-franklin-transit',
    displayName: 'Ben Franklin Transit',
    defaultSettings: {
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'bennington-gmcn',
    displayName: 'Bennington GMCN',
    defaultSettings: {
      showOperatorLogin: true,
      enableNativeMap: true,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'regular', displayName: 'Regular' },
        { type: 'child', displayName: 'Child' },
        { type: 'university-student', displayName: 'University Student' },
        { type: 'other-student', displayName: 'Other Student' },
        { type: 'senior', displayName: 'Senior' },
        { type: 'mobility-device', displayName: 'Mobility Device' },
        { type: 'bike', displayName: 'Bike' },
        {
          type: '1/2-price-special-events',
          displayName: '1/2 Price (Special events)',
        },
      ],
    },
  },
  {
    key: 'big-blue-bus',
    displayName: 'Santa Monica Big Blue Bus',
    defaultSettings: {
      sendGeolocationUpdates: true,
      enableServiceAdjustments: true,
      enableNativeMap: true,
    },
  },
  {
    key: 'bluego',
    displayName: 'Tahoe Transportation District',
    defaultSettings: {},
  },
  {
    key: 'bluego-test',
    displayName: 'Tahoe Transportation District Test',
    defaultSettings: {},
  },
  {
    key: 'bluewater',
    displayName: 'Blue Water Area Transit',
    vehiclesLicensed: '16',
    defaultSettings: {
      showOperatorLogin: true,
      enableNativeMap: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'regular', displayName: 'Regular' },
        { type: 'student', displayName: 'Student' },
        { type: 'senior', displayName: 'Senior' },
        { type: 'disabled', displayName: 'Disabled' },
        { type: 'senior-disabled', displayName: 'Senior Disabled' },
      ],
    },
  },
  {
    key: 'brightline-fl-us',
    displayName: 'Brightline',
    vehiclesLicensed: '6',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'broward-county',
    displayName: 'Broward County Transit',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'bsoob',
    displayName: 'Biddeford Saco Old Orchard Beach Transit',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'ca-sts-sherbrooke',
    displayName: 'Société de Transport de Sherbrooke',
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
    },
  },
  {
    key: 'cap-metro',
    displayName: 'Austin Capital Metro',
    vehiclesLicensed: '23',
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
    },
    privateRoadsDatasetId: 'clrjt4r0f04i31mroa7auxgzl',
  },
  {
    key: 'cap-metro-test',
    displayName: 'Austin Capital Metro Test',
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
      enableZeroTouchLogin: true,
    },
    privateRoadsDatasetId: 'clrjt4r0f04i31mroa7auxgzl',
  },
  {
    key: 'county-connection-sandbox',
    displayName: 'County Connection Sandbox',
    vehiclesLicensed: '125',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'cttransit',
    displayName: 'CTTransit',
    vehiclesLicensed: '2',
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      enableZeroTouchLogin: true,
    },
  },
  {
    key: 'cttransit-sandbox',
    displayName: 'CTTransit (Sandbox)',
    vehiclesLicensed: '2',
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      enableZeroTouchLogin: true,
    },
  },
  {
    key: 'city-of-miami-beach',
    displayName: 'City of Miami Beach Trolley',
    defaultSettings: {
      enableNativeMap: true,
      tripDisplayFormat: 'miami-style',
    },
  },
  {
    key: 'cta',
    displayName: 'Chicago Transit Authority',
    defaultSettings: {
      enableNativeMap: true,
    },
  },
  {
    key: 'dcta',
    displayName: 'DCTA (Denton County Transportation Authority)',
    defaultSettings: {
      showOperatorLogin: true,
      enableNativeMap: true,
      enableServiceAdjustments: true,
      turnByTurnRoutes: ['3'],
    },
  },
  {
    key: 'delano-dart',
    displayName: 'Delano Area Rapid Transit',
    vehiclesLicensed: '9',
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
    },
  },
  {
    key: 'detroit-m1-rail',
    displayName: 'Qline Detroit',
    vehiclesLicensed: '6',
    defaultSettings: {
      showOperatorLogin: false,
      enableServiceAdjustments: true,
      operatorAssignmentFormat: TRANSITIME_OPERATOR_FORMAT.ID_FULL_NAME,
      disableHeading: true,
      sendGeolocationUpdates: true,
    },
  },
  {
    key: 'detroit-m1-rail-sandbox',
    displayName: 'Qline Detroit Sandbox',
    defaultSettings: {
      showOperatorLogin: true,
      enableServiceAdjustments: true,
      operatorAssignmentFormat: TRANSITIME_OPERATOR_FORMAT.ID_FULL_NAME,
      disableHeading: true,
    },
  },
  //For Tim to test with locally
  {
    key: 'detroit-smartbus-test',
    displayName: 'Detroit SMART-Bus Test',
    isTestAgency: true,
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
      enableVehiclePermanentAssignment: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'regular', displayName: 'Regular' },
        { type: 'child', displayName: 'Child' },
        { type: 'senior', displayName: 'Senior' },
      ],
    },
    privateRoadsDatasetId: 'clp8ojt4z0j561mob5zcvbkx9',
  },
  {
    key: 'detroit-smartbus-sandbox',
    displayName: 'Detroit SMART-Bus Sandbox',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'regular', displayName: 'Regular' },
        { type: 'child', displayName: 'Child' },
        { type: 'senior', displayName: 'Senior' },
      ],
    },
    privateRoadsDatasetId: 'clp8ojt4z0j561mob5zcvbkx9',
  },
  {
    key: 'durham-region',
    displayName: 'Durham Region Transit',
    vehiclesLicensed: '10',
    defaultSettings: {
      showRouteStops: false,
      enableNativeMap: false,
    },
  },
  {
    key: 'edmonton',
    displayName: 'Edmonton Transit Service',
    defaultSettings: {
      showRouteStops: false,
    },
  },
  {
    key: 'es-soler-i-sauret',
    displayName: 'Soler i Sauret - Martorell',
    vehiclesLicensed: '2',
    defaultSettings: {
      enableNativeMap: true,
      restrictNativeMapToSpecificRoutes: ['01', '02'],
      enableServiceAdjustments: true,
      showRouteStops: false,
    },
  },
  {
    key: 'es-alsa-mediterraneo-sandbox',
    displayName: 'Alsa Mediterraneo Sandbox - OA Testing (MartaZ)',
    defaultSettings: {
      enableServiceAdjustments: true,
      showOperatorLogin: true,
      enableNativeMap: true,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'full-fare', displayName: 'Full Fare' },
        { type: 'senior', displayName: 'Senior' },
        { type: 'youth', displayName: 'Youth' },
        { type: 'free', displayName: 'Free' },
      ],
    },
  },
  {
    key: 'es-alsa-sandbox',
    displayName: 'Alsa Sandbox',
    vehiclesLicensed: '0',
    activeVehiclesGoal: '0',
    defaultSettings: {
      enableServiceAdjustments: true,
      showOperatorLogin: true,
      enableNativeMap: true,
    },
  },
  {
    key: 'fairfield-fast',
    vehiclesLicensed: '28',
    displayName: 'Fairfield and Suisun Transit (FAST)',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'fairfield-fast-sandbox',
    displayName: 'Fairfield FAST Sandbox',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'frederick-county-transit-sandbox',
    displayName: 'TransIT Services of Frederick County Sandbox',
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
      restrictNativeMapToSpecificRoutes: ['40', '50'],
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'regular', displayName: 'Regular' },
        { type: 'wheelchair', displayName: 'Wheelchair' },
        { type: 'bicycle', displayName: 'Bicycle' },
      ],
    },
  },
  {
    key: 'godurham',
    displayName: 'GoDurham',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
      disableSafeDrivingMode: true,
    },
  },
  {
    key: 'gold-country-stage',
    displayName: 'Nevada County Gold Country Stage',
    defaultSettings: {
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'gold-country-stage-sandbox',
    displayName: 'Nevada County Gold Country Stage Sandbox',
    vehiclesLicensed: '19',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'adult', displayName: 'Adult' },
        { type: 'discount', displayName: 'Discount' },
        { type: 'children', displayName: 'Children' },
        { type: 'transfers', displayName: 'Transfers' },
      ],
    },
  },
  {
    key: 'golden-gate-park',
    displayName: 'San Francisco Recreation and Parks',
    vehiclesLicensed: '4',
    defaultSettings: {
      showOperatorLogin: true,
      enableServiceAdjustments: true,
      enableNativeMap: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'adult', displayName: 'Adult' },
        { type: 'child', displayName: 'Child' },
        { type: 'senior', displayName: 'Senior' },
        { type: 'disabled', displayName: 'Disabled/Lift Used' },
      ],
    },
  },
  {
    key: 'goose-creek-transit',
    displayName: 'Goose Creek Transit',
    defaultSettings: {
      enableNativeMap: true,
      showOperatorLogin: true,
      enableServiceAdjustments: true,
      sendGeolocationUpdates: true,
      avlReportingInterval: '5',
    },
  },
  {
    key: 'green-mountain',
    displayName: 'Green Mountain Transit',
    vehiclesLicensed: '68',
    activeVehiclesGoal: '40',
    defaultSettings: {
      sendGeolocationUpdates: true,
      headwayMode: false,
      developerMode: false,
      showOperatorLogin: true,
      enableServiceAdjustments: true,
      enableNativeMap: true,
    },
  },
  {
    key: 'green-mountain-test',
    displayName: 'Green Mountain Transit Test',
    defaultSettings: {
      sendGeolocationUpdates: true,
      headwayMode: false,
      developerMode: false,
      showOperatorLogin: true,
      enableServiceAdjustments: true,
      enableNativeMap: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'regular', displayName: 'Regular' },
        { type: 'child', displayName: 'Child' },
        { type: 'senior', displayName: 'Senior' },
      ],
    },
  },
  {
    key: 'harbor-transit',
    displayName: 'Harbor Transit',
    vehiclesLicensed: '3',
    defaultSettings: {
      enableNativeMap: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'adult', displayName: '19-59 years old' },
        { type: 'child-5-18', displayName: '5-18 years old' },
        { type: 'senior', displayName: '60+ years old' },
        {
          type: 'senior-disability-medicare',
          displayName: '60+ persons with a disability or Medicare',
        },
        {
          type: 'disability-medicare',
          displayName: 'Persons with a disability or Medicare',
        },
        { type: 'child-4-and-under', displayName: 'Children 4 years & under' },
      ],
    },
  },
  {
    key: 'hill-county-hop',
    displayName: 'TheHOP',
    defaultSettings: {
      showOperatorLogin: true,
      enableNativeMap: true,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      tripDisplayFormat: 'miami-style',
      passengerCountingEnabled: true,
      passengerCountingButtons: [{ type: 'regular', displayName: 'Regular' }],
    },
  },
  {
    key: 'is-straeto',
    displayName: 'Strætó',
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
      turnByTurnRoutes: ['18'],
    },
  },
  {
    key: 'is-straeto-sandbox',
    displayName: 'Strætó Sandbox',
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
      turnByTurnRoutes: ['18'],
    },
  },
  {
    key: 'johnson-county',
    displayName: 'Johnson County',
    defaultSettings: {
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'josephine-jct',
    displayName: 'Josephine Community Transit',
    vehiclesLicensed: '4',
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
    },
  },
  {
    key: 'kcata',
    displayName: 'Kansas City KCATA',
    vehiclesLicensed: '10',
    defaultSettings: {
      showOperatorLogin: true,
      enableServiceAdjustments: true,
      tripDisplayFormat: 'miami-style',
      enableNativeMap: true,
      restrictNativeMapToSpecificRoutes: [
        '21',
        '25',
        '102',
        '103',
        '113',
        '116',
        '118',
      ],
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'passenger', displayName: 'Passenger' },
        { type: 'bicycle', displayName: 'Bicycle' },
        { type: 'wheelchair', displayName: 'Wheelchair' },
      ],
    },
  },
  {
    key: 'kern-transit',
    displayName: 'Kern Transit',
    vehiclesLicensed: '27',
    defaultSettings: {
      showOperatorLogin: true,
      enableNativeMap: true,
      disableMapboxMatching: true,
      restrictDisableMapboxMatchingToSpecificRoutes: ['100', '250', '110'],
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'mobility-senior', displayName: 'Mobility Aid/Senior Rider' },
        { type: 'student', displayName: 'Student Rider' },
        { type: 'other', displayName: 'Other Rider' },
      ],
    },
  },
  {
    key: 'ketchikan',
    displayName: 'Ketchikan The Bus',
    vehiclesLicensed: '12',
    defaultSettings: {
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      enableNativeMap: true,
      enableServiceAdjustments: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'free-student', displayName: 'Free Student' },
        { type: 'special-event', displayName: 'Special Event' },
      ],
      restrictNativeMapToSpecificRoutes: [
        'Green Line',
        'Silver Line',
        'Downtown Shuttle',
      ],
    },
  },
  {
    key: 'ketchikan-sandbox',
    displayName: 'Ketchikan The Bus (Sandbox)',
    vehiclesLicensed: '12',
    defaultSettings: {
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      enableNativeMap: true,
      enableServiceAdjustments: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'free-student', displayName: 'Free Student' },
        { type: 'special-event', displayName: 'Special Event' },
      ],
      restrictNativeMapToSpecificRoutes: [
        'Green Line Loop',
        'Silver Line',
        'Downtown Shuttle',
      ],
    },
  },
  {
    key: 'king-county',
    displayName: 'King County Metro',
    defaultSettings: {
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'king-county-asm',
    vehiclesLicensed: '20',
    displayName: 'King County Metro Pilot (Headways)',
    defaultSettings: {
      headwayMode: true,
      enableNativeMap: true,
      enableServiceAdjustments: true,
      sendGeolocationUpdates: true,
      showRouteStops: false,
    },
  },
  {
    key: 'king-county-asm-sandbox',
    vehiclesLicensed: '20',
    displayName: 'King County Metro Pilot (Sandbox)',
    defaultSettings: {
      headwayMode: true,
      enableNativeMap: true,
      enableServiceAdjustments: true,
      showRouteStops: false,
    },
  },
  {
    key: 'lametro',
    vehiclesLicensed: '60',
    displayName: 'LA Metro',
    defaultSettings: {
      showRouteStops: false,
      enableNativeMap: false,
      enableServiceAdjustments: true,
      headwayMode: true,
      enableZeroTouchLogin: true,
    },
  },
  {
    key: 'lametro-sandbox',
    displayName: 'LA Metro (Sandbox)',
    defaultSettings: {
      enableNativeMap: false,
      enableServiceAdjustments: true,
      headwayMode: true,
      enableZeroTouchLogin: true,
      showRouteStops: false,
    },
  },
  {
    key: 'las-vegas-keolis',
    vehiclesLicensed: '18',
    displayName: 'Las Vegas Keolis',
    defaultSettings: {
      showOperatorLogin: true,
      enableNativeMap: false,
      restrictNativeMapToSpecificRoutes: ['DEUCE'],
      disableMapboxMatching: true,
      showRouteStops: false,
    },
  },
  {
    key: 'las-vegas-rtc',
    displayName: 'Las Vegas RTC',
    defaultSettings: {
      showOperatorLogin: true,
      enableNativeMap: false,
      restrictNativeMapToSpecificRoutes: ['DEUCE'],
      disableMapboxMatching: true,
      showRouteStops: false,
    },
  },
  {
    key: 'las-vegas-rtc-sandbox',
    displayName: 'Las Vegas RTC Sandbox',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'lincoln-startran',
    displayName: 'Lincoln StarTran',
    defaultSettings: {
      showRouteStops: false,
    },
  },
  {
    key: 'lorain-county',
    displayName: 'Lorain County Transit',
    defaultSettings: {
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      enableNativeMap: true,
      enableServiceAdjustments: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'full-fare', displayName: 'Full Fare' },
        { type: 'half-fare', displayName: 'Half Fare' },
        { type: 'veteran', displayName: 'Veteran' },
        { type: 'student', displayName: 'Student' },
        { type: 'no-charge', displayName: 'No Charge' },
        { type: 'wheelchair', displayName: 'Wheelchair' },
        { type: 'bike', displayName: 'Bike' },
      ],
    },
  },
  {
    key: 'lorain-county-sandbox',
    displayName: 'Lorain County Transit (Sandbox)',
    defaultSettings: {
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      enableNativeMap: true,
      enableServiceAdjustments: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'full-fare', displayName: 'Full Fare' },
        { type: 'half-fare', displayName: 'Half Fare' },
        { type: 'veteran', displayName: 'Veteran' },
        { type: 'student', displayName: 'Student' },
        { type: 'no-charge', displayName: 'No Charge' },
        { type: 'wheelchair', displayName: 'Wheelchair' },
        { type: 'bike', displayName: 'Bike' },
      ],
    },
  },
  {
    key: 'lubbock-citibus',
    displayName: 'Citibus',
    vehiclesLicensed: '15',
    defaultSettings: {
      enableServiceAdjustments: true,
      showOperatorLogin: true,
    },
  },
  {
    key: 'macatawa-max',
    displayName: 'Macatawa Area Express Transportation Authority',
    vehiclesLicensed: '25',
    defaultSettings: {
      enableNativeMap: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'full-fare-cash', displayName: 'Full Fare Cash' },
        { type: 'full-fare-pass', displayName: 'Full Fare Pass' },
        { type: 'ada-cash', displayName: 'ADA Cash' },
        { type: 'ada-pass', displayName: 'ADA Pass' },
        { type: 'senior-fare-cash', displayName: 'Senior Fare Cash' },
        { type: 'senior-fare-pass', displayName: 'Senior Fare Pass' },
        { type: 'youth-fare-cash', displayName: 'Youth Fare Cash' },
        { type: 'youth-fare-pass', displayName: 'Youth Fare Pass' },
      ],
    },
  },
  {
    key: 'marble-valley',
    displayName: 'Marble Valley The Bus',
    defaultSettings: {
      showOperatorLogin: true,
      enableNativeMap: true,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'regular', displayName: 'Regular' },
        { type: 'child', displayName: 'Child' },
        { type: 'university-student', displayName: 'University Student' },
        { type: 'other-student', displayName: 'Other Student' },
        { type: 'senior', displayName: 'Senior' },
        { type: 'mobility-device', displayName: 'Mobility Device' },
        { type: 'bike', displayName: 'Bike' },
        {
          type: '1/2-price-special-events',
          displayName: '1/2 Price (Special events)',
        },
      ],
    },
  },
  {
    key: 'marin-transit',
    displayName: 'Marin Transit',
    vehiclesLicensed: '71',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'marta-sandbox',
    displayName: 'Marta Sandbox',
    defaultSettings: {
      showRouteStops: false,
      enableNativeMap: true,
      enableServiceAdjustments: true,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
    },
    privateRoadsDatasetId: 'clsytnxeh1hvt1mqbs7hawpwm',
  },
  {
    key: 'massport',
    displayName: 'Massport',
    vehiclesLicensed: '89',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
      operatorAssignmentFormat: TRANSITIME_OPERATOR_FORMAT.ID_FULL_NAME,
      showOperatorLogin: true,
    },
  },
  {
    key: 'massport-sandbox',
    displayName: 'Massport Sandbox',
    vehiclesLicensed: '89',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
      operatorAssignmentFormat: TRANSITIME_OPERATOR_FORMAT.ID_FULL_NAME,
      showOperatorLogin: true,
    },
  },
  {
    key: 'mbta-bus',
    displayName: 'Boston MBTA',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
      hideScreenWhenVehicleIsMoving: true,
      showOperatorLogin: false,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      enableZeroTouchLogin: true,
      zeroTouchOperatorAllowList: [
        'GILLEN - 71156',
        'LEE - 6170',
        'SANTOS - 67312',
        'DAVIS - 516234',
        'KING - 533886',
        'LEDOUX - 553327',
        'LAIFOOK - 67129 ',
        'OGORMAN - 65460',
      ],
    },
  },
  {
    key: 'mbta-bus-oa-pilot-sandbox',
    displayName: 'Boston MBTA Buses Test (OA Pilot) Sandbox',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
      hideScreenWhenVehicleIsMoving: true,
      showOperatorLogin: false,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      enableZeroTouchLogin: true,
      zeroTouchOperatorAllowList: [
        'GILLEN - 71156',
        'LEE - 6170',
        'SANTOS - 67312',
        'DAVIS - 516234',
        'KING - 533886',
        'LEDOUX - 553327',
        'LAIFOOK - 67129 ',
        'OGORMAN - 65460',
      ],
    },
    privateRoadsDatasetId: 'clp7bydyj04h91trxtx5e5niv',
  },
  {
    key: 'mbta-bus-test',
    displayName: 'Boston MBTA Test',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
      enableZeroTouchLogin: false,
      hideScreenWhenVehicleIsMoving: false,
      showOperatorLogin: false,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
    },
    privateRoadsDatasetId: 'clp7bydyj04h91trxtx5e5niv',
  },
  // To allow Dave Maltzan to test passenger counting as he lives near MBTA
  {
    key: 'mbta-bus-sandbox',
    displayName: 'Boston MBTA Sandbox',
    defaultSettings: {
      enableNativeMap: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'adult', displayName: 'Adult fare' },
        { type: '1-day-pass', displayName: '1-Day Pass' },
        { type: '7-day-pass', displayName: '7-Day Pass' },
        { type: 'monthly-local-pass', displayName: 'Monthly Local Bus Pass' },
        { type: 'monthly-link-pass', displayName: 'Monthly LinkPass' },
        { type: 'child', displayName: 'Child' },
        { type: 'senior', displayName: 'Senior' },
        { type: 'tap', displayName: 'TAP' },
      ],
      passengerCountingRoutes: ['28', '77'],
      enableServiceAdjustments: true,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
    },
  },
  {
    key: 'mendocino',
    displayName: 'Mendocino Transit Authority',
    vehiclesLicensed: '29',
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
      showOperatorLogin: false,
      passengerCountingEnabled: true,
      passengerCountingButtons: [{ type: 'any', displayName: 'On' }],
    },
  },
  {
    key: 'miami',
    displayName: 'Miami-Dade Transit',
    defaultSettings: {
      sendGeolocationUpdates: true,
      tripDisplayFormat: 'miami-style',
      showRouteStops: false,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'ambulatory', displayName: 'Ambulatory' },
        { type: 'wheelchair', displayName: 'Wheelchair' },
        { type: 'service-animal', displayName: 'Service Animal' },
      ],
      enableNativeMap: true,
      restrictNativeMapToSpecificRoutes: ['MTROLJN', 'MTRONOW', 'MTROOPA'],
    },
  },
  {
    key: 'miami-gardens',
    displayName: 'City of Miami Gardens',
    vehiclesLicensed: '4',
    defaultSettings: {
      showOperatorLogin: true,
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'miami-test',
    displayName: 'Miami-Dade Transit Test',
    defaultSettings: {
      sendGeolocationUpdates: true,
      enableServiceAdjustments: true,
      tripDisplayFormat: 'miami-style',
      showRouteStops: false,
    },
  },
  {
    key: 'miami-safeguard',
    displayName: 'Miami-Dade Transit (SafeGuard)',
    defaultSettings: {
      operatorAssignmentFormat: TRANSITIME_OPERATOR_FORMAT.ID_FULL_NAME,
      sendGeolocationUpdates: true,
      showOperatorLogin: true,
      tripDisplayFormat: 'miami-style',
      showRouteStops: false,
    },
  },
  {
    key: 'moover',
    displayName: 'MOOver',
    defaultSettings: {
      showOperatorLogin: true,
      enableNativeMap: true,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'regular', displayName: 'Regular' },
        { type: 'child', displayName: 'Child' },
        { type: 'university-student', displayName: 'University Student' },
        { type: 'other-student', displayName: 'Other Student' },
        { type: 'senior', displayName: 'Senior' },
        { type: 'mobility-device', displayName: 'Mobility Device' },
        { type: 'bike', displayName: 'Bike' },
        {
          type: '1/2-price-special-events',
          displayName: '1/2 Price (Special events)',
        },
      ],
    },
  },
  {
    key: 'mt-cunisa',
    displayName: 'Malta Public Transport',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
      restrictNativeMapToSpecificRoutes: [
        '48',
        '13A',
        '31',
        '13',
        '14',
        '45',
        '41',
        '42',
        'TD13',
        '46',
      ],
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
    },
  },
  {
    key: 'mta-maryland',
    vehiclesLicensed: '1',
    displayName: 'Maryland MTA Core Bus',
    defaultSettings: {
      enableServiceAdjustments: true,
      showOperatorLogin: false,
      enableNativeMap: false,
      headwayMode: false,
      showTimepointsOnlyInMapView: false,
      showTimepointsOnlyInOtpView: false,
      enableZeroTouchLogin: true,
    },
  },
  {
    key: 'mta-maryland-light-rail',
    displayName: 'Maryland MTA Light Rail',
    defaultSettings: {
      showRouteStops: false,
    },
  },
  {
    key: 'mta-maryland-test',
    displayName: 'Maryland Transit Administration Test',
    defaultSettings: {
      enableServiceAdjustments: true,
      showRouteStops: false,
      headwayMode: false,
      showTimepointsOnlyInMapView: false,
      showTimepointsOnlyInOtpView: false,
      enableZeroTouchLogin: true,
    },
  },
  {
    key: 'ncrtd',
    displayName: 'NCRTD',
    defaultSettings: {
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'nl-connexxion',
    displayName: 'Connexxion (NL Ovapi)',
    defaultSettings: {
      showOperatorLogin: true,
      enableServiceAdjustments: true,
      enableNativeMap: true,
    },
  },
  {
    key: 'nycmta-manhattan',
    displayName: 'MTA New York City Transit',
    defaultSettings: {
      showOperatorLogin: true,
      enableServiceAdjustments: true,
      enableNativeMap: true,
    },
  },
  {
    key: 'ratp-paris',
    displayName: 'Régie Autonome des Transports Parisiens',
    vehiclesLicensed: '20',
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
      restrictNativeMapToSpecificRoutes: ['538', '574', '577'],
    },
  },
  {
    key: 'rct',
    displayName: 'Rural Community Transportation',
    defaultSettings: {
      showOperatorLogin: true,
      enableNativeMap: true,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'regular', displayName: 'Regular' },
        { type: 'child', displayName: 'Child' },
        { type: 'university-student', displayName: 'University Student' },
        { type: 'other-student', displayName: 'Other Student' },
        { type: 'senior', displayName: 'Senior' },
        { type: 'mobility-device', displayName: 'Mobility Device' },
        { type: 'bike', displayName: 'Bike' },
        {
          type: '1/2-price-special-events',
          displayName: '1/2 Price (Special events)',
        },
      ],
    },
  },
  {
    key: 'rct-sandbox',
    displayName: 'Rural Community Transportation (Sandbox)',
    defaultSettings: {
      showOperatorLogin: true,
      enableNativeMap: true,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'regular', displayName: 'Regular' },
        { type: 'child', displayName: 'Child' },
        { type: 'university-student', displayName: 'University Student' },
        { type: 'other-student', displayName: 'Other Student' },
        { type: 'senior', displayName: 'Senior' },
        { type: 'mobility-device', displayName: 'Mobility Device' },
        { type: 'bike', displayName: 'Bike' },
        {
          type: '1/2-price-special-events',
          displayName: '1/2 Price (Special events)',
        },
      ],
    },
  },
  {
    key: 'redwood',
    displayName: 'Humboldt Transit Authority - Redwood',
    defaultSettings: {
      enableNativeMap: true,
      showOperatorLogin: false,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
    },
  },
  {
    key: 'redwood-coast-rct',
    displayName: 'Redwood Coast Transit',
    vehiclesLicensed: '13',
    defaultSettings: {
      enableNativeMap: true,
      showOperatorLogin: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'youth', displayName: 'Youth' },
        { type: 'senior-or-disabled', displayName: 'Senior Or Disabled' },
        { type: 'child', displayName: 'Child' },
        { type: 'long-distance-zone-2', displayName: '2-Zone' },
        { type: 'long-distance-zone-3', displayName: '3-Zone' },
        { type: 'long-distance-zone-4', displayName: '4-Zone' },
        { type: 'long-distance-zone-5', displayName: '5-Zone' },
        { type: 'long-distance-zone-6', displayName: '6-Zone' },
      ],
    },
  },
  {
    key: 'rtamaryland',
    displayName: 'RTA of Central Maryland',
    vehiclesLicensed: '43',
    activeVehiclesGoal: '25',
    defaultSettings: {
      avlReportingInterval: '5',
      operatorAssignmentFormat: TRANSITIME_OPERATOR_FORMAT.ID_FULL_NAME,
      tripDisplayFormat: 'miami-style',
      enableServiceAdjustments: true,
      enableNativeMap: true,
      showOperatorLogin: true,
      disableSafeDrivingMode: true,
    },
  },
  {
    key: 'rtd-denver',
    displayName: 'Denver RTD',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'santa-maria',
    displayName: 'Santa Maria Area Transit',
    defaultSettings: {},
  },
  {
    key: 'santa-maria-test',
    displayName: 'Santa Maria Area Transit Test',
    isTestAgency: true,
    defaultSettings: {
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'seattle-streetcar',
    displayName: 'City of Seattle',
    vehiclesLicensed: '10',
    defaultSettings: {
      showRouteStops: false,
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'septa',
    displayName: 'Philadelphia SEPTA',
    defaultSettings: {
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'septa-rail',
    displayName: 'Philadelphia SEPTA Rail',
    defaultSettings: {
      enableNativeMap: true,
    },
  },
  {
    key: 'setd',
    displayName: 'Sunset Empire Transportation District',
    defaultSettings: {
      enableNativeMap: true,
      showOperatorLogin: true,
    },
  },
  {
    key: 'sfbay-ferry-sandbox',
    displayName: 'SF Bay Area WETA (Sandbox)',
    defaultSettings: {
      showRouteStops: false,
      enableServiceAdjustments: true,
      enableNativeMap: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'child', displayName: 'Child' },
        { type: 'general', displayName: 'General' },
        { type: 'pass-or-free', displayName: 'Pass Or Free' },
        { type: 'senior-or-disabled', displayName: 'Senior Or Disabled' },
        { type: 'veteran', displayName: 'Veteran' },
        { type: 'youth', displayName: 'Youth' },
      ],
    },
  },
  {
    key: 'sfmta',
    displayName: 'SFMTA',
    defaultSettings: {
      enableNativeMap: true,
    },
    privateRoadsDatasetId: 'cllwjth010yer2gplm5d19ykg',
  },
  {
    key: 'sfmtaTest',
    displayName: 'SFMTA Test',
    isTestAgency: true,
    defaultSettings: {
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      enableServiceAdjustments: true,
      enableNativeMap: true,
      hideScreenWhenVehicleIsMoving: true,
    },
    privateRoadsDatasetId: 'cllwjth010yer2gplm5d19ykg',
  },
  {
    key: 'sfmtaTestWithPassengerCounting',
    aliasOf: 'sfmtaTest',
    displayName: 'SFMTA Test Passenger Counting',
    isTestAgency: true,
    defaultSettings: {
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      enableServiceAdjustments: true,
      enableNativeMap: true,
      hideScreenWhenVehicleIsMoving: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'adult', displayName: '19-59 years old' },
        { type: 'child-5-18', displayName: '5-18 years old' },
        { type: 'senior', displayName: '60+ years old' },
        {
          type: 'senior-disability-medicare',
          displayName: '60+ persons with a disability or Medicare',
        },
        {
          type: 'disability-medicare',
          displayName: 'Persons with a disability or Medicare',
        },
        { type: 'child-4-and-under', displayName: 'Children 4 years & under' },
      ],
    },
    privateRoadsDatasetId: 'cllwjth010yer2gplm5d19ykg',
  },
  {
    key: 'sfmtaTestWithZeroTouchLogin',
    aliasOf: 'sfmtaTest',
    displayName: 'SFMTA Test Zero Touch',
    isTestAgency: true,
    defaultSettings: {
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      enableServiceAdjustments: true,
      enableNativeMap: true,
      enableZeroTouchLogin: true,
      zeroTouchOperatorAllowList: ['2242'],
    },
  },
  {
    key: 'south-portland-transit',
    displayName: 'City of South Portland',
    vehiclesLicensed: '9',
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'full-fare', displayName: 'Full Fare' },
        { type: 'half-fare', displayName: 'Half Fare' },
        { type: 'no-charge', displayName: 'No Charge' },
        {
          type: 'wheel-chair-lift-deployed',
          displayName: 'Wheel Chair/Lift Deployed',
        },
        {
          type: 'promo-electronic-fare-payment-declined',
          displayName: 'Promo/Electronic Fare Payment Declined',
        },
        {
          type: 'maine-medical-institutional',
          displayName: 'Maine Medical (institutional)',
        },
        { type: 'sp-school', displayName: 'SP School' },
        { type: 'transfer', displayName: 'Transfer' },
      ],
    },
  },
  {
    key: 'south-portland-transit-sandbox',
    displayName: 'City of South Portland (Sandbox)',
    vehiclesLicensed: '9',
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'full-fare', displayName: 'Full Fare' },
        { type: 'half-fare', displayName: 'Half Fare' },
        { type: 'no-charge', displayName: 'No Charge' },
        {
          type: 'wheel-chair-lift-deployed',
          displayName: 'Wheel Chair/Lift Deployed',
        },
        {
          type: 'promo-electronic-fare-payment-declined',
          displayName: 'Promo/Electronic Fare Payment Declined',
        },
        {
          type: 'maine-medical-institutional',
          displayName: 'Maine Medical (institutional)',
        },
        { type: 'sp-school', displayName: 'SP School' },
        { type: 'transfer', displayName: 'Transfer' },
      ],
    },
  },
  {
    key: 'springfield-thebus',
    displayName: 'CityUtilities',
    defaultSettings: {
      showOperatorLogin: true,
      enableServiceAdjustments: true,
      enableNativeMap: true,
    },
  },
  {
    key: 'steamboat-springs-sst',
    displayName: 'Steamboat Springs Transit',
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
    },
  },
  {
    key: 'suffolk-county',
    displayName: 'Suffolk County Transit',
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
    },
  },
  {
    key: 'summit-stage',
    displayName: 'Summit Stage',
    vehiclesLicensed: '7',
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
    },
  },
  {
    key: 'swiftly-demo',
    displayName: 'Swiftly Demo',
    vehiclesLicensed: '0',
    activeVehiclesGoal: '0',
    defaultSettings: {
      enableServiceAdjustments: true,
      showOperatorLogin: true,
      enableNativeMap: true,
    },
    privateRoadsDatasetId: 'clp8ok8z90iqc1wob560q0pyu',
  },
  {
    key: 'swiftly-demo-europe',
    displayName: 'Swiftly Demo Europe',
    vehiclesLicensed: '0',
    activeVehiclesGoal: '0',
    defaultSettings: {
      enableServiceAdjustments: true,
      showOperatorLogin: true,
      enableNativeMap: true,
    },
  },
  {
    key: 'swiftly-demo-small',
    displayName: 'Swiftly Demo (Small)',
    defaultSettings: {
      showRouteStops: false,
      enableServiceAdjustments: true,
      enableNativeMap: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'adult', displayName: 'Adult' },
        { type: 'child', displayName: 'Child' },
        { type: 'reduced-fare', displayName: 'Reduced Fare' },
        { type: 'monthly-pass', displayName: 'Monthly Pass' },
        { type: 'custom-example', displayName: 'Your label here!' },
      ],
    },
  },
  {
    key: 'swiftly-demo-tiny',
    displayName: 'Swiftly Demo Tiny',
    defaultSettings: {
      enableServiceAdjustments: true,
      enableNativeMap: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'adult', displayName: 'Adult' },
        { type: 'senior', displayName: 'Senior' },
        { type: 'youth', displayName: 'Youth' },
        {
          type: 'mobility-device-adult',
          displayName: 'Mobility Device (Adult)',
        },
        {
          type: 'mobility-device-youth',
          displayName: 'Mobility Device (Youth)',
        },
      ],
    },
  },
  {
    key: 'tampa',
    displayName: 'Tampa HART',
    vehiclesLicensed: '10',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'tampa-sandbox',
    displayName: 'Tampa HART (Sandbox)',
    vehiclesLicensed: '10',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'torrance',
    displayName: 'Torrance Transit',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'trax-tehama-county',
    displayName: 'Tehama Rural Area Express',
    vehiclesLicensed: '18',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
      showOperatorLogin: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'one-dollar-fare', displayName: '$1.00 Fare' },
        { type: 'two-fifty-dollar-fare', displayName: '$2.50 Fare' },
        { type: 'senior-veteran', displayName: 'Senior/Veteran' },
        { type: 'student-child', displayName: 'Student/Child' },
        { type: 'monthly-trax-pass', displayName: 'Monthly/TRAX Pass' },
        { type: 'comp-aid', displayName: 'Comp/Aid' },
        {
          type: 'one-dollar-mobility-device',
          displayName: '$1.00 Mobility Device',
        },
        {
          type: 'two-fifty-dollar-mobility-device',
          displayName: '$2.50 Mobility Device',
        },
      ],
    },
  },
  {
    key: 'trax-tehama-county-sandbox',
    displayName: 'Tehama Rural Area Express Sandbox',
    vehiclesLicensed: '18',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
      showOperatorLogin: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'one-dollar-fare', displayName: '$1.00 Fare' },
        { type: 'two-fifty-dollar-fare', displayName: '$2.50 Fare' },
        { type: 'senior-veteran', displayName: 'Senior/Veteran' },
        { type: 'student-child', displayName: 'Student/Child' },
        { type: 'monthly-trax-pass', displayName: 'Monthly/TRAX Pass' },
        { type: 'comp-aid', displayName: 'Comp/Aid' },
        {
          type: 'one-dollar-mobility-device',
          displayName: '$1.00 Mobility Device',
        },
        {
          type: 'two-fifty-dollar-mobility-device',
          displayName: '$2.50 Mobility Device',
        },
      ],
    },
  },
  {
    key: 'trivalleytransit',
    displayName: 'Tri-Valley Transit',
    defaultSettings: {
      showOperatorLogin: true,
      enableNativeMap: true,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'regular', displayName: 'Regular' },
        { type: 'child', displayName: 'Child' },
        { type: 'university-student', displayName: 'University Student' },
        { type: 'other-student', displayName: 'Other Student' },
        { type: 'senior', displayName: 'Senior' },
        { type: 'mobility-device', displayName: 'Mobility Device' },
        { type: 'bike', displayName: 'Bike' },
        {
          type: '1/2-price-special-events',
          displayName: '1/2 Price (Special events)',
        },
      ],
    },
  },
  {
    key: 'trivalleytransit-sandbox',
    displayName: 'Tri-Valley Transit (Sandbox)',
    defaultSettings: {
      showOperatorLogin: true,
      enableNativeMap: true,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'regular', displayName: 'Regular' },
        { type: 'child', displayName: 'Child' },
        { type: 'university-student', displayName: 'University Student' },
        { type: 'other-student', displayName: 'Other Student' },
        { type: 'senior', displayName: 'Senior' },
        { type: 'mobility-device', displayName: 'Mobility Device' },
        { type: 'bike', displayName: 'Bike' },
        {
          type: '1/2-price-special-events',
          displayName: '1/2 Price (Special events)',
        },
      ],
    },
  },
  {
    key: 'tuolumne-county-transit',
    displayName: 'Tuolumne County Transit',
    vehiclesLicensed: '17',
    defaultSettings: {
      enableNativeMap: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'regular', displayName: 'Regular' },
        { type: 'senior', displayName: 'Senior 60+' },
        { type: 'disabled', displayName: 'Disabled' },
        { type: 'college-students', displayName: 'College Students' },
        { type: 'student', displayName: 'Student (13-18)' },
        { type: 'children', displayName: 'Children (0-12)' },
        { type: 'bike', displayName: 'Bike' },
        { type: 'wheelchair', displayName: 'Wheelchair' },
      ],
    },
  },
  {
    key: 'twin-transit',
    displayName: 'Twin Transit',
    vehiclesLicensed: '16',
    defaultSettings: {
      enableNativeMap: true,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'adult', displayName: 'Adult' },
        { type: 'senior', displayName: 'Senior' },
        { type: 'youth', displayName: 'Youth' },
        {
          type: 'mobility-device-adult',
          displayName: 'Mobility Device (Adult)',
        },
        {
          type: 'mobility-device-youth',
          displayName: 'Mobility Device (Youth)',
        },
      ],
    },
  },
  {
    key: 'tyler-transit',
    displayName: 'Tyler Transit',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
    },
  },
  {
    key: 'umd-maryland',
    displayName: 'University of Maryland (UMD)',
    vehiclesLicensed: '48',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
      showTimepointsOnlyInOtpView: false,
    },
  },
  {
    key: 'unitrans',
    displayName: 'Unitrans',
    vehiclesLicensed: '25',
    defaultSettings: {
      showRouteStops: false,
    },
  },
  {
    key: 'university-of-kentucky',
    displayName: 'University of Kentucky',
    defaultSettings: {
      showOperatorLogin: true,
      enableNativeMap: true,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
    },
  },
  {
    key: 'visalia-transit',
    displayName: 'Visalia Transit',
    vehiclesLicensed: '28',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'wedriveu',
    displayName: 'WeDriveU LI',
    defaultSettings: {
      operatorAssignmentFormat: TRANSITIME_OPERATOR_FORMAT.ID_FULL_NAME,
      enableNativeMap: true,
      showTimepointsOnlyInOtpView: false,
      showTimepointsOnlyInMapView: false,
      passengerCountingEnabled: true,
      passengerCountingButtons: [
        { type: 'regular', displayName: 'Regular' },
        { type: 'bike', displayName: 'Bike' },
      ],
    },
  },
  {
    key: 'wmata',
    displayName: 'Washington DC WMATA',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
    },
  },
  {
    key: 'wta-whatcom-county',
    displayName: 'Whatcom Transportation Authority',
    defaultSettings: {
      enableNativeMap: true,
      enableServiceAdjustments: true,
      disableSafeDrivingMode: true,
    },
    privateRoadsDatasetId: 'clqfv8h4f2h7h1njy0aosenis',
  },
];

// If not explicitly defined above, the following defaults will be provided.
// The below properties are guaranteed to always exist in agency defaults.
// Please add future defaults to the list.
const DEFAULT_DEFAULTS = {
  disableHeading: false,
  enableServiceAdjustments: false,
  passengerCountingEnabled: false,
  passengerCountingButtons: null,
  passengerCountingRoutes: null,
  turnByTurnRoutes: [],
  enableZeroTouchLogin: false,
  showRouteStops: true,
  showTimepointsOnlyInOtpView: true,
  showTimepointsOnlyInMapView: true,
  disableSafeDrivingMode: false,
  hideScreenWhenVehicleIsMoving: false,
  useStadiaNavigation: true,
  useStadiaPolylines: true,
  zeroTouchOperatorAllowList: null,
};

const getSupportedAgencies = () => {
  const allowedAgencyType = process.env.ALLOWED_AGENCY_TYPE || 'test';
  const filteredAgencies = ALL_TABLET_AGENCIES.filter(({ isTestAgency }) => {
    const agencyType = isTestAgency != null && isTestAgency ? 'test' : 'prod';
    return agencyType === allowedAgencyType;
  }).map(({ defaultSettings, ...agencyConfig }) => ({
    defaultSettings: {
      ...DEFAULT_DEFAULTS,
      ...defaultSettings,
    },
    ...agencyConfig,
  }));
  return _.sortBy(filteredAgencies, 'displayName');
};

export default getSupportedAgencies;
