<template>
  <div id="agency-selection" class="animated fadeIn">
    <FullErrorLogo v-if="showFullError" :showFullError="showFullError" />
    <div
      v-else-if="$store.getters.isRegisteredDevice"
      class="main-content"
      :class="{ blur: showPartialError }"
    >
      <div class="bg-image-watermark"></div>
      <div class="darkening-overlay"></div>
      <div class="actions-wrap">
        <Logo />
        <div class="select-wrap agency">
          <div class="title" v-html="selectedAgencyTitleHtml"></div>
          <select v-model="agencyKey">
            <option value="" disabled selected>Select an agency...</option>
            <option
              v-for="item in $store.getters.supportedAgencies"
              v-text="item.displayName"
              :key="item.key"
              :value="item.key"
            ></option>
          </select>
        </div>
        <div
          class="set"
          :class="agencySelectedClass"
          v-text="'Set Agency'"
          @click="onSetAgency"
        ></div>
      </div>
    </div>
    <div
      v-else
      class="main-content-overlay"
      :class="{ blur: showPartialError }"
    >
      <div class="bg-image-watermark"></div>
      <div class="darkening-overlay"></div>
      <div class="logo-wrap">
        <Logo />
      </div>
      <div class="actions-description">
        <span>Activate Device:&nbsp;</span>
        Enter your Swiftly login to activate this device with your agency. After
        activation, operators will log in at the start of their shift.
      </div>
      <div class="actions-error" v-if="loginError" v-text="errorMessage"></div>
      <div class="actions-wrap">
        <div>
          <label for="input-email">Email</label>
          <input
            v-$model-email="email"
            type="email"
            id="input-email"
            name="input-email"
            placeholder="Tap here to enter your email"
          />
        </div>
        <div>
          <label for="input-password">Password</label>
          <input
            v-$model-password="password"
            type="password"
            id="input-password"
            name="input-password"
            placeholder="Tap here to enter your password"
            @keyup.enter="onSubmit"
          />
        </div>
        <div
          class="submit-button"
          :class="{ ready: formIsReady }"
          @click="onSubmit"
        >
          Submit
        </div>
      </div>
    </div>
    <PartialError
      v-if="showPartialError"
      :showPartialError="showPartialError"
    />
  </div>
</template>

<script>
import _ from 'lodash';

import * as Segment from '../analytics/segment';

import Logo from './Logo.vue';

let _offlineTimeout = undefined;

export default {
  name: 'AgencySelection',
  components: { Logo },
  data() {
    return {
      email: '',
      password: '',
      agencyKey: '',
      loginError: false,
      errorMessage: '',
      tick: 0,
      hasConnectionError: false,
      hasWaitedForConnectionError: false,
    };
  },
  computed: {
    showPartialError() {
      const { hasConnectionError, hasWaitedForConnectionError } = this;
      if (hasConnectionError && !hasWaitedForConnectionError) {
        return 'connection';
      }
      return '';
    },
    showFullError() {
      const { hasConnectionError, hasWaitedForConnectionError } = this;
      if (
        (hasConnectionError && hasWaitedForConnectionError) ||
        (!hasConnectionError &&
          !hasWaitedForConnectionError &&
          !this.$store.getters.isOnline)
      ) {
        return 'connection';
      }
      return '';
    },
    formIsReady() {
      return this.email.length && this.password.length;
    },
    selectedAgencyTitleHtml() {
      const currentAgencies = this.$store.getters.supportedAgencies;
      const currentAgency = _.find(currentAgencies, ['key', this.agencyKey]);
      if (currentAgency) {
        return `Agency: <span>${currentAgency.displayName}</span>`;
      }
      return 'Select Agency';
    },
    agencySelectedClass() {
      const classes = {};
      classes['is-ready'] = this.agencyKey;
      return classes;
    },
  },
  watch: {
    '$store.getters.registrationStatus'() {
      this.onRegisteredStatusOrAgencyKeysChange();
    },
    '$store.getters.agencyKeys'() {
      this.onRegisteredStatusOrAgencyKeysChange();
    },
    'tick'() {
      this.onTickOrCurrentAgencyKeyChange();
    },
    '$store.getters.currentAgencyKey'() {
      this.onTickOrCurrentAgencyKeyChange();
    },
    '$store.getters.isOnline'(isOnline) {
      const vc = this;
      if (isOnline) {
        clearTimeout(_offlineTimeout);
        _offlineTimeout = undefined;

        vc.hasConnectionError = false;
        vc.hasWaitedForConnectionError = false;

        return;
      }

      vc.hasConnectionError = true;
      _offlineTimeout = setTimeout(
        () => {
          vc.hasWaitedForConnectionError = true;
        },
        1000 * 60, // 60 seconds
      );
    },
  },
  beforeMount() {
    const vc = this;
    vc.agencyKey = vc.$store.getters.currentAgencyKey;
    vc.$emit('hide-loading');
  },
  mounted() {
    const vc = this;
    if (vc.$store.getters.hasSingleSupportedAgency) {
      const agencyKey = vc.$store.getters.agencyKeys[0];
      if (vc.$store.getters.isValidAgencyKey(agencyKey)) {
        vc.$store.commit('currentAgencyKey', agencyKey);
        Segment.identifyUser();
        Segment.group();
      }
    }
  },
  methods: {
    async onSubmit() {
      const vc = this;

      if (!vc.formIsReady) {
        return;
      }

      vc.$emit('show-loading');

      const registrationSuccess = await vc.$store.dispatch(
        'requestDeviceRegistration',
        {
          email: vc.email,
          password: vc.password,
        },
      );

      if (!registrationSuccess.success) {
        vc.loginError = true;
        // TODO(haysmike) Base this on status codes and handle in the action
        switch (registrationSuccess.reason) {
          case 'invalid account':
            vc.errorMessage = 'Incorrect username or password';
            break;
          case 'agencies permission denied':
            vc.errorMessage =
              "Oops! It looks like you don't have permission to activate Onboard App. If you need help, contact support@goswift.ly";
            break;
          default:
            vc.errorMessage =
              'Oops! It looks like an error occurred, please try again later';
        }
      }

      vc.$emit('hide-loading');
    },
    onSetAgency() {
      if (!this.agencyKey) {
        alert('Please select an agency');
        return;
      }
      this.$store.commit('currentAgencyKey', this.agencyKey);
      this.$router.push({
        name: 'operator-login',
        params: {
          agencyKey: this.agencyKey,
        },
      });
      Segment.identifyUser();
      Segment.group();
    },
    onRegisteredStatusOrAgencyKeysChange() {
      const vc = this;
      const supportedAgencies = vc.$store.getters.supportedAgencies;
      if (supportedAgencies.length !== 1) {
        return;
      }
      const firstAgencyKey = _.get(supportedAgencies, ['0', 'key']);
      if (!firstAgencyKey) {
        return;
      }
      if (firstAgencyKey === '*') {
        return true;
      }
      vc.$store.commit('currentAgencyKey', firstAgencyKey);
      Segment.identifyUser();
      Segment.group();
      vc.tick += 1;
    },
    onTickOrCurrentAgencyKeyChange() {
      const vc = this;
      const agencyKey = vc.$store.getters.currentAgencyKey;
      if (agencyKey) {
        if (vc.$store.getters.isRegisteredDevice) {
          vc.$router.push({
            name: 'operator-login',
            params: { agencyKey },
          });
        }
      }
    },
  },
};
</script>

<style lang="stylus">
@require "../styl/_colors.styl"
@require "../styl/_images.styl"

#agency-selection {

    .main-content-overlay {

        position absolute
        top 0
        left 0
        width 100%
        height 100%
        color $seashell

        display flex
        flex-direction column
        flex-wrap nowrap
        justify-content center

        .actions-description {
            width 80%
            max-width 40rem
            font-size 1.6rem
            color $silver-sand
            font-weight 300
            margin 0 auto 3.6rem
            > span {
                font-weight 500
            }
        }

        .actions-error {
            width 80%
            margin 0 auto
            max-width 40rem
            color $tapestry
            font-size 1.6rem
            margin-bottom 1.6rem
        }

        .actions-wrap {
            width 80%
            margin 0 auto
            max-width 40rem

            > div {
                label {
                    display block
                    font-size 1.8rem
                    font-weight 300
                    color $white-trnsp-090
                }
                input {
                    width calc( 100% - 2.4rem )
                    padding 0.8rem 1.2rem
                    height 3rem
                    line-height 3rem
                    margin-top 0.8rem
                    margin-bottom 1.6rem
                    font-size 1.6rem
                    font-weight 300
                    color $black-haze
                    outline none
                    border 1px solid $abbey2
                    border-radius 0.4rem
                    background-color transparent
                    transition border-color 0.2s ease-in-out
                    &::placeholder {
                        color $gray-chateau
                    }
                    &:focus {
                        border-color $color-primary-blue
                    }
                }
            }

            .submit-button {
                margin 2.4rem auto 0
                padding 1.4rem
                text-align center
                font-size 1.8rem
                border-radius 0.4rem
                background-color $abbey3
                cursor pointer
                transition background-color 0.2s ease-in-out
                &.ready {
                    background-color $color-primary-blue
                }
            }
        }
        &.blur {
            filter blur(2px)
        }
    }

    .main-content {
        .actions-wrap {
            font-size 1.6rem
            font-weight 500

            .select-wrap {
                position relative
                height 4.8rem
                line-height 4.8rem
                color $text-default
                background-color $button-color
                border-radius 0.4rem
                padding 1rem 0

                margin-bottom 2.4rem

                .title {
                    position absolute
                    top 0
                    left 0
                    width 100%
                    height 100%
                    padding 0 1rem
                    overflow hidden
                    white-space nowrap
                    text-overflow ellipsis

                    > span {
                        font-weight 500
                    }
                }

                > select {
                    position absolute
                    top 0
                    left 0
                    width 100%
                    height 100%
                    opacity 0
                }
            }

            .set {
                height 4.8rem
                line-height 4.8rem
                color $white
                background-color $color-primary-blue
                border-radius 0.4rem
                transition all 0.2s ease

                opacity 0
                &.is-ready {
                    opacity 1
                    cursor pointer
                }
            }
        }
        &.blur {
            filter blur(2px)
        }
    }
}
</style>
