<template>
  <div id="operator-login">
    <FullErrorLogo v-if="showFullError" :showFullError="showFullError" />
    <div
      v-else-if="$store.getters.showLoginOverlay"
      class="main-content animated fadeIn"
      :class="{ blur: showPartialError }"
    >
      <div class="bg-image-watermark"></div>
      <div class="darkening-overlay"></div>
      <div class="actions-wrap">
        <Logo />
        <p v-show="showTrainingModeNotice" class="operator-login__notice">
          Training mode is enabled. Swiftly will not record your sign-in or GPS
          data
        </p>
        <p
          v-show="showWaitingForAssignment"
          class="operator-login__notice operator-login__notice--centered"
        >
          Waiting for vehicle assignment...
        </p>
        <p v-show="showZeroTouchLoginNotice" class="operator-login__notice">
          Select a vehicle ID to complete setup.
        </p>
        <div
          v-if="showZeroTouchLoginNotice"
          class="action-button"
          :class="{
            'vehicle-is-moving': vehicleIsMoving,
          }"
          @click="onZeroTouchSelectVehicle"
        >
          Select Vehicle ID
        </div>
        <div
          v-if="!isZeroTouchLoginEnabled"
          class="action-button"
          :class="{
            'vehicle-is-moving': vehicleIsMoving,
          }"
          @click="onSignInTap"
        >
          Sign In
        </div>
      </div>
    </div>
    <div
      v-else
      id="selections-wrap"
      class="animated fadeIn"
      :class="{
        'blur': showPartialError,
        'not-showing-login-selections': !$store.getters.showLoginSelections,
        'showing-confirmation-info': showConfirmationInfo,
      }"
    >
      <div v-if="$store.getters.showLoginSelections" class="selections">
        <div
          v-if="showOperatorLogin"
          class="selection-wrap operator"
          :class="{ active: selectionFocus === 'operator' }"
        >
          <div class="icon-wrap">
            <inline-svg
              name="perm_identity"
              classes="icon-smaller"
            ></inline-svg>
          </div>
          <div v-if="!operator">
            <input
              id="input-operator"
              type="text"
              placeholder="Enter Operator ID (tap here for search)"
              v-$model-operator-filter-text="operatorFilterText"
            />
          </div>
          <div v-else class="label" @click="editOperator">
            <div class="value">{{ operatorIdLabel }}</div>
            <div class="edit-button" @click="handleClearOperator">
              <inline-svg name="close" classes="close"></inline-svg>
            </div>
          </div>
        </div>
        <div
          v-if="!showOperatorLogin || operator"
          class="selection-wrap vehicle"
          :class="{ active: selectionFocus === 'vehicle' }"
        >
          <div class="icon-wrap">
            <inline-svg
              name="directions_bus"
              classes="icon-smaller"
            ></inline-svg>
          </div>
          <div v-if="!vehicleId">
            <input
              id="input-vehicle"
              type="text"
              placeholder="Select Vehicle ID (tap here for search)"
              v-$model-vehicle-filter-text="vehicleFilterText"
            />
          </div>
          <div v-if="vehicleId" class="label" @click="editVehicle">
            <div class="value">Vehicle {{ vehicleId }}</div>
            <div
              v-if="!$store.getters.hasPermanentAssignment"
              class="edit-button"
              @click="handleClearVehicle"
            >
              <inline-svg name="close" classes="close"></inline-svg>
            </div>
          </div>
        </div>
        <div
          v-if="
            (!showOperatorLogin || operator) &&
            vehicleId &&
            !isPrePopulatingTripInfo
          "
          class="selection-wrap route"
          :class="{ active: selectionFocus === 'route' }"
        >
          <div class="icon-wrap">
            <inline-svg name="directions" classes="icon-smaller"></inline-svg>
          </div>
          <div v-if="showRouteFilterText">
            <input
              id="input-route"
              type="text"
              placeholder="Select Route (tap here for search)"
              v-$model-route-filter-text="routeFilterText"
            />
          </div>
          <div v-else class="label" @click="editRoute">
            <div class="value">Route {{ routeKey }}</div>
            <div class="edit-button" @click="handleClearRoute">
              <inline-svg name="close" classes="close"></inline-svg>
            </div>
          </div>
        </div>
        <div
          v-if="vehicleId && routeKey && !isPrePopulatingTripInfo"
          class="selection-wrap trip"
          :class="{ active: selectionFocus === 'trip' }"
        >
          <div class="icon-wrap">
            <inline-svg name="event_note" classes="icon-smaller"></inline-svg>
          </div>
          <div v-if="tripId" class="label">
            <div class="value">{{ selectedTripDisplayName }}</div>
            <div class="edit-button" @click="handleClearTrip">
              <inline-svg name="close" classes="close"></inline-svg>
            </div>
          </div>
          <div v-else class="label">Select Trip</div>
        </div>
      </div>
      <div v-if="showFilteredResults" id="filtered-results">
        <div
          v-for="result in filteredResults"
          :class="`result ${result.customClass}`"
          :key="result.key"
          :value="result.value"
          @click="selectResult(result)"
        >
          <div v-if="result.iconName != null" class="icon-wrap">
            <inline-svg
              :name="result.iconName"
              classes="icon-smaller"
            ></inline-svg>
          </div>
          <div class="result-value">{{ result.displayName }}</div>
        </div>
      </div>
      <div
        v-else-if="
          (!vehicleId && selectionFocus === 'vehicle') ||
          (!routeKey && selectionFocus === 'route') ||
          (!tripId && selectionFocus === 'trip')
        "
        id="filtered-results"
        :class="classFilteredResults"
      >
        <div v-if="showLoadingSpinner" class="loading-spinner">
          <LoadingAnimation />
        </div>
        <div
          v-else
          class="no-result-message"
          v-html="noFilteredResultsHtml"
        ></div>
      </div>
      <div
        v-if="showConfirmationInfo"
        id="confirmation-info"
        class="animated fadeIn"
      >
        <div class="confirmation-title">Confirm Assignment</div>
        <div
          class="confirmation-subtitle"
          v-if="showAssignedTripInfo"
          v-text="
            `Vehicle ${vehicleId} already has an assigned block. Tap the correct assignment.`
          "
        ></div>
        <div class="assignment-options">
          <div
            v-if="showAssignedTripInfo"
            class="option assigned"
            :class="classAssignedTrip"
            @click="selectedTripCategory = 'assigned'"
          >
            <div class="option-info selection-state">
              <div class="circle">
                <div class="filling"></div>
              </div>
            </div>
            <div class="option-info left">
              <div class="option-label">Current</div>
              <div>{{ tripTimeDisplay(assignedTripInfo) }}</div>
            </div>
            <div
              class="option-separator"
              :class="{
                'has-operator-id': showOperatorLabel,
                'has-multiple-options': !hasSingleTripOption,
              }"
            ></div>
            <div class="option-info right">
              <div class="label route">
                Route
                {{
                  $store.getters.routeFullName(assignedTripInfo.routeShortName)
                }}
              </div>
              <div class="label first-line">
                {{ tripFirstLine(assignedTripInfo) }}
              </div>
              <div class="label second-line">
                {{ tripSecondLine(assignedTripInfo) }}
              </div>
              <div v-if="showOperatorLabel" class="operator-id-label">
                {{ operatorIdLabel }}
              </div>
            </div>
          </div>
          <div
            v-if="showNewTripInfo"
            class="option desired"
            :class="classNewTrip"
            @click="selectedTripCategory = 'desired'"
          >
            <div class="option-info selection-state">
              <div class="circle">
                <div class="filling"></div>
              </div>
            </div>
            <div class="option-info left">
              <div class="option-label">{{ newTripOptionLabelText }}</div>
              <div>{{ tripTimeDisplay(newTripInfo) }}</div>
            </div>
            <div
              class="option-separator"
              :class="{
                'has-operator-id': showOperatorLabel,
                'has-multiple-options': !hasSingleTripOption,
              }"
            />
            <div class="option-info right">
              <div class="label route">
                Route
                {{ $store.getters.routeFullName(newTripInfo.routeShortName) }}
              </div>
              <div class="label first-line">
                {{ tripFirstLine(newTripInfo) }}
              </div>
              <div class="label second-line">
                {{ tripSecondLine(newTripInfo) }}
              </div>
              <div
                v-if="!showAssignedTripInfo || assignmentToBeTaken != null"
                class="label vehicle-id"
              >
                <div
                  v-if="assignmentToBeTaken != null"
                  class="vehicle-id--taken"
                >
                  Vehicle {{ assignmentToBeTaken.vehicleId }}
                </div>
                Vehicle {{ vehicleId }}
              </div>
              <div v-if="showOperatorLabel" class="operator-id-label">
                <div
                  v-if="
                    assignmentToBeTaken != null &&
                    assignmentToBeTaken.operatorId != null
                  "
                  class="operator-id--taken"
                >
                  Operator {{ assignmentToBeTaken.operatorId }}
                </div>
                {{ operatorIdLabel }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="showConfirmAssignmentButton"
          class="confirm-assignment-button"
          @click="onConfirmAssignment"
        >
          Confirm assignment
        </div>
        <div
          v-else-if="showTakeAssignmentButton"
          class="take-assignment-confirmation"
        >
          <p class="take-assignment-confirmation__message">
            This trip is already assigned to Vehicle
            {{ assignmentToBeTaken.vehicleId }}.
          </p>
          <p>
            Taking this trip assignment will un-assign Vehicle
            {{ assignmentToBeTaken.vehicleId }}.
          </p>
          <div class="take-assignment-confirmation__buttons">
            <button
              class="take-assignment-confirmation__back-button"
              @click="onTakeAssignmentGoBack"
            >
              Go Back
            </button>
            <button
              class="take-assignment-confirmation__take-assignment-button"
              @click="onConfirmAssignment"
            >
              Take Assignment
            </button>
          </div>
        </div>
      </div>
    </div>
    <PartialError
      v-if="showPartialError && !showFullError"
      :showPartialError="showPartialError"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import * as Segment from '../analytics/segment';
import * as ErrorReporter from '../error-reporter';
import { APP_OPERATOR_FORMAT, formatOperator } from '../utils/format-operator';
import sleep from '../utils/sleep';
import {
  convertTransitimeTimeToHumanFriendly,
  isTransitimeTimeInPast,
} from '../utils/transitime-time';

import Logo from './Logo.vue';
import LoadingAnimation from './common/LoadingAnimation.vue';

const CurrentSelection = {
  COMPLETE: 'complete',
  OPERATOR: 'operator',
  ROUTE: 'route',
  TRIP: 'trip',
  VEHICLE: 'vehicle',
};

let _agencyInfoInterval = undefined;
let _loginInfoInterval = undefined;
let _offlineTimeout = undefined;
let _serverErrorTimeout = undefined;
let _resetLoginTimeout = undefined;

export default {
  name: 'OperatorLogin',
  components: { LoadingAnimation, Logo },
  data() {
    return {
      selectionFocus: '',
      selectedTripCategory: '',

      vehicleFilterText: '',
      routeFilterText: '',
      operatorFilterText: '',

      operator: null,
      vehicleId: '',
      routeKey: '',
      tripId: '',
      blockId: '',

      vehicleInfo: null,
      assignedTripInfo: null,
      newTripInfo: null,
      assignmentToBeTaken: null,

      forceVehicleLoader: false,
      temporarilyPreventSelections: false,
      isPrePopulatingTripInfo: false,

      // Connection error
      hasConnectionError: false,
      hasWaitedForConnectionError: false,

      // Server error
      hasServerError: false,
      hasWaitedForServerError: false,
    };
  },
  computed: {
    ...mapGetters(['isZeroTouchLoginEnabled', 'isZeroTouchLoginPossible']),
    vehicleIsMoving() {
      return this.$store.getters.vehicleIsMoving;
    },
    hasConnectivity() {
      return (
        this.$store.getters.isOnline &&
        !this.$store.getters.serverErrorLoginInfo
      );
    },
    showLoadingSpinner() {
      switch (this.selectionFocus) {
        case CurrentSelection.VEHICLE:
          if (this.forceVehicleLoader) {
            return true;
          }
          if (!this.vehicleId) {
            const hasVehicleFilterText = Boolean(this.vehicleFilterText);
            const hasNoVehicleResults = _.isEmpty(this.filteredResults);
            return !(hasVehicleFilterText && hasNoVehicleResults);
          }
          break;
        case CurrentSelection.ROUTE:
          if (!this.routeKey) {
            const hasRouteFilterText = Boolean(this.routeFilterText);
            const hasNoRouteResults = _.isEmpty(this.filteredResults);
            return !(hasRouteFilterText && hasNoRouteResults);
          }
          break;
      }
      return false;
    },
    showPartialError() {
      const {
        hasConnectionError,
        hasWaitedForConnectionError,
        hasServerError,
        hasWaitedForServerError,
      } = this;
      if (hasConnectionError && !hasWaitedForConnectionError) {
        return 'connection';
      }
      if (hasServerError && !hasWaitedForServerError) {
        return 'server';
      }
      return '';
    },
    showFullError() {
      const {
        hasConnectionError,
        hasWaitedForConnectionError,
        hasServerError,
        hasWaitedForServerError,
      } = this;
      const hasConnectionErrorFromScreenWhileDriving =
        !hasConnectionError &&
        !hasWaitedForConnectionError &&
        !this.$store.getters.isOnline;
      const hasServerErrorFromScreenWhileDriving =
        !hasServerError &&
        !hasWaitedForServerError &&
        this.$store.getters.serverErrorVehicleInfo;
      if (
        hasConnectionErrorFromScreenWhileDriving &&
        hasServerErrorFromScreenWhileDriving
      ) {
        this.$store.commit('serverErrorVehicleInfo', false);
        return 'connection';
      }
      if (hasServerErrorFromScreenWhileDriving) {
        this.$store.commit('serverErrorVehicles', true);
        return 'server';
      }
      if (
        (hasConnectionError && hasWaitedForConnectionError) ||
        hasConnectionErrorFromScreenWhileDriving
      ) {
        return 'connection';
      }
      if (hasServerError && hasWaitedForServerError) {
        return 'server';
      }
      return '';
    },
    showTrainingModeNotice() {
      return (
        !this.isZeroTouchLoginEnabled &&
        !this.$store.getters.sendGeolocationUpdates
      );
    },
    showZeroTouchLoginNotice() {
      return this.isZeroTouchLoginEnabled && !this.isZeroTouchLoginPossible;
    },
    showOperatorLogin() {
      return this.$store.getters.showOperatorLogin;
    },
    showConfirmationInfo() {
      return (
        this.isReadyForNextScreen && !this.$store.getters.showLoginSelections
      );
    },
    showWaitingForAssignment() {
      return this.isZeroTouchLoginEnabled && this.isZeroTouchLoginPossible;
    },
    operatorIdLabel() {
      const operator = this.operator;
      if (operator == null) {
        return '';
      }
      return formatOperator(
        operator,
        APP_OPERATOR_FORMAT.DISPLAY_NAME_WITH_OPERATOR_TAG,
      );
    },
    showOperatorLabel() {
      // TO CONSIDER: Improving with regular expressions
      return (
        this.showOperatorLogin &&
        this.operatorIdLabel &&
        this.operatorIdLabel !== ' Anonymous operator'
      );
    },
    showConfirmAssignmentButton() {
      return (
        !this.$store.getters.showLoginSelections &&
        ((this.assignmentToBeTaken == null &&
          (this.hasSingleTripOption ||
            this.selectedTripCategory === 'desired')) ||
          this.selectedTripCategory === 'assigned')
      );
    },
    showTakeAssignmentButton() {
      return (
        !this.$store.getters.showLoginSelections &&
        this.assignmentToBeTaken != null &&
        (this.hasSingleTripOption || this.selectedTripCategory === 'desired')
      );
    },
    hasSingleTripOption() {
      let optionsShowing = 0;
      if (this.showAssignedTripInfo) {
        optionsShowing += 1;
      }
      if (this.showNewTripInfo) {
        optionsShowing += 1;
      }
      return optionsShowing === 1;
    },
    classFilteredResults() {
      const classes = {};
      classes[`selection-focus-${this.selectionFocus}`] = true;
      classes['operator-login-enabled'] = this.$store.getters.showOperatorLogin;
      return classes;
    },
    classAssignedTrip() {
      const classes = {};
      classes.selectable = !this.hasSingleTripOption;
      classes.selected = this.selectedTripCategory === 'assigned';
      classes[`trip-format-${this.$store.getters.tripDisplayFormat}`] = true;
      return classes;
    },
    classNewTrip() {
      const classes = {};
      classes.selectable = !this.hasSingleTripOption;
      if (this.hasSingleTripOption) {
        classes.selected = true;
      } else if (this.selectedTripCategory === 'desired') {
        classes.selected = true;
      }
      classes[`trip-format-${this.$store.getters.tripDisplayFormat}`] = true;
      return classes;
    },
    newTripOptionLabelText() {
      if (this.hasSingleTripOption) {
        return '';
      }
      return 'New';
    },
    currentAssignmentExists() {
      const assignedTripId = _.get(this.assignedTripInfo, 'id');
      return this.tripId === assignedTripId;
    },
    showAssignedTripInfo() {
      if (!_.isObjectLike(this.assignedTripInfo)) {
        return false;
      }
      const assignedTripId = _.get(this.assignedTripInfo, 'id');
      const newTripId = _.get(this.newTripInfo, 'id');
      if (assignedTripId === newTripId) {
        return false;
      }
      return true;
    },
    showNewTripInfo() {
      return _.isObjectLike(this.newTripInfo);
    },
    desiredAssignmentInPlace() {
      if (!_.isObjectLike(this.assignedTripInfo)) {
        return false;
      }
      const assignedTripId = _.get(this.assignedTripInfo, 'id');
      const desiredTripId = _.get(this.newTripInfo, 'id');
      return assignedTripId === desiredTripId;
    },
    showRouteFilterText() {
      if (this.routeKey) {
        return false;
      }
      return true;
    },
    showFilteredResults() {
      // Delay rendering large vehicle list immediately after sign in tap to allow for a snappier UI transition
      if (
        this.selectionFocus === CurrentSelection.VEHICLE &&
        this.forceVehicleLoader
      ) {
        return false;
      }
      if (!this.$store.getters.showLoginSelections) {
        return false;
      }
      return !_.isEmpty(this.filteredResults);
    },
    filteredResults() {
      const vc = this;

      if (vc.isPrePopulatingTripInfo) {
        return [];
      }
      if (vc.isReadyForNextScreen) {
        return [];
      }

      let results = [];
      switch (vc.selectionFocus) {
        case CurrentSelection.OPERATOR: {
          const operatorIds = vc.$store.getters.currentOperatorIds;
          operatorIds.forEach((operator, index) => {
            //TODO : improve with Regex
            const isDefaultOperator = operator.key == null;
            let shouldIncludeOperator = true;
            if (vc.operatorFilterText && !isDefaultOperator) {
              let hasMatchingContent = false;
              for (const [, value] of Object.entries(operator)) {
                if (hasMatchingContent) {
                  continue;
                }
                if (typeof value !== 'string') {
                  continue;
                }
                if (
                  value
                    .toLowerCase()
                    .indexOf(vc.operatorFilterText.toLowerCase()) !== -1
                ) {
                  hasMatchingContent = true;
                }
              }
              shouldIncludeOperator = hasMatchingContent;
            }
            if (shouldIncludeOperator) {
              results.push({
                key: operator.key,
                value: operator,
                displayName: isDefaultOperator
                  ? "I don't see my operator ID"
                  : formatOperator(operator, APP_OPERATOR_FORMAT.DISPLAY_NAME),
                iconName: 'perm_identity',
                customClass: isDefaultOperator ? 'default-option' : '',
              });
            }
          });
          break;
        }
        case CurrentSelection.VEHICLE: {
          const vehicleIds = vc.$store.getters.currentVehicleIds;
          vehicleIds.forEach((vehicleId) => {
            // To account for Transitime errors we have seen
            if (!vehicleId) {
              return;
            }
            if (vehicleId === 'null') {
              return;
            }

            const shouldFilterResult =
              vc.vehicleFilterText &&
              vehicleId
                .toLowerCase()
                .indexOf(vc.vehicleFilterText.toLowerCase()) === -1;
            if (shouldFilterResult) {
              return;
            }

            results.push({
              key: vehicleId,
              value: vehicleId,
              displayName: vehicleId,
              iconName: 'directions_bus',
              customClass: '',
            });
          });
          // Additionally sort by how early the match is in the vehicle ID
          results = _.sortBy(results, (result) =>
            result.key.indexOf(vc.vehicleFilterText),
          );
          // If developer mode is on, add an option to choose randomly assigned vehicle
          if (vc.$store.getters.developerMode) {
            const randomVehicleIdWithAssignment =
              vc.$store.getters.randomVehicleIdWithAssignment;
            if (randomVehicleIdWithAssignment) {
              results.unshift({
                // To avoid duplicate keys, prepended vehicle id with 'random-'
                key: `random-${randomVehicleIdWithAssignment}`,
                value: `random-${randomVehicleIdWithAssignment}`,
                displayName: 'Any vehicle with assignment',
                iconName: 'directions_bus',
                customClass: '',
              });
            }
          }
          break;
        }
        case CurrentSelection.ROUTE: {
          const routeKeys = vc.$store.getters.currentRouteKeys;
          routeKeys.forEach((routeKey) => {
            // To account for Transitime errors we have seen
            if (!routeKey) {
              return;
            }
            if (routeKey === 'null') {
              return;
            }

            const shouldFilterResult =
              vc.routeFilterText &&
              routeKey
                .toLowerCase()
                .indexOf(vc.routeFilterText.toLowerCase()) === -1;
            if (shouldFilterResult) {
              return;
            }

            results.push({
              key: routeKey,
              value: routeKey,
              displayName: routeKey,
              iconName: 'directions',
              customClass: '',
            });
          });
          // Additionally sort by how early the match is in the vehicle ID
          results = _.sortBy(results, (result) =>
            result.key.indexOf(vc.routeFilterText),
          );
          break;
        }
        case CurrentSelection.TRIP: {
          const trips = vc.$store.getters.tripsForRoute(vc.routeKey);
          if (trips.length === 0) {
            break;
          }
          const { unassignedTrips, assignedTrips } = trips.reduce(
            (acc, cur) => {
              if (
                cur.assignedVehicle == null ||
                cur.assignedVehicle.id === this.vehicleId
              ) {
                acc.unassignedTrips.push({
                  key: cur.tripId,
                  value: cur.tripId,
                  displayName: cur.displayName,
                  iconName: 'event_note',
                  customClass: 'result--unassigned-trip',
                });
              } else {
                acc.assignedTrips.push({
                  key: cur.tripId,
                  value: cur.tripId,
                  displayName: cur.displayName,
                  iconName: 'event_note',
                  customClass: '',
                });
              }
              return acc;
            },
            { unassignedTrips: [], assignedTrips: [] },
          );
          if (unassignedTrips.length !== 0) {
            unassignedTrips.at(-1).customClass +=
              ' result--last-unassigned-trip';
          }
          results.push(...unassignedTrips);
          if (assignedTrips.length !== 0) {
            results.push({
              key: 'assigned-trips-label',
              displayName: 'Trips already assigned to another vehicle',
              customClass: 'assigned-trips-label',
            });
            results.push(...assignedTrips);
          }
          break;
        }
      }

      return results;
    },
    isReadyForNextScreen() {
      return this.vehicleId && this.routeKey && this.blockId && this.tripId;
    },
    selectedTripDisplayName() {
      const trips = this.currentTripsById;
      if (!this.tripId) {
        return '...';
      }
      const displayName = _.get(trips, [this.tripId, 'displayName']);
      if (displayName) {
        return displayName;
      }
      return `Trip: ${this.tripId}`;
    },
    currentTripsById() {
      return _.keyBy(
        this.$store.getters.tripsForRoute(this.routeKey),
        'tripId',
      );
    },
    noFilteredResultsHtml() {
      switch (this.selectionFocus) {
        case CurrentSelection.TRIP:
          return '<span>No Trips:</span> Try logging in within one hour of your next trip.';
        case CurrentSelection.VEHICLE:
          return '<span>No available vehicles found</span>';
        case CurrentSelection.ROUTE:
          return '<span>No available routes found</span>';
      }
      return '';
    },
    isZeroTouchLoginAllowed() {
      const zeroTouchOperatorAllowList =
        this.$store.getters.currentAgencyConfig?.defaultSettings
          .zeroTouchOperatorAllowList;
      const driver = this.$store.getters.currentVehicleInfo?.driver;
      return (
        this.isZeroTouchLoginEnabled &&
        this.$store.getters.vehicleIsAssigned &&
        (zeroTouchOperatorAllowList == null ||
          zeroTouchOperatorAllowList.includes(driver))
      );
    },
  },
  watch: {
    'isZeroTouchLoginAllowed': {
      immediate: true,
      handler(isZeroTouchLoginAllowed) {
        if (isZeroTouchLoginAllowed) {
          this.$router.push({
            name: 'vehicle',
            params: {
              vehicleId: this.$store.getters.permanentlyAssignedVehicleId,
            },
          });
        }
      },
    },
    'desiredAssignmentInPlace'() {
      const vc = this;
      if (!vc.desiredAssignmentInPlace) {
        return;
      }
      vc.selectedTripCategory = 'desired';
    },
    'selectionFocus'(selection) {
      if (selection === CurrentSelection.TRIP) {
        const tripIds = this.$store.getters.tripsForRoute(this.routeKey);
        Segment.track('operator-login-trip-results', {
          activeTrips: this.$store.getters
            .activeBlocksForRoute(this.routeKey)
            .map(({ trip }) => trip.id),
          selectableTrips: tripIds.map(({ tripId }) => tripId),
        });
      }
      this.resetResultsScroll();
    },
    'showPartialError'(showPartialError) {
      const vc = this;
      vc.$emit('show-partial-error-change', Boolean(showPartialError));
    },
    'showFullError'(showFullError) {
      const vc = this;
      vc.$emit('show-full-error-change', Boolean(showFullError));
    },
    '$store.getters.showLoginSelections'(shouldShowLoginSelections) {
      if (shouldShowLoginSelections) {
        this.clearTrip();
      }
    },
    async '$store.getters.isOnline'(isOnline) {
      const vc = this;
      if (isOnline) {
        vc.$store.commit('showLoginSelections', true);
        clearTimeout(_offlineTimeout);
        _offlineTimeout = undefined;
        vc.clearLoginServerErrors();
        vc.hasConnectionError = false;
        vc.hasWaitedForConnectionError = false;
        vc.startAgencyInfoInterval();
        return;
      }
      vc.resetLogin();
      vc.hasConnectionError = true;
      _offlineTimeout = setTimeout(
        () => {
          vc.hasWaitedForConnectionError = true;
        },
        1000 * 60, // 60 seconds
      );
    },
    '$store.getters.serverErrorLoginInfo'(hasError) {
      const vc = this;
      if (!vc.$store.getters.isOnline) {
        return;
      }
      const hasErrorFromScreenWhileDriving =
        vc.$store.getters.serverErrorVehicleInfo;
      if (hasErrorFromScreenWhileDriving) {
        vc.$store.commit('serverErrorVehicleInfo', false);
        vc.hasServerError = true;
        vc.hasWaitedForServerError = true;
        vc.startLoginInfoInterval();
        return;
      }
      if (!hasError) {
        vc.$store.commit('showLoginSelections', true);
        vc.stopLoginInfoInterval();
        clearTimeout(_serverErrorTimeout);
        _serverErrorTimeout = undefined;
        vc.hasServerError = false;
        vc.hasWaitedForServerError = false;
        return;
      }
      vc.startLoginInfoInterval();
      vc.resetLogin();
      vc.hasServerError = true;
      _serverErrorTimeout = setTimeout(
        () => {
          vc.hasWaitedForServerError = true;
        },
        1000 * 60, // 60 seconds
      );
    },
    'vehicleIsMoving'(vehicleIsMoving) {
      const vc = this;
      if (vehicleIsMoving) {
        vc.resetLogin();
      }
    },
    'vehicleId': 'onVehicleIdChange',
    'routeKey': 'onRouteKeyChange',
    'tripId': 'onTripIdChange',
  },
  beforeMount() {
    if (!this.showWaitingForAssignment) {
      this.startAgencyInfoInterval();
      this.$store.commit('currentVehicleInfo', null);
      this.$store.commit('currentVehicleInfoTimestamp', null);
      this.updateSelectionFocus();
      this.$emit('hide-loading');
    }
    this.$emit('show-partial-error-change', Boolean(this.showPartialError));
    this.$emit('show-full-error-change', Boolean(this.showFullError));
  },
  beforeDestroy() {
    this.$store.commit('showLoginOverlay', true);
    this.$store.commit('showLoginSelections', true);
    this.stopLoginInfoInterval();
    this.stopAgencyInfoInterval();
    this.$emit('show-partial-error-change', false);
    this.$emit('show-full-error-change', false);
    clearTimeout(_resetLoginTimeout);
  },
  methods: {
    refreshLoginData() {
      const vc = this;
      if (!vc.$store.getters.isOnline) {
        return;
      }
      vc.$store.dispatch('getVehicles');
      vc.$store.dispatch('getBlocks');
    },
    startLoginInfoInterval() {
      const vc = this;
      vc.refreshLoginData();
      clearInterval(_loginInfoInterval);
      _loginInfoInterval = setInterval(
        vc.refreshLoginData,
        1000 * 10, // 10 Seconds
      );
    },
    stopLoginInfoInterval() {
      clearInterval(_loginInfoInterval);
    },
    startAgencyInfoInterval() {
      const vc = this;
      vc.$emit('refresh-agency-data');
      clearInterval(_agencyInfoInterval);
      _agencyInfoInterval = setInterval(
        () => {
          vc.$emit('refresh-agency-data');
        },
        1000 * 60 * 60 * 12, // 12 hours
      );
    },
    stopAgencyInfoInterval() {
      clearInterval(_agencyInfoInterval);
    },
    async onSignInTap() {
      if (this.vehicleIsMoving) {
        return;
      }
      this.clearOperator();
      if (
        !this.showOperatorLogin &&
        this.$store.getters.hasPermanentAssignment
      ) {
        this.vehicleId = this.$store.getters.permanentlyAssignedVehicleId;
      }
      this.enforceMinimumVehicleLoading();
      this.updateSelectionFocus();
      this.$store.commit('showLoginOverlay', false);
      this.$store.commit('showLoginSelections', true);
      this.refreshLoginData();
      this.startResetLoginTimeout();
      Segment.track('operator-login-sign-in-tap');
    },
    async onZeroTouchSelectVehicle() {
      if (this.vehicleIsMoving) {
        return;
      }
      this.$router.push({ name: 'permanent-vehicle-assignment' });
    },
    tripFirstLine(tripInfo) {
      const vc = this;
      const startTime = _.get(tripInfo, 'startTime');
      let hasDeparted = false;
      let startTimeText = null;
      try {
        hasDeparted = isTransitimeTimeInPast(startTime);
        startTimeText = convertTransitimeTimeToHumanFriendly(startTime);
      } catch (error) {
        ErrorReporter.capture({
          level: 'error',
          messageOrException: 'Invalid start time detected for trip',
          extraContext: { error, tripInfo },
        });
      }
      const departureTimeText =
        startTimeText != null
          ? `at ${convertTransitimeTimeToHumanFriendly(startTime)}`
          : '';
      const startStop = _.get(tripInfo, [
        'tripPattern',
        'stopPaths',
        '0',
        'stopName',
      ]);
      const departureLocationText = startStop ? `from ${startStop}` : '';
      const tripDisplayFormat = vc.$store.getters.tripDisplayFormat;
      switch (tripDisplayFormat) {
        case 'miami-style': {
          const departVerb = hasDeparted ? 'departed' : 'departs';
          return `Trip ${departVerb} ${departureTimeText} ${departureLocationText}`;
        }
        case 'default':
        default: {
          const firstStopName = _.get(tripInfo, ['schedule', 0, 'stopName']);
          if (!firstStopName) {
            return '...';
          }
          return `Starts at ${firstStopName}`;
        }
      }
    },
    tripSecondLine(tripInfo) {
      const vc = this;
      const tripDisplayFormat = vc.$store.getters.tripDisplayFormat;
      switch (tripDisplayFormat) {
        case 'miami-style':
          return `Destination: ${tripInfo.headsign}`;
        case 'default':
        default:
          return `Trip ${tripInfo.id}`;
      }
    },
    tripTimeDisplay(tripInfo) {
      const startTime = _.get(tripInfo, 'startTime');
      const endTime = _.get(tripInfo, 'endTime');
      try {
        const startTimeString = convertTransitimeTimeToHumanFriendly(startTime);
        const endTimeString = convertTransitimeTimeToHumanFriendly(endTime);
        return `${startTimeString} - ${endTimeString}`;
      } catch (error) {
        ErrorReporter.capture({
          level: 'error',
          messageOrException: 'Unable to build trip time display',
          extraContext: { error, tripInfo },
        });
        return '...';
      }
    },
    async selectResult(result) {
      const newValue = result?.value;
      // To prevent accidental double tapping per OA-367
      if (this.temporarilyPreventSelections || newValue == null) {
        return;
      }
      const randomPrefix = 'random-';
      // Track before `selectionFocus` is updated.
      Segment.track(`operator-login-${this.selectionFocus}-result-tap`);
      switch (this.selectionFocus) {
        case CurrentSelection.OPERATOR:
          this.operator = newValue;
          if (this.$store.getters.hasPermanentAssignment) {
            this.vehicleId = this.$store.getters.permanentlyAssignedVehicleId;
          }
          this.enforceMinimumVehicleLoading();
          this.updateSelectionFocus();
          break;
        case CurrentSelection.VEHICLE:
          this.isPrePopulatingTripInfo = true;
          // To avoid duplicate keys, prepended developer mode's random option
          // with 'random-'. Now, when assigning, clean up
          this.vehicleId = newValue.startsWith(randomPrefix)
            ? newValue.slice(randomPrefix.length)
            : newValue;
          break;
        case CurrentSelection.ROUTE:
          this.routeKey = newValue;
          this.updateSelectionFocus();
          break;
        case CurrentSelection.TRIP:
          this.tripId = newValue;
          this.updateSelectionFocus();
          break;
      }
    },
    updateSelectionFocus() {
      const {
        $store,
        tripId,
        routeKey,
        operator,
        showOperatorLogin,
        vehicleId,
      } = this;
      // TODO(will) revisit and clean up
      if ($store.getters.hasPermanentAssignment) {
        if (tripId) {
          this.selectionFocus = CurrentSelection.COMPLETE;
        } else if (routeKey) {
          this.selectionFocus = CurrentSelection.TRIP;
        } else if (operator || !showOperatorLogin) {
          this.selectionFocus = CurrentSelection.ROUTE;
        } else {
          this.selectionFocus = CurrentSelection.OPERATOR;
        }
        return;
      }
      if (tripId) {
        this.selectionFocus = CurrentSelection.COMPLETE;
      } else if (routeKey) {
        this.selectionFocus = CurrentSelection.TRIP;
      } else if (vehicleId) {
        this.selectionFocus = CurrentSelection.ROUTE;
      } else if (operator || !showOperatorLogin) {
        this.selectionFocus = CurrentSelection.VEHICLE;
      } else {
        this.selectionFocus = CurrentSelection.OPERATOR;
      }
    },
    enforceMinimumVehicleLoading() {
      const vc = this;
      vc.forceVehicleLoader = true;
      vc.temporarilyPreventSelections = true;
      setTimeout(() => {
        vc.forceVehicleLoader = false;
      }, 250);
      setTimeout(() => {
        vc.temporarilyPreventSelections = false;
      }, 1000);
    },
    handleClearOperator() {
      this.clearOperator();
      Segment.track('operator-login-clear-operator-tap');
    },
    handleClearVehicle() {
      this.clearVehicle();
      Segment.track('operator-login-clear-vehicle-tap');
    },
    handleClearRoute() {
      this.clearRoute();
      Segment.track('operator-login-clear-route-tap');
    },
    handleClearTrip() {
      this.clearTrip();
      Segment.track('operator-login-clear-trip-tap');
    },
    editOperator() {
      this.clearOperator();
      this.bringFocusToInputWithValue('input-operator');
      Segment.track('operator-login-edit-operator-tap');
    },
    editVehicle() {
      if (this.$store.getters.hasPermanentAssignment) {
        return;
      }
      const vehicleFilterText = this.vehicleId;
      this.clearVehicle();
      this.vehicleFilterText = vehicleFilterText;
      this.bringFocusToInputWithValue('input-vehicle', this.vehicleFilterText);
      Segment.track('operator-login-edit-vehicle-tap');
    },
    editRoute() {
      const routeFilterText = this.routeKey;
      this.clearRoute();
      this.routeFilterText = routeFilterText;
      this.bringFocusToInputWithValue('input-route', this.routeFilterText);
      Segment.track('operator-login-edit-route-tap');
    },
    clearOperator() {
      this.operator = null;
      this.operatorFilterText = '';
      this.clearVehicle();
    },
    clearVehicle() {
      this.vehicleId = '';
      this.assignedTripInfo = null;
      this.enforceMinimumVehicleLoading();
      this.clearRoute();
    },
    clearRoute() {
      this.routeKey = '';
      this.routeFilterText = '';
      this.vehicleFilterText = '';
      this.clearTrip();
    },
    clearTrip() {
      this.tripId = '';
      this.selectedTripCategory = '';
      this.updateSelectionFocus();
    },
    bringFocusToInputWithValue(elementId, value = '') {
      const vc = this;
      setTimeout(() => {
        let $element = document.getElementById(
          elementId ?? `input-${vc.selectionFocus}`,
        );
        if (_.isFunction(_.get($element, 'focus'))) {
          $element.focus();
          $element.value = value;
        } else {
          setTimeout(() => {
            $element = document.getElementById(elementId);
            if (_.isFunction(_.get($element, 'focus'))) {
              $element.focus();
              $element.value = value;
            }
          }, 100);
        }
      }, 0);
    },
    resetResultsScroll() {
      let $element = document.getElementById('filtered-results');
      if (_.isInteger(_.get($element, 'scrollTop'))) {
        $element.scrollTop = 0;
        return;
      }

      setTimeout(() => {
        $element = document.getElementById('filtered-results');
        if (_.isInteger(_.get($element, 'scrollTop'))) {
          $element.scrollTop = 0;
        }
      }, 100);
    },
    async onVehicleIdChange(vehicleId) {
      // To ensure getters work properly, if component-level vehicle id
      // is cleared, also clear app-level vehicle, route, and trip selections
      if (!vehicleId) {
        this.$store.commit('currentVehicleId', '');
        this.$store.commit('currentRouteKey', '');
        this.$store.commit('currentBlockId', '');
        this.isPrePopulatingTripInfo = false;
        this.updateSelectionFocus();
        return;
      }

      this.$emit('show-loading');

      this.vehicleInfo = await this.$store.dispatch('getVehicleInfo', {
        vehicleId,
      });

      const blockAssignmentCalls = this.$store.getters.blockAssignmentCalls;

      const tripId = this.vehicleInfo?.tripId;
      if (!tripId) {
        if (blockAssignmentCalls) {
          this.showBlockingAssignmentWarningAndClearVehicleSelection(vehicleId);
        }
        this.isPrePopulatingTripInfo = false;
        this.updateSelectionFocus();
        this.$emit('hide-loading');
        return;
      }

      this.newTripInfo = await this.$store.dispatch('getTripInfo', tripId);

      const routeKey = this.newTripInfo?.routeShortName;
      if (!routeKey) {
        if (blockAssignmentCalls) {
          this.showBlockingAssignmentWarningAndClearVehicleSelection(vehicleId);
        }
        this.isPrePopulatingTripInfo = false;
        this.updateSelectionFocus();
        this.$emit('hide-loading');
        return;
      }

      this.$store.commit('showLoginSelections', false);
      await sleep(100); // Avoids animation thrashing per OA-175
      this.isPrePopulatingTripInfo = false;

      this.routeKey = routeKey;
      this.tripId = tripId;
      this.assignedTripInfo = this.newTripInfo;

      this.updateSelectionFocus();
      this.$emit('hide-loading');
    },
    onRouteKeyChange(routeKey) {
      this.$store.commit('currentRouteKey', routeKey);
    },
    async onTripIdChange(tripId) {
      if (!tripId) {
        this.blockId = '';
        this.$store.commit('currentBlockId', '');
        this.$store.commit('showLoginSelections', true);
        this.newTripInfo = null;
        this.assignmentToBeTaken = null;
        return;
      }

      // First see if we can get a block id quickly from the local Vuex store
      if (this.routeKey) {
        const blockId = this.$store.getters.blockIdForRouteAndTrip(
          this.routeKey,
          this.tripId,
        );
        if (blockId) {
          this.blockId = blockId;
          this.$store.commit('currentBlockId', blockId);
        }
      }

      // Prevents multiple calls to getTripInfo.
      if (this.newTripInfo == null) {
        await this.$store.dispatch('getBlocks');
        const vehicleToTakeAssignmentFrom =
          this.$store.getters.assignedVehicleForRouteAndTrip(
            this.routeKey,
            tripId,
          )?.id;
        if (
          vehicleToTakeAssignmentFrom != null &&
          vehicleToTakeAssignmentFrom !== this.vehicleId
        ) {
          const operatorId = (
            await this.$store.dispatch('getVehicleInfo', {
              vehicleId: vehicleToTakeAssignmentFrom,
              shouldCommit: false,
            })
          )?.driver;
          this.assignmentToBeTaken = {
            vehicleId: vehicleToTakeAssignmentFrom,
            operatorId,
          };
        }
        this.newTripInfo = await this.$store.dispatch('getTripInfo', tripId);
      }
      this.$store.commit('showLoginSelections', false);

      // See if block id is available in the trip info
      if (
        this.newTripInfo != null &&
        this.newTripInfo.blockId &&
        this.newTripInfo.blockId !== this.blockId
      ) {
        this.blockId = this.newTripInfo.blockId;
        this.$store.commit('currentBlockId', this.newTripInfo.blockId);
      }

      // If we _still_ do not have a block id at this point, alert the user, send a Segment tracking event, and have the user re-login
      if (!this.blockId) {
        await sleep(1000); // Waiting 1 sec which allows hasConnectivity to be up to date
        if (this.hasConnectivity) {
          window.alert(
            'Oops! We encountered an unexpected error and have notified our engineers. Please try again, and if the error persists, notify your supervisor.',
          );
          const errorMessage = `Unable to find associated block for route "${this.routeKey}", trip "${tripId}" in most recent API info`;
          Segment.track('operator-login-unexpected-error', {
            error: errorMessage,
          });
          ErrorReporter.captureException(
            new Error(`Operator login unexpected error: ${errorMessage}`),
          );
        }
        this.clearVehicle();
      }
    },
    async showBlockingAssignmentWarningAndClearVehicleSelection(vehicleId) {
      const vc = this;
      await sleep(1000); // Waiting 1 sec which allows hasConnectivity to be up to date
      if (vc.hasConnectivity) {
        const vehicleDisplayName = vehicleId
          ? `Vehicle ${vehicleId}`
          : 'That vehicle';
        vc.$alert(
          `${vehicleDisplayName} is not yet assigned to a block. ` +
            'To sign in to unassigned vehicles, enable “Send GPS Updates” in Settings.',
        );
      }
      if (!vc.$store.getters.hasPermanentAssignment) {
        vc.clearVehicle();
      }
    },
    async onConfirmAssignment() {
      const operatorAssignmentFormat =
        this.$store.getters.operatorAssignmentFormat;

      const agencyKey = this.$store.getters.currentAgencyKey;
      const vehicleId = this.vehicleId;
      const routeKey = this.routeKey;
      const tripId = this.tripId;
      const blockId = this.blockId;
      const operator = this.operator;
      const operatorId = operator
        ? formatOperator(operator, operatorAssignmentFormat)
        : '';

      this.$store.commit('currentOperator', operator);
      Segment.identifyUser();

      if (!agencyKey || !vehicleId || !routeKey || !tripId || !blockId) {
        const errorMessage =
          'Missing required information (agency, vehicle, route, trip, and block) to proceed.';
        Segment.track('operator-login-confirm-assignment-tap-error', {
          error: errorMessage,
          agency: agencyKey,
          vehicle: vehicleId,
          route: routeKey,
          trip: tripId,
          block: blockId,
        });
        ErrorReporter.captureException(
          new Error(
            `Operator login confirm assignment tap error: ${errorMessage}`,
          ),
        );
        window.alert(
          'Oops! We encountered an unexpected error and have notified our engineers. Please try again, and if the error persists, notify your supervisor.',
        );
        this.clearVehicle();
        return;
      }

      const dispatchParams = { vehicleId, blockId };
      if (operatorId) {
        dispatchParams.operatorId = operatorId;
      }

      if (this.desiredAssignmentInPlace) {
        Segment.track('operator-login-confirm-assignment-tap', {
          'assignment-change-needed': false,
          'agency': agencyKey,
          'vehicle': vehicleId,
          'route': routeKey,
          'block': blockId,
          'trip': tripId,
          'operator': operatorId,
        });

        this.$store.dispatch('sendVehicleAssignment', dispatchParams);
        this.$router.push({
          name: 'vehicle',
          params: { vehicleId },
        });
        return;
      }

      switch (this.selectedTripCategory) {
        case 'assigned':
          Segment.track('operator-login-confirm-assignment-tap', {
            'assignment-change-needed': false,
            'agency': agencyKey,
            'vehicle': vehicleId,
            'route': routeKey,
            'block': blockId,
            'trip': tripId,
            'operator': operatorId,
          });
          this.$store.dispatch('sendVehicleAssignment', dispatchParams);
          this.$router.push({
            name: 'vehicle',
            params: { vehicleId },
          });
          return;
        case 'desired':
        default: {
          const blockAssignmentCalls = this.$store.getters.blockAssignmentCalls;
          if (blockAssignmentCalls) {
            this.showBlockingAssignmentWarningAndClearVehicleSelection(
              vehicleId,
            );
            return;
          }

          Segment.track('operator-login-confirm-assignment-tap', {
            'assignment-change-needed': true,
            'agency': agencyKey,
            'vehicle': vehicleId,
            'route': routeKey,
            'block': blockId,
            'trip': tripId,
            'operator': operatorId,
          });
          this.$emit('show-loading');

          // Step 1. Send GPS information _without_ assignment (and maybe even wait a second or two)
          const successfullySentVehicleLocationWithoutAssignmentInfo =
            await this.$store.dispatch('sendLocationUpdate', { vehicleId });

          // Step 2.
          // If we successfully sent vehicle location (without assignment info),
          // wait a few seconds to let Transitime "digest"
          if (successfullySentVehicleLocationWithoutAssignmentInfo) {
            await sleep(3000);
          }

          // Step 3.
          // Send explicit assignment request (will be sticky in the future)
          let successfullySentVehicleAssignment = await this.$store.dispatch(
            'sendVehicleAssignment',
            dispatchParams,
          );

          // If that didn't work...
          if (!successfullySentVehicleAssignment) {
            // Wait a few seconds and retry once
            await sleep(3000);
            successfullySentVehicleAssignment = await this.$store.dispatch(
              'sendVehicleAssignment',
              dispatchParams,
            );

            // If the second attempt sill did not work, let the user know
            if (!successfullySentVehicleAssignment) {
              this.$emit('hide-loading');
              let alertText = 'Oops! There was an unexpected problem.';
              if (
                this.$store.getters.vehicleAssignmentError.includes('spatial')
              ) {
                alertText =
                  'Oops! I can’t log you in to this trip because you are too far away from the route’s starting point. ' +
                  'Please double-check that you selected the right trip. ' +
                  'If you believe you got this message in error, please notify your supervisor.';
              }
              Segment.track('operator-login-assignment-request-failed', {
                'assignment-failure-reason':
                  this.$store.getters.vehicleAssignmentError,
                'agency': agencyKey,
                'vehicle': vehicleId,
                'route': routeKey,
                'block': blockId,
                'trip': tripId,
                'operator': operatorId,
              });
              this.$alert(alertText);
              return;
            }
          }

          // Step 4. Poll vehicle information until it is clear assignment has taken
          this.awaitAssignmentConfirmationThenAdvance();
        }
      }
    },
    onTakeAssignmentGoBack() {
      this.$store.commit('showLoginSelections', true);
    },
    async awaitAssignmentConfirmationThenAdvance(routeParams) {
      // TODO(haysmike) Use a retry lib
      const NUM_RETRIES = 3; // NUM_RETRIES + 1 attempts. Note the `>=`.
      const vehicleId = this.vehicleId;

      for (let numRetries = NUM_RETRIES; numRetries >= 0; numRetries--) {
        const vehicleInfo = await this.$store.dispatch('getVehicleInfo', {
          vehicleId,
        });
        this.vehicleInfo = _.isObjectLike(vehicleInfo) ? vehicleInfo : null;
        const vehicleInfoBlockId = this.vehicleInfo?.blockId;
        if (vehicleInfoBlockId === this.blockId) {
          this.$emit('hide-loading');
          this.$router.push({
            name: 'vehicle',
            params: { vehicleId },
          });
          return;
        }
        if (numRetries > 0) {
          await sleep(3000);
        }
      }

      this.$emit('hide-loading');
      this.$alert('Oops! There was an unexpected problem.');
    },
    resetLogin() {
      this.clearOperator();
      this.$store.commit('currentVehicleInfo', null);
      this.$store.commit('currentVehicleInfoTimestamp', null);
      this.$store.commit('showLoginOverlay', true);
      this.$store.commit('showLoginSelections', true);
      clearTimeout(_resetLoginTimeout);
      _resetLoginTimeout = undefined;
    },
    startResetLoginTimeout() {
      const vc = this;
      clearTimeout(_resetLoginTimeout);
      _resetLoginTimeout = setTimeout(
        vc.resetLogin,
        1000 * 60 * 10, // 10 minutes
      );
    },
    clearLoginServerErrors() {
      const vc = this;
      vc.$store.commit('serverErrorVehicles', false);
      vc.$store.commit('serverErrorBlocks', false);
    },
  },
};
</script>

<style lang="stylus" scoped>
@require "../styl/_variables.styl"
@require "../styl/_colors.styl"
@require "../styl/_images.styl"
@require "../styl/_svg-icon-adjustments.styl"

#operator-login {
    .operator-login__notice {
      margin-bottom 20px
      font-size 1.6rem
      font-weight 400
      color $white
      text-align left

      &--centered {
      text-align center
    }

    }

    #selections-wrap {
        position absolute
        left 0
        bottom 0
        width 100%
        height 100%
        background-color $bunker
        overflow hidden

        display flex
        flex-direction column

        .selections {
            width 100%
            background-color $outer-space2

            .selection-wrap {
                position relative
                width 86%
                max-width $main-content-max-width
                height 4.8rem
                margin 0 auto 1.6rem
                border-radius 0.4rem
                background-color $white-trnsp-016

                &:first-child {
                    margin-top 3rem
                }
                &:last-child {
                    margin-bottom 3rem
                }

                .icon-wrap {
                    padding 0 1.2rem

                    > i {
                        height 4.8rem
                        line-height 4.8rem
                        color $white
                        font-size 2rem
                    }
                }
                #input-operator,
                #input-vehicle,
                #input-route {
                    position absolute
                    top 0
                    left 0
                    width calc(100% - 1rem)
                    height 4.8rem
                    line-height 4.8rem
                    padding 0
                    background-color transparent
                    border none
                    outline none
                    color $white
                    text-indent 4rem
                    font-size 2rem

                    &::placeholder {
                        color $seashell
                        opacity 1
                    }
                    &[placeholder] {
                        text-overflow ellipsis
                    }
                }

                > .label {
                    position absolute
                    top 0
                    left 0
                    width 100%
                    font-size 2rem
                    color $white
                    text-indent 4rem
                    height 4.8rem
                    line-height 4.8rem

                    > .value {
                        width calc( 100% - 4.5rem )
                        overflow hidden
                        white-space nowrap
                        text-overflow ellipsis
                    }

                    .edit-button {
                        position absolute
                        right 0
                        top 0
                        width 4.8rem
                        height 4.8rem
                        padding-top 1.2rem
                        cursor pointer
                        color $white-trnsp-033
                        transition color 0.2s ease

                        > i {
                            position absolute
                            right 1.2rem
                        }

                        &:hover {
                            color $white
                        }
                    }
                }

                &.active {
                    background-color $white-trnsp-016
                    .icon-wrap {
                        > i {
                            color $white
                        }
                    }
                    > .label {
                        color $white
                    }
                }

                #vehicleFilterText {
                    position absolute
                    top 0
                    left 0
                    width 100%
                    height 100%
                    visibility hidden
                }
            }
        }

        #filtered-results {
            width 86%
            max-width $main-content-max-width

            margin 0 auto
            background-color transparent
            overflow-x hidden
            overflow-y auto

            &.selection-focus-vehicle {
                min-height calc(100% - 10.8rem)
                &.operator-login-enabled {
                    min-height calc(100% - 17.2rem)
                }
            }

            .no-result-message {
                padding-top 3.6rem
                padding-left 1.8rem
                padding-right 1.8rem
                color $silver-sand2
                font-size 2rem
                font-weight 300
                > span {
                    font-weight 500
                }
            }

            .loading-spinner {
                z-index 1
                position absolute
                top 0
                left 0
                width 100%
                height 100%
                text-align center
                opacity 1

                display flex
                flex-direction column
                justify-content center
            }

            .result {
                position relative
                height 9rem
                line-height 9rem
                color $seashell
                border-bottom 1px solid $mineshaft6
                font-size 2rem

                > .icon-wrap {
                    i {
                        position absolute
                        top 0
                        left 0
                        height 9rem
                        line-height 9rem
                        font-size 2rem
                        color $white
                        margin-left 1.2rem
                    }
                }

                > .result-value {
                    position absolute
                    top 0
                    left 4rem
                    width calc( 100% - 40px )
                    overflow hidden
                    white-space nowrap
                    text-overflow ellipsis
                }

                &.default-option {
                    > .icon-wrap i {
                        opacity 0.5
                    }
                }
                &--unassigned-trip {
                  font-weight 500
                }

                &.result--last-unassigned-trip {
                  border-bottom none
                }

                &.assigned-trips-label {
                  height 3rem
                  line-height normal
                  margin-top 4rem
                  border-bottom none

                  & .result-value {
                    left 0
                  }
                }
            }
        }
    }
    .blur {
        filter blur(2px)
    }

    #confirmation-info {
        position relative
        width 86%
        max-width $main-content-max-width
        margin 0 auto
        height 100%
        color $seashell

        display flex
        flex-direction column
        justify-content flex-start

        .confirmation-title {
            margin 4.8rem 0
            font-size 2.4rem
            font-weight 400
            text-align center
        }

        .confirmation-subtitle {
            font-size 1.8rem
            margin-bottom 1.2rem
        }

        .assignment-options {
            width 100%
            margin 0 auto

            display flex
            flex-direction column
            justify-content center

            .option {
                display flex
                flex-direction row
                justify-content space-between

                position relative
                padding 1.7rem 1.2rem

                font-size 2rem
                font-weight 300
                color $white-trnsp-087
                background-color $outer-space3
                border-radius 0.4rem
                cursor pointer

                &.selectable {
                    padding-left 6.4rem
                    opacity 0.7
                    &.selected {
                        opacity 1
                    }
                }

                &.assigned {
                    margin-bottom 1.2rem
                }

                &.trip-format-miami-style {
                    .option-info.left {
                        display none
                    }
                    .option-separator {
                        display none
                    }
                    .option-info.right {
                        padding-left 2rem
                    }
                }

                .option-info {
                    line-height 2.667rem
                    > div {
                        overflow hidden
                        white-space nowrap
                        text-overflow ellipsis
                    }
                    &.selection-state {
                        visibility hidden
                        position absolute
                        top 0
                        left 0
                        width 5.2rem
                        height 100%
                        border-top-left-radius 0.4rem
                        border-bottom-left-radius 0.4rem
                        background-color $mako

                        display flex
                        flex-direction column
                        justify-content center

                        > .circle {
                            position relative
                            width 1.8rem
                            height 1.8rem
                            margin-left 1.7rem
                            border-radius 100%
                            border 2px solid $iron

                            > .filling {
                                opacity 0
                                position absolute
                                top 0.2rem
                                left 0.2rem
                                width 1rem
                                height 1rem
                                background-color $color-primary-blue
                                border-radius 100%
                                transition opacity 0.2s ease-in-out
                            }
                        }
                    }
                    .option-label {
                        font-weight 500
                        color $havelock-blue
                    }
                    > .label {
                        margin-bottom 0.8rem

                        &.route {
                            font-weight 500
                        }
                    }
                    &.right {
                        flex-grow 0.667
                        overflow hidden
                    }

                    .vehicle-id--taken, .operator-id--taken {
                          text-decoration line-through
                    }
                }

                &.selectable {
                    .option-info.selection-state {
                        visibility visible
                    }
                }

                &.selected {
                    .option-info.selection-state {
                        > .circle {
                            border-color $color-primary-blue
                            > .filling {
                                opacity 1
                            }
                        }
                    }
                }
            }

            .option-separator {
                height 13.6rem
                width 0.1rem
                margin 0 1.6rem
                background-color $bombay
                &.has-multiple-options {
                    height 10.2rem
                }
                &.has-operator-id {
                    height 16.2rem
                }
                &.has-multiple-options.has-operator-id {
                    height 13.6rem
                }
            }
        }

        .confirm-assignment-button {
            position absolute
            bottom 3.8rem
            width 100%
            height 5.5rem
            line-height 5.5rem
            background-color $color-primary-blue
            border-radius 0.4rem
            color $seashell
            font-size 2rem
            font-weight 500
            text-align center
            transition opacity 0.2s ease-in-out
            cursor pointer
        }
    }

    .main-content {
        .actions-wrap {
            .action-button {
                position relative
                padding 1.5rem 0
                font-size 1.6rem
                font-weight 500
                color $white
                background-color $button-color
                border-radius 0.4rem
                cursor pointer

                > input {
                    position absolute
                    top 0
                    left 0
                    width 100%
                    height 100%
                }

                &.vehicle-is-moving {
                    color $oslo-gray
                    background-color $outer-space4
                }
            }
            .select-wrap {
                position relative
                padding 1rem 0
                color $text-default
                font-weight 300
                border 1px solid $text-default
                border-radius .4rem
                margin-bottom 24px

                .title > span {
                    font-weight 500
                }

                > select {
                    opacity 0
                    position absolute
                    top 0
                    left 0
                    width 100%
                    height 100%
                }
            }

            .headsign,
            .trip,
            .route {
                opacity 0
                transition opacity 0.2s ease
                cursor disabled
                visibility hidden

                &.is-ready {
                    opacity 1
                    cursor pointer
                    visibility visible
                }
            }

            .set {
                display flex
                justify-content center
                padding 10px 0
                color $cod-gray
                background-color $color-primary-blue
                border-radius 0.4rem
                cursor disabled
                opacity 0
                transition opacity 0.2s ease

                &.is-ready {
                    opacity 1
                    cursor pointer
                }
            }
        }

        .hidden-selector {
            visibility hidden
        }

        .is-visible {
            visibility visible
        }
    }
    .blur {
        filter blur(2px)
    }
}

.take-assignment-confirmation {
  padding-bottom 3.8rem
  margin-top auto
  font-size 2rem
  color $seashell

  &__message {
    font-weight 500
  }

  &__buttons {
    display flex
    gap 2rem
  }

  &__back-button, &__take-assignment-button {
    flex 1
    height 5.5rem
    color $seashell
    font-weight 500
    border-radius 0.4rem
    border none
  }

  &__back-button {
    background-color $outer-space3
  }

  &__take-assignment-button {
    background-color $guardsman-red
  }
}

@media screen and (max-width 767px) {
    #operator-login #selections-wrap {
        .selections .selection-wrap {
            max-width $main-content-max-width-smaller-screens
        }
        #filtered-results {
            max-width $main-content-max-width-smaller-screens
        }
    }
}


@media screen and (max-width 600px) {
    #operator-login #selections-wrap {
        height calc(100% - 6rem)
        &.not-showing-login-selections,
        &.showing-confirmation-info {
            height 100%
        }
        .selections .selection-wrap {
            max-width none
            width calc(100% - 4rem)
        }
        #filtered-results {
            max-width none
            width calc(100% - 4rem)
        }
    }
}

@media screen and (max-height 567px) {
    #operator-login {
        position fixed

        #selections-wrap {
            display block
            position static
            width 100%
            height 100%
            overflow-x hidden
            overflow-y scroll

            .selections {
                padding 1.6rem 0
                .selection-wrap {
                    &:first-child {
                        margin-top 0
                    }
                    &:last-child {
                        margin-bottom 0
                    }
                }
            }

            .confirm-assignment-button {
                position static
                margin 1.6rem 0 10rem
            }
        }
    }
}
</style>
