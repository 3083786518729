<template>
  <div id="screen-while-driving">
    <FullErrorGhost v-if="showFullError" :showFullError="showFullError" />

    <div v-else class="main-content-wrapper">
      <StatusHeader
        v-show="!showMySwiftlyView"
        :status="statusHeaderStatus"
        :statusText="statusHeaderText"
      />
      <!-- TODO(haysmike) Move the rest of this to a "big circle" component -->
      <div
        v-if="showPerformanceView && isYetToDepart"
        class="main-content yet-to-depart animated fadeIn"
      >
        <div
          class="actionable-info"
          :class="{
            'blur': showPartialError,
            'show-route-stops': showRouteStops,
          }"
        >
          <div
            v-if="!showRouteStops"
            class="trip-description trip-description-portrait"
          >
            <div class="text-line one">Next trip starts at</div>
            <div class="text-line two">
              {{ $store.getters.vehicleStopName }}
            </div>
          </div>

          <div class="circle-container-wrap">
            <div class="circle-container">
              <div class="active-outer-border before-departure">
                <div id="active-inner-border" class="active-inner-border">
                  <div class="circle">
                    <div
                      class="initial-depart-in-value"
                      v-text="departInText"
                    ></div>
                    <div
                      class="initial-depart-in-label"
                      v-html="preDepartureInfo.html"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="showRouteStops" class="route-stops-wrap">
            <RouteStops />
          </div>
          <div v-else class="trip-description">
            <div class="trip-description-landscape">
              <div class="text-line one">Next trip starts at</div>
              <div class="text-line two">
                {{ $store.getters.vehicleStopName }}
              </div>
            </div>
            <div
              class="depart-at-text"
              :class="{
                'is-modified': hasModifiedDepartureTime,
              }"
            >
              Depart at {{ departureTimeText }}
              <span v-if="hasModifiedDepartureTime">(adjusted)</span>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else-if="showPerformanceView"
        class="main-content has-departed animated fadeIn"
      >
        <div
          v-if="vehicleTimingIsUnavailable"
          class="actionable-info"
          :class="{ blur: showPartialError }"
        >
          <div
            class="circle-container-wrap circle-container-wrap--timing-unavailable"
          >
            <div class="circle-container">
              <div class="active-border timing-unavailable">
                <div class="circle">
                  <div class="timing-unavailable-status">
                    Timing Unavailable
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-else
          class="actionable-info"
          :class="{
            'blur': showPartialError,
            'show-route-stops': showRouteStops,
          }"
        >
          <div v-if="!showRouteStops" class="headsign-wrap">
            <div class="text-line one">HEADSIGN</div>
            <div class="text-line two">
              {{ headsignDisplay }}
            </div>
          </div>

          <div class="circle-container-wrap">
            <div class="circle-container">
              <div class="active-border" :class="circleBorderClass">
                <div id="driving-circle" class="circle">
                  <template v-if="showOffRoute">
                    <div class="off-route-status">Off-Route</div>
                  </template>
                  <template v-else-if="isDetour">
                    <div class="detour-status">On Detour</div>
                  </template>
                  <template v-else-if="isHeadwayMode">
                    <div class="gapped-status">
                      {{ gappedStatus }}
                    </div>
                    <div class="actual-headway">
                      <span>{{ $store.getters.vehicleHeadwayMinutes }}</span>
                      min headway
                    </div>
                    <div class="scheduled-headway">
                      {{ $store.getters.vehicleScheduledHeadwayMinutes }} min
                      scheduled
                    </div>
                  </template>
                  <template v-else>
                    <div class="depart-in-value">
                      {{ otpStatusValue }}
                    </div>
                    <div class="depart-in-label">
                      {{ otpStatusLabel }}
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div v-if="showRouteStops" class="route-stops-wrap">
            <RouteStops />
          </div>
        </div>
      </div>

      <div
        v-else-if="showPassengerCountingView"
        class="main-content passenger-counting animated fadeIn"
      >
        <PassengerCounting />
      </div>
      <MessageView
        v-show="isMessagingEnabled && showMessageView"
        :vehicleIsMoving="vehicleIsMoving"
        :isVisible="showMessageView"
        @close="handleCloseMessageView"
      />
      <MySwiftly v-show="showMySwiftlyView" />

      <NavBar
        :hasConnectionError="hasConnectionError"
        :isNativeMapShowing="isNativeMapShowing"
        :showFullError="showFullError"
        @nativeMapTouchWhileDriving="nativeMapTouchWhileDriving"
      />
    </div>
  </div>
</template>

<script>
import { TweenMax } from 'gsap/all';
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';

import * as Segment from '../analytics/segment';
import * as ErrorReporter from '../error-reporter';
import { OPERATOR_DISPATCH_MESSAGING } from '../optimizely/feature-flags';
import { NAV_VIEWS } from '../store/modules/navigation';
import { computeHeaderStatus } from '../utils/computeHeaderStatus';
import sleep from '../utils/sleep';
import { convertTransitimeTimeToHumanFriendly } from '../utils/transitime-time';

import MessageView from './Messaging/MessageView.vue';
import MySwiftly from './MySwiftly.vue';
import NavBar from './Navigation/NavBar.vue';
import PassengerCounting from './PassengerCounting.vue';
import RouteStops from './RouteStops.vue';
import StatusHeader from './StatusHeader/StatusHeader.vue';
import FullErrorGhost from './common/FullErrorGhost.vue';

const _generateAnimationId = () => Math.random().toString(36).substring(7);

const _tweenChunk = (vc, percentStart, percentEnd, secondsToTween) =>
  new Promise((resolve) => {
    const objectToTween = { value: percentStart };
    _currentTweenChunk = TweenMax.to(objectToTween, secondsToTween, {
      value: percentEnd,
      ease: 'none',
      onUpdate: () => {
        vc.setDepartInPercentage(objectToTween.value, {
          active: '#0D4CE4',
          background: '#2a2a2a',
        });
      },
      onComplete: () => {
        _currentTweenChunk = undefined;
        resolve();
      },
    });
  });

let _offlineTimeout = undefined;
let _serverErrorTimeout = undefined;
let _logoutTimeout = undefined;
let _currentTweenChunk = undefined;
let _nativeMapOpenTimestamp = undefined;
let _nativeMapTouchWhileDrivingCount = 0;
let _nativeMapManipulatedCount = 0;

export default {
  components: {
    FullErrorGhost,
    NavBar,
    MessageView,
    MySwiftly,
    PassengerCounting,
    RouteStops,
    StatusHeader,
  },
  props: {},
  data() {
    return {
      NAV_VIEWS,
      isAtStop: false,
      isNativeMapShowing: false,
      nativeMapRefreshing: false,
      nativeMapRefreshingQueueCount: 0,
      shouldToggleMapForDetour: true,
      didToggleMapForDetour: false,
      justMounted: true,
      currentAnimationId: '',
      cancelCircleAnimation: false,
      lastLocalVehicleId: '',
      lostAssignmentLogoutThreshold: 1000 * 60 * 5, // 5 minutes,

      hasConnectionError: false,
      hasWaitedForConnectionError: false,

      hasServerError: false,
      hasWaitedForServerError: false,

      hasNewMessages: false,

      // Note: Assignment errors don't (usually?) resolve themselves, so we don't wait to show the full-screen error
      hasAssignmentError: false,
      // For logging cases where assignment errors do resolve themselves
      unassignedVehicleInfo: null,

      showOffRoute: false,
      offRouteTimeout: null,
    };
  },
  computed: {
    ...mapState({
      numMessagesFromOthers: ({ messages }) =>
        messages.messages.filter(
          (message) => message.participant.attributes.role !== 'operator',
        ).length,
      nowTimestamp: (state) => state.currentTime.nowTimestamp,
    }),
    ...mapGetters({
      isOffRoute: 'isOffRoute',
      hasUnreadMessages: 'messages/hasUnreadMessages',
      passengerCountTotal: 'passengerCounting/passengerCountTotal',
      activeView: 'navigation/activeView',
      lastView: 'navigation/lastView',
    }),
    showPerformanceView() {
      return this.activeView === NAV_VIEWS.TIMEPOINTS;
    },
    showNativeMapView() {
      return this.activeView === NAV_VIEWS.MAP;
    },
    showMessageView() {
      return this.activeView === NAV_VIEWS.MESSAGES;
    },
    showMySwiftlyView() {
      return this.activeView === NAV_VIEWS.MY_SWIFTLY;
    },
    showPassengerCountingView() {
      return this.activeView === NAV_VIEWS.PASSENGER_COUNTING;
    },
    statusHeaderStatus() {
      return computeHeaderStatus({
        isError: this.showPartialError,
        showOtp: this.showNativeMapView || this.showPassengerCountingView,
        isYetToDepart: this.isYetToDepart,
        isOnDetour: this.isDetour,
        isTimingUnavailable: this.vehicleTimingIsUnavailable,
        isHeadway: this.isHeadwayMode,
        headwayStatus: this.gappedStatus,
        otpStatus: this.otpCategory,
      });
    },
    statusHeaderText() {
      const status = this.statusHeaderStatus;
      switch (status) {
        case 'depart':
          return this.preDepartureInfo.text;
        case 'early':
        case 'late':
          return `${this.otpAbsoluteMinutes} min ${status}`;
        case 'bunched':
        case 'gapped': {
          const capitalizedStatus =
            status.charAt(0).toUpperCase() + status.slice(1);
          const headwayDeviation = Math.abs(
            this.$store.getters.vehicleScheduledHeadwayMinutes -
              this.$store.getters.vehicleHeadwayMinutes,
          );
          return `${capitalizedStatus} by ${headwayDeviation} min`;
        }
        default:
          return '';
      }
    },
    isHeadwayMode() {
      return this.$store.getters.headwayMode;
    },
    showRouteStops() {
      return this.$store.getters.showRouteStops;
    },
    currentOperatorDisplayName() {
      return this.$store.getters.currentOperatorDisplayName;
    },
    vehicleIsMoving() {
      return this.$store.getters.vehicleIsMoving;
    },
    isNativeAppWithMapSupport() {
      return this.$store.getters.isNativeAppWithMapSupport;
    },
    showNativeMapControls() {
      if (this.showFullError) {
        return false;
      }
      if (!this.isNativeAppWithMapSupport) {
        return false;
      }
      return true;
    },
    departInText() {
      if (this.preDepartureInfo.html === 'Now') {
        return 'DEPART';
      }
      return 'DEPART IN';
    },
    vehicleTimingIsUnavailable() {
      const headwayMode = this.$store.getters.headwayMode;
      const vehicleHeadwayMinutes = this.$store.getters.vehicleHeadwayMinutes;
      const scheduleAdherenceSeconds =
        this.$store.getters.vehicleScheduleAdherenceSeconds;
      if (headwayMode && !vehicleHeadwayMinutes) {
        return true;
      }
      if (!headwayMode && !_.isFinite(scheduleAdherenceSeconds)) {
        return true;
      }
      return false;
    },
    showPartialError() {
      const {
        hasConnectionError,
        hasWaitedForConnectionError,
        hasServerError,
        hasWaitedForServerError,
        isNativeMapShowing,
      } = this;
      const shouldShowConnectionError =
        hasConnectionError &&
        (!hasWaitedForConnectionError || isNativeMapShowing);
      const shouldShowServerError =
        hasServerError && (!hasWaitedForServerError || isNativeMapShowing);
      return shouldShowConnectionError || shouldShowServerError;
    },
    showFullError() {
      const {
        isNativeMapShowing,
        hasConnectionError,
        hasWaitedForConnectionError,
        hasServerError,
        hasWaitedForServerError,
        hasAssignmentError,
      } = this;
      if (hasAssignmentError) {
        return 'assignment';
      }
      // Prevent connection and server fullscreen errors if the native map is showing;
      // let the partial errors continue to show and the native-map will resize
      if (isNativeMapShowing) {
        return '';
      }
      if (hasConnectionError && hasWaitedForConnectionError) {
        return 'connection';
      }
      if (hasServerError && hasWaitedForServerError) {
        return 'server';
      }
      return '';
    },
    isYetToDepart() {
      return (
        Number.isFinite(this.$store.getters.vehicleOptimalDepartureTime) ||
        Number.isFinite(
          this.$store.getters.vehicleModifiedDepartureEpochMilliseconds,
        )
      );
    },
    hasModifiedDepartureTime() {
      return _.isFinite(
        this.$store.getters.vehicleModifiedDepartureEpochMilliseconds,
      );
    },
    isLoading() {
      return _.isEmpty(this.$store.getters.currentVehicleInfo);
    },
    headsignDisplay() {
      return (
        this.$store.getters.currentVehicleHeadsign ||
        this.$store.getters.currentTripHeadsign
      );
    },
    gappedStatus() {
      return this.$store.getters.vehicleHeadwayCategory;
    },
    secondsUntilDeparture() {
      const departureTimeEpochMilliseconds =
        this.$store.getters.departureTimeEpochMilliseconds;
      if (!_.isFinite(departureTimeEpochMilliseconds)) {
        return null;
      }
      return Math.ceil(
        (departureTimeEpochMilliseconds - this.nowTimestamp) / 1000,
      );
    },
    preDepartureInfo() {
      const DEFAULT_VALUE = { text: '', html: '...' };
      const secondsUntilDeparture = this.secondsUntilDeparture;
      if (secondsUntilDeparture == null) {
        return DEFAULT_VALUE;
      }
      if (secondsUntilDeparture > 60) {
        const minsUntilDeparture = Math.ceil(secondsUntilDeparture / 60);
        return {
          text: `Depart in ${minsUntilDeparture} min`,
          html: `<span>${minsUntilDeparture}</span> <span class="time-unit">min</span>`,
        };
      }
      if (secondsUntilDeparture < 1) {
        return { text: 'Depart now', html: 'Now' };
      }
      return {
        text: `Depart in ${secondsUntilDeparture} sec`,
        html: `<span>${secondsUntilDeparture}</span> <span class="time-unit">sec</span>`,
      };
    },
    departureTimeText() {
      const DEFAULT_VALUE = '...';
      const modifiedDepartureTimeText =
        this.$store.getters.vehicleModifiedDepartureReadable;
      if (modifiedDepartureTimeText) {
        return modifiedDepartureTimeText;
      }
      const departureTimeText = this.$store.getters.vehicleDepartureTime;
      if (!departureTimeText) {
        return DEFAULT_VALUE;
      }
      try {
        return convertTransitimeTimeToHumanFriendly(departureTimeText);
      } catch (error) {
        // We get a _lot_ of negative departure times, so don't report it (see COND-1610)
        return DEFAULT_VALUE;
      }
    },
    newDepartureTimeText() {
      const DEFAULT_VALUE = '...';
      const newDepartureTimeText =
        this.$store.getters.vehicleModifiedDepartureReadable;
      if (!newDepartureTimeText) {
        return DEFAULT_VALUE;
      }
      return newDepartureTimeText;
    },
    isDetour() {
      return this.$store.getters.vehicleIsOnDetour;
    },
    circleBorderClass() {
      const classes = {};
      if (this.showOffRoute) {
        classes['off-route'] = true;
      } else if (this.isDetour) {
        classes.detour = true;
      } else if (this.$store.getters.headwayMode) {
        classes[this.$store.getters.vehicleHeadwayCategory] = true;
      } else if (_.isString(this.otpCategory)) {
        classes[this.otpCategory] = true;
      }
      return classes;
    },
    otpCategory() {
      const scheduleAdherenceSeconds =
        this.$store.getters.vehicleScheduleAdherenceSeconds;
      const allowableSecondsEarly = this.$store.getters.allowableSecondsEarly;
      const allowableSecondsLate = this.$store.getters.allowableSecondsLate;
      if (scheduleAdherenceSeconds == null) {
        return null;
      }
      if (scheduleAdherenceSeconds < allowableSecondsEarly) {
        return 'early';
      }
      if (scheduleAdherenceSeconds > allowableSecondsLate) {
        return 'late';
      }

      return 'on-time';
    },
    otpBinaryCategory() {
      // Only includes early and late
      const scheduleAdherenceSeconds =
        this.$store.getters.vehicleScheduleAdherenceSeconds;
      if (!_.isFinite(scheduleAdherenceSeconds)) {
        return null;
      }

      // Schedule adherence seconds will be positive if late
      return scheduleAdherenceSeconds > 0 ? 'late' : 'early';
    },
    otpAbsoluteMinutes() {
      const scheduleAdherenceSeconds =
        this.$store.getters.vehicleScheduleAdherenceSeconds;
      if (!_.isFinite(scheduleAdherenceSeconds)) {
        return null;
      }

      return Math.round(Math.abs(scheduleAdherenceSeconds) / 60);
    },
    otpStatusValue() {
      const otpAbsoluteMinutes = this.otpAbsoluteMinutes;

      if (this.otpCategory === 'on-time') {
        return 'on-time';
      }

      return otpAbsoluteMinutes ? otpAbsoluteMinutes : '...';
    },
    otpStatusLabel() {
      const otpCategory = this.otpCategory;
      const otpBinaryCategory = this.otpBinaryCategory;
      const otpAbsoluteMinutes = this.otpAbsoluteMinutes;

      if (otpCategory === 'on-time') {
        return `${otpAbsoluteMinutes} min ${otpBinaryCategory}`;
      }
      return `min ${otpCategory}`;
    },
    vehicleIsOnDetourWithOffsets() {
      const vehicleInfo =
        this.$store.getters.currentVehicleInfo != null
          ? this.$store.getters.currentVehicleInfo
          : this.$store.getters.lastValidVehicleInfo;
      const currentVehicleStopId = vehicleInfo?.nextStopId ?? null;
      if (currentVehicleStopId == null) {
        return;
      }

      const currentTripInfo = this.$store.getters.currentTripInfo;
      const currentTripSchedule =
        currentTripInfo != null ? currentTripInfo.schedule : [];
      const detourDetailsForCurrentTrip =
        this.$store.getters.detourDetailsForCurrentTrip;

      // We want to show the map starting two stops before and one stop after a detour's skipped stops
      const detourStopIds = detourDetailsForCurrentTrip.reduce(
        (acc, detour) => {
          acc.add(detour.routeExit.previousStopId);
          for (const stopId of detour.skippedStops) {
            acc.add(stopId);
          }
          const prevStopIdOffset = this.findPrevStopId(
            currentTripSchedule,
            detour.routeExit.previousStopId,
          );
          if (prevStopIdOffset != null) {
            acc.add(prevStopIdOffset);
          }
          // No route entry if detour includes the last stop
          const nextStopId =
            detour.routeEntry != null ? detour.routeEntry.nextStopId : null;
          if (nextStopId != null) {
            acc.add(nextStopId);
          }
          return acc;
        },
        new Set(),
      );

      return detourStopIds.has(currentVehicleStopId);
    },
    isOnLayover() {
      const vehicleInfo =
        this.$store.getters.currentVehicleInfo != null
          ? this.$store.getters.currentVehicleInfo
          : this.$store.getters.lastValidVehicleInfo;
      return vehicleInfo?.layover ?? false;
    },
    isMessagingEnabled() {
      return this.$store.getters.userHasFeatureAccess(
        OPERATOR_DISPATCH_MESSAGING,
      );
    },
    isPassengerCountingEnabled() {
      return (
        this.$store.getters.isPassengerCountingEnabled &&
        this.$store.getters.agencyPassengerCountingButtons != null
      );
    },
    isOffRouteAfterDeparture() {
      return this.isOffRoute && !this.isYetToDepart;
    },
  },
  watch: {
    'isOffRoute'() {
      const currentInfo = {
        agencyId: this.$store.getters.currentAgencyKey,
        tripId: this.$store.getters.currentTripId,
        block: this.$store.getters.currentBlockId,
        operatorId: this.$store.getters.currentOperator?.key,
        vehicle: this.$store.getters.currentVehicleId,
        gpsLocation: this.$store.getters.currentVehicleGpsLatLon,
        distancesFromRouteLines: this.$store.getters.distancesFromRouteLines,
      };
      if (this.isOffRouteAfterDeparture) {
        Segment.track('verify-if-off-route', currentInfo);
        this.offRouteTimeout = setTimeout(() => {
          this.showOffRoute = true;
          this.offRouteTimeout = null;
          Segment.track('off-route-start', currentInfo);
        }, 10000);
      } else {
        if (this.showOffRoute) {
          Segment.track('off-route-end', currentInfo);
        }
        this.showOffRoute = false;
        clearTimeout(this.offRouteTimeout);
        this.offRouteTimeout = null;
      }
    },
    async 'activeView'(activeView) {
      this.trackActiveView(activeView);
      if (this.showNativeMapView) {
        if (!this.isNativeMapShowing) {
          if (!this.vehicleIsOnDetourWithOffsets) {
            this.shouldToggleMapForDetour = false;
          }
          await this.showNativeMap({
            action: this.didToggleMapForDetour
              ? 'map-opened-automatically'
              : 'map-opened-manually',
          });
          _nativeMapOpenTimestamp = Date.now();
        }
      } else {
        if (this.isNativeMapShowing) {
          // Resets auto-switching to map view if a detour is encountered
          this.shouldToggleMapForDetour = true;
          this.hideNativeMap({
            action: this.didToggleMapForDetour
              ? 'map-closed-automatically'
              : 'map-closed-manually',
          });
        }
        if (this.showPerformanceView && this.isYetToDepart) {
          this.loadCircleCountdown();
        }
      }
      this.didToggleMapForDetour = false;
    },
    'vehicleIsOnDetourWithOffsets': {
      immediate: true,
      handler: async function () {
        if (!this.shouldToggleMapForDetour || !this.isNativeAppWithMapSupport) {
          return;
        }

        if (this.vehicleIsOnDetourWithOffsets) {
          this.didToggleMapForDetour = true;
          this.$store.dispatch('navigation/setActiveView', NAV_VIEWS.MAP);
        } else if (!this.showPerformanceView) {
          this.didToggleMapForDetour = true;
          this.$store.dispatch(
            'navigation/setActiveView',
            NAV_VIEWS.TIMEPOINTS,
          );
        }
      },
    },
    async 'showFullError'(newErrorName, oldErrorName) {
      if (newErrorName === 'assignment') {
        await this.hideNativeMap({ action: 'map-closed-automatically' });
        this.unassignedVehicleInfo = this.$store.getters.currentVehicleInfo;
      } else if (
        newErrorName === '' &&
        oldErrorName === 'assignment' &&
        this.showNativeMapView
      ) {
        await this.showNativeMap({ action: 'map-opened-automatically' });
        ErrorReporter.capture({
          level: 'warning',
          messageOrException: 'Temporary assignment error',
          extraContext: {
            unassignedVehicleInfo: this.unassignedVehicleInfo,
            currentVehicleInfo: this.$store.getters.currentVehicleInfo,
          },
        });
        this.unassignedVehicleInfo = null;
      }
    },
    'showPartialError'() {
      if (this.showMessageView || this.showMySwiftlyView) {
        this.$store.dispatch('navigation/reset');
      }
    },
    async 'hasConnectionError'(hasError) {
      const setConnectionError =
        window.NativeAPI != null ? window.NativeAPI.setConnectionError : null;
      if (typeof setConnectionError === 'function') {
        await setConnectionError({
          hasError,
        });
      }
    },
    '$store.getters.isOnline'(isOnline) {
      if (isOnline) {
        clearTimeout(_offlineTimeout);
        _offlineTimeout = undefined;
        this.hasConnectionError = false;
        this.hasWaitedForConnectionError = false;
        this.$store.dispatch('messages/start');
        return;
      }
      this.$store.dispatch('messages/stop');
      this.hasConnectionError = true;
      _offlineTimeout = setTimeout(
        () => {
          this.hasWaitedForConnectionError = true;
        },
        1000 * 60, // 60 seconds
      );
    },
    '$store.getters.serverErrorVehicleInfo'(hasError) {
      if (!hasError) {
        clearTimeout(_serverErrorTimeout);
        _serverErrorTimeout = undefined;

        this.hasServerError = false;
        this.hasWaitedForServerError = false;

        return;
      }

      this.hasServerError = true;
      _serverErrorTimeout = setTimeout(
        () => {
          this.hasWaitedForServerError = true;
        },
        1000 * 60, // 60 seconds
      );
    },
    'isYetToDepart'() {
      if (!this.isYetToDepart) {
        return;
      }
      this.loadCircleCountdown();
    },
    '$store.getters.vehicleId'(vehicleId) {
      // The node containing vehicleId and therefore vehicleId will be
      // missing when a vehicle becomes unassigned

      if (vehicleId) {
        // If we detect a previous logout timeout has been set,
        // ensure the current vehicleId matches the last seen.
        // If it does not, logout immediately
        if (_logoutTimeout) {
          if (vehicleId === this.lastLocalVehicleId) {
            clearTimeout(_logoutTimeout);
            _logoutTimeout = undefined;
            this.hasAssignmentError = false;
          } else {
            const reason =
              'Assignment lost, then reappeared within 5 minutes, but did not match previous vehicle ID';
            this.$store.dispatch('logoutVehicleNoWarning', {
              vc: this,
              reason,
            });
          }
        }

        // Always set at the component-level a memory of the last valid vehicle id
        this.lastLocalVehicleId = vehicleId;
      } else if (!vehicleId && !_logoutTimeout) {
        this.hasAssignmentError = true;
        _logoutTimeout = setTimeout(() => {
          const reason = 'Assignment lost for > 5 minutes';
          this.$store.dispatch('logoutVehicleNoWarning', { vc: this, reason });
        }, this.lostAssignmentLogoutThreshold);
        const currentStateInfo = {
          agency: this.$store.getters.currentAgencyKey,
          vehicle: this.$store.getters.currentVehicleId,
          operator:
            this.$store.getters.currentOperator != null
              ? this.$store.getters.currentOperator.key
              : null,
          block: this.$store.getters.currentBlockId,
          timestamp: new Date().toISOString(),
        };
        Segment.track('vehicle-has-assignment-error', currentStateInfo);
      }
    },
    '$store.getters.departureTimeEpochMilliseconds'() {
      if (!this.isYetToDepart) {
        return;
      }
      this.loadCircleCountdown();
    },
    // Needed for blocks with multiple trips
    '$store.getters.currentTripId'() {
      this.$store.dispatch('getTripInfo');
      this.fetchMatchedPolylines();
      if (this.isPassengerCountingEnabled) {
        const { currentTripId, lastValidVehicleInfo, currentVehicleId } =
          this.$store.getters;
        const countInfo = {
          'vehicle-id': currentVehicleId,
          'trip-id': currentTripId,
          'onboard-app': this.passengerCountTotal,
          'transit-time': lastValidVehicleInfo?.occupancyCount ?? 'Not Present',
        };
        Segment.track(
          'passenger-counting-currentTripId-change-count',
          countInfo,
        );
      }
      try {
        const stopNavigation = window.NativeAPI?.stopNavigation;
        if (stopNavigation != null) {
          stopNavigation({ endReason: 'End trip' });
        }
      } catch (error) {
        ErrorReporter.capture({
          level: 'error',
          messageOrException: 'Failed to stop navigation on trip change',
          extraContext: { error },
        });
      }
    },
    '$store.getters.currentTripInfo'(currentTripInfo) {
      if (currentTripInfo != null) {
        const { routeShortName, blockId } = currentTripInfo;
        this.$store.commit('currentBlockId', blockId);
        this.$store.commit('currentRouteKey', routeShortName);
      }
    },
    // The component's isAtStop is a local mirror of the store's isVehicleAtStop.
    // It exists to prevent "flapping" and updates at most every 10 seconds.
    'isAtStop'(isAtStop) {
      if (!this.isPassengerCountingEnabled) {
        return;
      }
      Segment.track(isAtStop ? 'arrived-at-stop' : 'departed-stop', {
        showPassengerCountingView: this.showPassengerCountingView,
      });
      if (isAtStop && !this.showPassengerCountingView) {
        this.$store.dispatch(
          'navigation/setActiveView',
          NAV_VIEWS.PASSENGER_COUNTING,
        );
      }
      if (!isAtStop && this.showPassengerCountingView) {
        this.$store.dispatch('navigation/setActiveView', this.lastView);
      }
    },
    '$store.getters.isVehicleAtStop': _.debounce(
      function () {
        // Because the debounce is set for both leading and trailing,
        // don't rely on the watcher's function parameter, check the store.
        const isAtStop = this.$store.getters.isVehicleAtStop;
        if (this.isAtStop !== isAtStop) {
          this.isAtStop = isAtStop;
        }
      },
      1000 * 10, // 10 seconds
      {
        leading: true,
        trailing: true,
      },
    ),
    async '$store.getters.mapMatchedTripPolylines'() {
      await this.showNativeMapTrip();
    },
    async '$store.getters.currentTripPathGeoJson'() {
      await this.showNativeMapTrip();
    },
    '$store.getters.currentTripStopsGeoJson': {
      immediate: true,
      async handler(currentTripStopsGeoJson, previousTripStopsGeoJson) {
        if (!_.isEqual(currentTripStopsGeoJson, previousTripStopsGeoJson)) {
          await this.showNativeMapStops();
        }
      },
    },
    // Because map support can vary between routes, although unlikely, it
    // is possible the state value could change while the map is open. Therefore,
    // if we "lose" map support while the map is open, hide the map.
    async '$store.getters.isNativeAppWithMapSupport'(
      isNativeAppWithMapSupport,
    ) {
      if (this.isNativeMapShowing && !isNativeAppWithMapSupport) {
        await this.hideNativeMap({
          action: 'map-closed-automatically',
        });
      }
    },
    async '$store.getters.detourDetailsForCurrentTrip'(
      detourDetails,
      prevDetourDetails,
    ) {
      if (JSON.stringify(detourDetails) === JSON.stringify(prevDetourDetails)) {
        return;
      }
      this.fetchMatchedPolylines();
      const notifyPathChanged = window.NativeAPI?.notifyPathChanged;
      if (notifyPathChanged != null) {
        try {
          await notifyPathChanged();
        } catch (error) {
          ErrorReporter.capture({
            level: 'error',
            messageOrException: 'Unable to notify native layer of path change',
            extraContext: { error },
          });
        }
      }
    },
    'numMessagesFromOthers'() {
      if (!this.showMessageView && !this.hasNewMessages) {
        setTimeout(() => {
          this.hasNewMessages = false;
        }, 5000); // Should be longer than the duration of the CSS animation
        this.hasNewMessages = true;
      }
    },

    async 'vehicleTimingIsUnavailable'(vehicleTimingIsUnavailable) {
      if (this.justMounted || this.isYetToDepart || this.showFullError) {
        return;
      }

      const currentVehicleInfoTimestamp =
        this.$store.getters.currentVehicleInfoTimestamp;
      const vehicleInfoTimestamp = currentVehicleInfoTimestamp
        ? currentVehicleInfoTimestamp
        : 'N/A';
      const vehicleInfoTimestampAgeInSeconds = _.isFinite(
        currentVehicleInfoTimestamp,
      )
        ? (Date.now() - currentVehicleInfoTimestamp) / 1000
        : 'N/A';
      const vehicleHeadwaySeconds = this.$store.getters.vehicleHeadwaySeconds;
      const headwaySeconds = vehicleHeadwaySeconds
        ? vehicleHeadwaySeconds
        : 'N/A';
      const scheduleAdherenceSeconds =
        this.$store.getters.vehicleScheduleAdherenceSeconds;
      const scheduleAdherenceValue = scheduleAdherenceSeconds
        ? scheduleAdherenceSeconds
        : 'N/A';

      const currentStateInfo = {
        'agency': this.$store.getters.currentAgencyKey,
        'vehicle': this.$store.getters.currentVehicleId,
        'block': this.$store.getters.currentBlockId,
        'headway-mode': this.$store.getters.headwayMode,
        'headway-seconds': headwaySeconds,
        'schedule-adherence-seconds': scheduleAdherenceValue,
        'vehicle-info-timestamp': vehicleInfoTimestamp,
        'vehicle-info-timestamp-age-in-seconds':
          vehicleInfoTimestampAgeInSeconds,
        'memory-heap-size-limit': this.$store.getters.heapSizeLimit,
        'memory-heap-size-total': this.$store.getters.totalJavaScriptHeapSize,
        'memory-heap-size-used': this.$store.getters.usedJavaScriptHeapSize,
        'send-gps-updates': this.$store.getters.sendGeolocationUpdates,
      };

      if (vehicleTimingIsUnavailable) {
        Segment.track('vehicle-timing-became-unavailable', currentStateInfo);
      } else {
        Segment.track('vehicle-timing-became-available', currentStateInfo);
      }
    },
    '$store.getters.enableNativeMapGestures'(gesturesEnabled) {
      const shouldHideScreen = this.$store.getters.shouldHideScreen;
      this.setNativeMapGesturesEnabled(gesturesEnabled, shouldHideScreen);
    },
  },
  beforeMount() {
    this.$emit('hide-loading');
    // If we already have currentTripId, the watcher will not fire,
    // so ensure we call /fetchMapMatchedPolylines or /getTripCoordinates with a lifecyle hook
    this.fetchMatchedPolylines();
  },
  mounted() {
    this.lastLocalVehicleId = this.$store.getters.vehicleId;

    if (this.isYetToDepart) {
      this.loadCircleCountdown();
    }

    setTimeout(() => {
      this.justMounted = false;
    }, 1000);

    window.addEventListener('orientationchange', this.onOrientationChange);
    document.addEventListener('resume', this.onResume, false);
    this.isAtStop = this.$store.getters.isVehicleAtStop;
    // No need to wait when a connection or server error already happened while another component was active.
    this.hasConnectionError = !this.$store.getters.isOnline;
    this.hasWaitedForConnectionError = !this.$store.getters.isOnline;
    this.hasServerError = this.$store.getters.serverErrorVehicleInfo;
    this.hasWaitedForServerError = this.$store.getters.serverErrorVehicleInfo;
    if (this.isMessagingEnabled) {
      this.$store.dispatch('messages/start');
    }
    this.trackDeviceLocation();
    this.trackActiveView(this.activeView);
  },
  async beforeDestroy() {
    this.cancelCircleAnimation = true;
    this.cancelCurrentTweenChunkIfNeeded();
    window.removeEventListener('orientationchange', this.onOrientationChange);
    document.removeEventListener('resume', this.onResume, false);
    clearTimeout(_logoutTimeout);
    _logoutTimeout = undefined;
    this.$store.commit('matchedTripPathResponse', null);
    this.$store.commit('currentTripCoordinates', null);
    await this.hideNativeMap({ action: 'map-closed-automatically' });
    await this.hideNativeMapStops();
    await this.hideNativeMapTrip();
    const setNavigationEnabled = window.NativeAPI?.setNavigationEnabled;
    if (setNavigationEnabled != null) {
      await setNavigationEnabled({
        navigationEnabled: false,
        isNavigationAudioEnabled: false,
      });
    }
    clearTimeout(this.offRouteTimeout);
    this.offRouteTimeout = null;
  },
  methods: {
    nativeMapTouchWhileDriving() {
      _nativeMapTouchWhileDrivingCount++;
    },
    findPrevStopId(schedule, stopId) {
      const stopIdIndex = schedule.findIndex((stop) => stop.stopId === stopId);
      // if stopId is the first stop in the schedule (index 0), there will be no previous stop id
      return stopIdIndex > 0 ? schedule[stopIdIndex - 1].stopId : null;
    },
    cancelCurrentTweenChunkIfNeeded() {
      if (!_.isObjectLike(_currentTweenChunk)) {
        return;
      }
      if (typeof _currentTweenChunk.kill !== 'function') {
        return;
      }
      _currentTweenChunk.kill();
    },

    setDepartInPercentage(percentage, colors) {
      let _colors = colors;
      let _percentage = percentage;
      if (!_.isObjectLike(_colors)) {
        // Provide defaults
        _colors = {
          background: '#2a2a2a',
          active: '#0D4CE4',
        };
      }

      if (!_.isFinite(_percentage)) {
        _percentage = 0;
      }
      if (_percentage < 0) {
        _percentage = 0;
      }
      if (_percentage > 100) {
        _percentage = 100;
      }

      const degrees = parseFloat(((_percentage * 360) / 100).toFixed(3));

      const element = document.getElementById('active-inner-border');
      if (!element) {
        return;
      }

      if (degrees <= 180) {
        element.style.backgroundImage = `
      linear-gradient(
        ${90 + degrees}deg, transparent 50%, ${_colors.active} 50%
      ),
      linear-gradient(90deg, ${_colors.active} 50%, transparent 50%)
    `;
      } else {
        element.style.backgroundImage = `
      linear-gradient(
        ${degrees - 90}deg, transparent 50%, ${_colors.background} 50%
      ),
      linear-gradient(90deg, ${_colors.active} 50%, transparent 50%)
    `;
      }
    },
    loadCircleCountdown: _.debounce(
      async function () {
        const localAnimationId = _generateAnimationId();
        this.currentAnimationId = localAnimationId;
        this.cancelCurrentTweenChunkIfNeeded();

        const departureTimeEpochMilliseconds =
          this.$store.getters.departureTimeEpochMilliseconds;
        if (!_.isFinite(departureTimeEpochMilliseconds)) {
          return;
        }

        const background = '#2a2a2a';
        const active = '#0D4CE4';
        let secondsLeft = (departureTimeEpochMilliseconds - Date.now()) / 1000;
        if (secondsLeft < 2) {
          // TODO: Pair with another engineer to see if we can remove setTimeout
          setTimeout(() => {
            this.setDepartInPercentage(0, { active, background });
          }, 100);
          return;
        }

        const tween = { value: 100 };
        TweenMax.to(tween, 2, {
          value: 0,
          ease: 'power1.out',
          onUpdate: () => {
            if (this.cancelCircleAnimation) {
              return;
            }
            if (localAnimationId !== this.currentAnimationId) {
              return;
            }

            this.setDepartInPercentage(tween.value, { active, background });
          },
          onComplete: async () => {
            const finalPercent = 100;
            const ANIMATION_CHUNK_DURATION = 10;

            let percentStart = 0;
            let percentEnd = undefined;
            let percentLeft = undefined;
            let percentToAnimate = undefined;
            let animationDuration = undefined;

            while (
              percentStart < finalPercent &&
              !this.cancelCircleAnimation &&
              localAnimationId === this.currentAnimationId
            ) {
              secondsLeft =
                (departureTimeEpochMilliseconds - Date.now()) / 1000;

              if (secondsLeft < ANIMATION_CHUNK_DURATION) {
                // Final animation

                percentEnd = finalPercent;
                animationDuration = secondsLeft;
              } else {
                // Intermediary animation

                animationDuration = ANIMATION_CHUNK_DURATION;

                percentLeft = finalPercent - percentStart;
                percentToAnimate =
                  percentLeft * (ANIMATION_CHUNK_DURATION / secondsLeft);
                percentEnd = percentStart + percentToAnimate;
              }

              await _tweenChunk(
                this,
                percentStart,
                percentEnd,
                animationDuration,
              );

              percentStart = percentEnd;
            }

            if (this.cancelCircleAnimation) {
              return;
            }
            if (localAnimationId !== this.currentAnimationId) {
              return;
            }

            this.setDepartInPercentage(0, {
              active: '#10b164',
              background: '#2a2a2a',
            });
          },
        });
      },
      250,
      {
        leading: false,
        trailing: true,
      },
    ),
    handleCloseMessageView() {
      this.showMessageView = false;
    },
    fetchMatchedPolylines: _.debounce(
      function () {
        if (this.$store.getters.disableMapboxMatching) {
          this.$store.dispatch('getTripCoordinates');
        } else {
          this.$store.dispatch('fetchMapMatchedPolylines');
        }
      },
      500,
      {
        leading: false,
        trailing: true,
      },
    ),
    async showNativeMap(options = {}) {
      if (!this.isNativeAppWithMapSupport) {
        return;
      }

      const showMap = _.get(window, ['NativeAPI', 'showMap']);
      if (typeof showMap !== 'function') {
        return;
      }
      this.isNativeMapShowing = await showMap({
        top: 60, // 60px $status-header-height
        bottom: 100, // This needs to be the same as $nav-bar-height in px
        left: 0,
        right: 0,
        instant: true,
      });
      if (options.action) {
        Segment.track(options.action, {
          'agency': this.$store.getters.currentAgencyKey,
          'vehicle-id': this.$store.getters.currentVehicleId,
          'operator-id':
            this.$store.getters.currentOperator != null
              ? this.$store.getters.currentOperator.key
              : null,
        });
      }
      if (this.isNativeMapShowing) {
        const gesturesEnabled = this.$store.getters.enableNativeMapGestures;
        const shouldHideScreen = this.$store.getters.shouldHideScreen;
        this.setNativeMapGesturesEnabled(gesturesEnabled, shouldHideScreen);

        const setNavigationEnabled = window.NativeAPI?.setNavigationEnabled;
        if (
          setNavigationEnabled != null &&
          this.$store.getters.isNavigationEnabled
        ) {
          await setNavigationEnabled({
            navigationEnabled: true,
            isNavigationAudioEnabled:
              this.$store.getters.isNavigationAudioEnabled,
          });
        }
      }
    },
    async hideNativeMap(options = {}) {
      if (!this.isNativeAppWithMapSupport) {
        return;
      }

      const hideMap = _.get(window, ['NativeAPI', 'hideMap']);
      if (typeof hideMap !== 'function') {
        return;
      }
      const response = await hideMap({ instant: true });
      if (response) {
        _nativeMapTouchWhileDrivingCount += response.mapTouchWhileLocked;
        _nativeMapManipulatedCount += response.mapManipulated;
      }
      if (options.action) {
        const nativeMapOpenTimestampAgeInSeconds = _.isFinite(
          _nativeMapOpenTimestamp,
        )
          ? (Date.now() - _nativeMapOpenTimestamp) / 1000
          : 'N/A';
        Segment.track(options.action, {
          'map-opened-in-seconds': nativeMapOpenTimestampAgeInSeconds,
          'map-touched-while-driving-count': _nativeMapTouchWhileDrivingCount,
          'map-manipulated-count': _nativeMapManipulatedCount,
          'agency': this.$store.getters.currentAgencyKey,
          'vehicle-id': this.$store.getters.currentVehicleId,
          'operator-id':
            this.$store.getters.currentOperator != null
              ? this.$store.getters.currentOperator.key
              : null,
        });
        _nativeMapOpenTimestamp = undefined;
        _nativeMapTouchWhileDrivingCount = 0;
        _nativeMapManipulatedCount = 0;
      }
      if (options.isRefreshingDimensions) {
        return;
      }
      this.isNativeMapShowing = false;
    },
    async hideNativeMapTrip() {
      if (!this.isNativeAppWithMapSupport) {
        return;
      }
      const {
        removeTripPolylines,
        removeTripDetourPolylines,
        removeTripClosedPolylines,
        removeTripGeojsonLine,
        removeTripDetourGeoJsonLine,
        removeTripClosedGeoJsonLine,
      } = window.NativeAPI;
      if (removeTripPolylines != null) {
        await removeTripPolylines();
      }
      if (removeTripDetourPolylines != null) {
        await removeTripDetourPolylines();
      }
      if (removeTripClosedPolylines != null) {
        await removeTripClosedPolylines();
      }
      if (removeTripGeojsonLine != null) {
        await removeTripGeojsonLine();
      }
      if (removeTripDetourGeoJsonLine != null) {
        await removeTripDetourGeoJsonLine();
      }
      if (removeTripClosedGeoJsonLine != null) {
        await removeTripClosedGeoJsonLine();
      }
    },
    async showNativeMapTrip() {
      if (!this.isNativeAppWithMapSupport) {
        return;
      }

      const {
        mapMatchedTripPolylines,
        mapMatchedDetourPolylines,
        mapMatchedClosedPolylines,
        currentTripPathGeoJson: {
          original: tripGeoJson,
          detour: tripDetourGeoJson,
          closed: tripClosedGeoJson,
        },
      } = this.$store.getters;

      const {
        drawTripPolylines,
        drawTripDetourPolylines,
        drawTripClosedPolylines,
        drawTripGeojsonLine,
        drawTripDetourGeoJsonLine,
        drawTripClosedGeoJsonLine,
      } = window.NativeAPI;

      // If the API is returning trip detours, yet the native app still
      // has a version that does not yet support rendering detours via a
      // separate function, draw the detour with the regular trip polyline
      const tripPolylinesToRender =
        drawTripDetourPolylines != null
          ? mapMatchedTripPolylines
          : mapMatchedTripPolylines.concat(mapMatchedDetourPolylines);

      // If supported, always call polyline draw methods. Calling them with
      // empty arrays ensures the proper clean up of previously-rendered detours
      if (drawTripPolylines != null) {
        await drawTripPolylines(tripPolylinesToRender);
      }
      if (drawTripDetourPolylines != null) {
        await drawTripDetourPolylines(mapMatchedDetourPolylines);
      }
      if (drawTripClosedPolylines != null) {
        await drawTripClosedPolylines(mapMatchedClosedPolylines);
      }

      // If we had no trip polylines to render, try rendering trip geojson
      if (tripPolylinesToRender.length === 0 && drawTripGeojsonLine != null) {
        await drawTripGeojsonLine(tripGeoJson);
        if (
          drawTripDetourGeoJsonLine != null &&
          drawTripClosedGeoJsonLine != null
        ) {
          await drawTripDetourGeoJsonLine(tripDetourGeoJson);
          await drawTripClosedGeoJsonLine(tripClosedGeoJson);
        }
      }
    },
    async hideNativeMapStops() {
      if (!this.isNativeAppWithMapSupport) {
        return;
      }
      const removeTripStops = window.NativeAPI?.removeTripStops;
      if (removeTripStops != null) {
        await removeTripStops();
      }
    },
    async showNativeMapStops() {
      if (!this.isNativeAppWithMapSupport) {
        return;
      }
      const drawTripStops = window.NativeAPI?.drawTripStops;
      if (drawTripStops != null) {
        await drawTripStops(this.$store.getters.currentTripStopsGeoJson);
      }
    },
    // TODO(haysmike) Could probably do this natively without hiding and showing
    async refreshNativeMapDimensions(sleepDelay = 333) {
      const vc = this;
      if (!vc.isNativeMapShowing) {
        return;
      }
      vc.nativeMapRefreshingQueueCount += 1;
      if (vc.nativeMapRefreshing) {
        return;
      }
      vc.nativeMapRefreshing = true;
      while (vc.nativeMapRefreshingQueueCount) {
        await vc.hideNativeMap({
          isRefreshingDimensions: true,
          action: 'map-closed-automatically',
        });
        await sleep(sleepDelay); // In testing, Android display gets corrupted without small delay
        await vc.showNativeMap({
          action: 'map-opened-automatically',
        });
        vc.nativeMapRefreshingQueueCount -= 1;
      }
      vc.nativeMapRefreshing = false;
    },
    setNativeMapGesturesEnabled: _.debounce(
      async function (gesturesEnabled, shouldHideScreen) {
        const setGesturesEnabled = _.get(window, [
          'NativeAPI',
          'setGesturesEnabled',
        ]);
        if (typeof setGesturesEnabled !== 'function') {
          return;
        }
        await setGesturesEnabled({
          gesturesEnabled,
          shouldHideScreen,
        });
      },
      250,
      {
        leading: false,
        trailing: true,
      },
    ),
    trackActiveView(activeView) {
      const viewName = {
        [NAV_VIEWS.MAP]: 'map',
        [NAV_VIEWS.MESSAGES]: 'messaging',
        [NAV_VIEWS.MY_SWIFTLY]: 'settings',
        [NAV_VIEWS.PASSENGER_COUNTING]: 'passenger-counting',
        [NAV_VIEWS.TIMEPOINTS]: 'performance',
      };

      const {
        currentAgencyKey,
        currentBlockId,
        currentOperator,
        currentRouteKey,
        currentTripId,
        currentVehicleId,
        headwayMode,
        permanentlyAssignedVehicleId,
      } = this.$store.getters;

      const currentStateInfo = {
        'agency': currentAgencyKey,
        'block': currentBlockId,
        'mode': headwayMode ? 'headway' : 'otp',
        'operator': currentOperator != null ? currentOperator.key : null,
        'permanently-assigned-vehicle-id': permanentlyAssignedVehicleId,
        'route': currentRouteKey,
        'timestamp': new Date().toISOString(),
        'trip': currentTripId,
        'vehicle': currentVehicleId,
      };
      Segment.track(`viewed-${viewName[activeView]}-view`, currentStateInfo);
    },
    trackDeviceLocation: _.debounce(
      async function () {
        const trackDeviceLocation = _.get(window, [
          'NativeAPI',
          'trackDeviceLocation',
        ]);
        if (typeof trackDeviceLocation !== 'function') {
          return;
        }
        await trackDeviceLocation();
      },
      250,
      {
        leading: false,
        trailing: true,
      },
    ),
    onOrientationChange() {
      this.refreshNativeMapDimensions();
    },
    onResume() {
      const vc = this;
      vc.refreshNativeMapDimensions();
    },
  },
};
</script>

<style lang="stylus" scoped>
@require "../styl/_variables.styl"
@require "../styl/_colors.styl"
@require "../styl/_svg-icon-adjustments.styl"

#screen-while-driving {
    position absolute
    top 0
    right 0
    width 100%
    height 100%
    display flex
    flex-direction column
    justify-content flex-start

    .main-content-wrapper {
        height 100%
        display flex
        flex-direction column
        justify-content space-between // keep the status header at the top and nav bar at the bottom in map view
    }

    .main-content {
        position relative
        flex-basis 100%
        background-color $background-color
        text-align center
        display flex

        &.passenger-counting {
          background-color $background-color-3
          overflow-y hidden
        }
        .last-updated {
            position absolute
            top 0
            right 0
            padding 2rem 9rem 0 0
            color $bombay2
            font-size 1.8rem
            text-align right

            &.hide {
                display none
            }
        }

        .actionable-info {
            flex-basis 100%
            transition opacity 0.2s ease
            display flex
            flex-direction column
            justify-content center

            &.blur {
                filter blur(2px)
            }

            .text-line {
                color $text-default
                &.one {
                    margin-bottom 1.2rem
                    font-size 2.6rem
                    font-weight 400
                }

                &.two {
                    margin-bottom 5rem
                    padding 0 3rem 0
                    font-size 4rem
                    font-weight 400
                    overflow hidden
                }
            }

            .circle-container {
                width 31rem
                height 31rem
                margin 0 auto

                .active-outer-border {

                    position relative
                    text-align center
                    width 310px
                    height 310px
                    border-radius 100%

                    background-image linear-gradient(90deg, transparent 50%, $white-trnsp-010 50%),
                                     linear-gradient(90deg, $white-trnsp-010 50%, transparent 50%)

                    .active-inner-border {
                        width 31rem
                        height 31rem
                        border-radius 100%
                        background-color $mineshaft2
                    }
                }

                .active-border {
                    position relative
                    text-align center
                    width 310px
                    height 310px
                    border-radius 100%

                    &.on-time,
                    &.expected {
                        background-image linear-gradient(90deg, transparent 50%, $status-positive 50%),
                                         linear-gradient(90deg, $status-positive 50%, transparent 50%)
                        box-shadow 0px 0px 9px 0px $status-positive
                    }
                    &.early,
                    &.bunched,
                    &.off-route {
                        background-image linear-gradient(90deg, transparent 50%, $status-negative 50%),
                                         linear-gradient(90deg, $status-negative 50%, transparent 50%)
                        box-shadow 0px 0px 9px 0px $status-negative
                    }
                    &.late,
                    &.gapped {
                        background-image linear-gradient(90deg, transparent 50%, $status-neutral 50%),
                                         linear-gradient(90deg, $status-neutral 50%, transparent 50%)
                        box-shadow 0px 0px 9px 0px $status-neutral
                    }

                    &.timing-unavailable,
                    &.detour {
                        background-color $status-unavailable
                        .circle {
                            background-color $status-unavailable
                        }
                    }
                }

                .circle {
                    position relative
                    top 10px
                    left 10px
                    text-align center
                    width 290px
                    height 290px
                    border-radius 100%
                    /* TODO: Related to OA-124. Pair with another engineer to see if we can remove !important */
                    background-image none !important
                    background-color $cod-gray
                    .initial-depart-in-value {
                        z-index 1
                        padding-top 8.35rem
                        color $text-default
                        font-size 2.4rem
                        font-weight 400
                    }
                    .initial-depart-in-label {
                        z-index 1
                        margin-top 2rem
                        color $text-default
                        font-size 6.4rem
                        font-weight 500
                        > .time-unit {
                            font-size 4.5rem
                        }
                    }
                    .initial-depart-in-time {
                        color $white-trnsp-080
                        margin-top 2.2rem
                        font-size 2.5rem
                        font-weight 400
                        &.modified {
                            .new-time {
                                margin-top 0.66rem
                            }
                        }
                    }

                    .depart-in-value {
                        z-index 1
                        padding-top 8.35rem
                        color $text-default
                        font-size 6.4rem
                    }
                    .depart-in-label {
                        z-index 1
                        margin-top 2rem
                        color $text-default
                        font-size 2.2rem
                    }

                    .gapped-status,
                    .otp-status,
                    .detour-status,
                    .timing-unavailable-status {
                        z-index 1
                        padding-top 8.45rem
                        color $text-default
                        font-size 5.8rem
                        font-weight 500
                    }

                    .detour-status {
                        width 80%
                        margin 0 auto
                        color $iron2
                    }

                    .off-route-status {
                        padding-top 11.5rem
                        color $iron2
                        font-size 5.8rem
                        font-weight 500
                    }

                    .timing-unavailable-status {
                        padding-top 8.6rem
                        color $iron2
                        font-size 4.5rem
                    }

                    .actual-headway {
                        position absolute
                        bottom 9.2rem
                        width 100%
                        text-align center
                        color $text-default
                        font-size 2.3rem
                        font-weight 400
                    }
                    .scheduled-headway {
                        position absolute
                        bottom 5.6rem
                        width 100%
                        text-align center
                        color $text-default
                        font-size 2.3rem
                        font-weight 400
                    }
                }
            }

            .trip-description {
              display flex
              flex-direction column
              justify-content center
              &-portrait {
                display none
              }
              &-landscape {
                display none
              }
            }

            .depart-at-text {
                margin 0 auto
                padding 1rem 4rem
                border-radius 0.4rem
                font-size 2.4rem
                font-weight 400
                background-color $mineshaft3
                color $mercury

                &.is-modified {
                    background-color $woodrush
                    color $tree-poppy
                    border 1px solid $tree-poppy
                }
            }
        }
    }
}

// 600px is fairly arbitrary, revisit perhaps?
@media screen and (max-width 600px) {
    #screen-while-driving {
        .main-content {
            .actionable-info {
                .text-line.two {
                    font-size 1.9rem
                }
            }
        }
    }
}

@media (orientation: landscape) {
    #screen-while-driving {
        .main-content {
            justify-content center
            .actionable-info {
                flex-direction row
                max-height 31rem
                justify-content center
                .circle-container-wrap {
                    flex-basis 50%
                    display flex
                    flex-direction row
                    justify-content flex-end
                    margin-right 3%
                    &--timing-unavailable {
                      justify-content center
                      margin-right 0
                    }

                    .circle-container {
                        margin 0
                    }
                }
                .trip-description {
                  flex-basis 50%
                  &-landscape{
                    position relative
                    flex-basis 50%
                    display block
                  }
                }
                .headsign-wrap {
                    position relative
                    flex-basis 50%
                    order 1
                    align-self center
                }
                .route-stops-wrap {
                    position relative
                    flex-basis 50%
                    margin-left 3%
                }
            }
        }
    }
}

@media (orientation: portrait) {
    #screen-while-driving {
        .main-content {
            justify-content flex-end
            .actionable-info {
                flex-direction column
                .trip-description {
                  flex-basis 25%
                  &-portrait {
                    display flex
                    padding-top 2rem
                  }
                }

                .circle-container-wrap {
                    display flex
                    flex-direction row
                    justify-content center
                    padding-top 1rem
                }

                .route-stops-wrap {
                    position relative
                    height 100%
                    width calc( 31rem + 6% )
                    min-height 20rem
                    max-height 30rem
                    margin 6rem auto 0 auto
                }
            }
        }
    }
}
</style>
